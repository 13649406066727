import { useNavigate, useParams } from 'react-router-dom';
import { MyIcon } from '@scanny-app/loopy-loop';

interface AgreementCompleteProps {
  backLink: (id: string) => string;
}
function AgreementComplete({ backLink }: AgreementCompleteProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(backLink(id || '/'));
  };

  return (
    <section id="agreement-complete" className="flex flex-column middle content-center gap-8">
      <div className="mb-24" style={{ width: '140px' }}>
        <MyIcon icon={'IconAgreementComplete'} />
      </div>
      <div className="flex gap-12 text-green middle content-center">
        <p className="m-0 text-sm">Rental Agreement Complete</p>
        <MyIcon icon={'IconCircleCompleted'} />
      </div>
      <h1 className="m-0 text-xl">Please hand device back</h1>
      <button type="button" className="btn-outline mt-16" onClick={handleReturn}>
        Return
      </button>
    </section>
  );
}

export default AgreementComplete;
