import { Form, Input, Modal, message } from 'antd';
import { useContext, useState } from 'react';
import { CustomDropdown } from './CustomDropdown';
import { Auth } from 'aws-amplify';
import { UserContext, IRentalRequest, IRequestExtension, MyIcon, IOptionDropdown } from '@scanny-app/loopy-loop';
import axios from 'axios';

interface ApprovalStatusUpdateProps {
  isRequestExtension: boolean;
  requestExtensionData: IRequestExtension | undefined;
  rentalRequestData: IRentalRequest;
  setIsLoading: (value: boolean) => void;
  setReloadDataRentalRequest: (value: boolean) => void;
  setReloadDataDashboard: (value: boolean) => void;
  setReloadDataComment: (value: boolean) => void;
}

type StatusOption = 'Approved' | 'Rejected' | 'Request Details' | 'Respond';

function ApprovalStatusUpdate({
  isRequestExtension,
  requestExtensionData,
  rentalRequestData,
  setIsLoading,
  setReloadDataRentalRequest,
  setReloadDataDashboard,
  setReloadDataComment,
}: ApprovalStatusUpdateProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [status, setStatus] = useState<StatusOption>();
  const [statusComments, setStatusComments] = useState('');
  const [approvalForm] = Form.useForm();
  const userData = useContext(UserContext);

  const handleConfirm = (value: StatusOption) => {
    setStatus(value);

    approvalForm
      .validateFields()
      .then((formValue) => {
        setStatusComments(formValue.statusComments);
        setShowConfirmation(true);
      })
      .catch(() => {
        message.error('Please review field errors');
      });
  };

  const handleOk = () => {
    if (status) {
      if (status === 'Request Details' || status === 'Respond') {
        handleComment(statusComments);
      } else {
        handleUpdate(status, statusComments);
      }
    }
    setShowConfirmation(false);
  };

  const handleComment = async (statusCommnents: string) => {
    setIsLoading(true);
    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();
      const data = {
        body: statusCommnents,
        submittedByName: `${userData?.firstName} ${userData?.lastName}`,
        submittedByCompany: userData?.currentCompany,
        rentalRequestId: rentalRequestData.id,
        hireExtensionId: isRequestExtension && requestExtensionData ? requestExtensionData.id : '',
      };

      await axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/Comment`, JSON.stringify(data), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success('Success');
          setReloadDataComment(true);
          setIsLoading(false);
          approvalForm.resetFields();
        });
    } catch (error) {
      message.error(`${error}`);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (status: string, statusCommnents: string) => {
    setIsLoading(true);
    try {
      let endpoint, messageStatus: string, data;
      if (isRequestExtension && requestExtensionData) {
        endpoint = `${process.env.REACT_APP_ENDPOINT_URL}/update/HireExtension/${requestExtensionData.id}`;
        messageStatus = 'Request extension updated successfully';
        data = {
          status: status,
          statusUpdateComments: statusCommnents,
          statusUpdatedByName: `${userData?.firstName} ${userData?.lastName}`,
        };
      } else {
        endpoint = `${process.env.REACT_APP_ENDPOINT_URL}/update/RentalRequest/${rentalRequestData.id}`;
        messageStatus = 'Rental request updated successfully';
        data = {
          status: status,
          statusComments: statusCommnents,
          statusUpdatedByName: `${userData?.firstName} ${userData?.lastName}`,
        };
      }
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();

      await axios
        .post(endpoint, JSON.stringify(data), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success(messageStatus);
          setReloadDataRentalRequest(true);
          setReloadDataDashboard(true);
          setIsLoading(false);
        });
    } catch (error) {
      message.error(`${error}`);
      setIsLoading(false);
    }
  };

  const statusDesc =
    status === 'Approved' ? (
      <span>approve</span>
    ) : status === 'Rejected' ? (
      <span>reject</span>
    ) : status === 'Respond' ? (
      <span>respond</span>
    ) : (
      <span>request further details</span>
    );

  const OptionDropdown: IOptionDropdown[] = [
    {
      icon: 'IconApproved',
      name: 'Approve Request',
      className: 'btn-green',
      onClick: () => handleConfirm('Approved'),
    },
    {
      icon: 'IconNotePad',
      name: 'Request Further Details',
      className: 'btn-blue',
      onClick: () => handleConfirm('Request Details'),
    },
    {
      icon: 'IconDecline',
      name: 'Reject Request',
      className: 'btn-red',
      onClick: () => handleConfirm('Rejected'),
    },
  ];

  return (
    <>
      <Modal
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onOk={handleOk}
        className="custom-modal"
        width={400}
        centered
      >
        <h5 className="text-sm font-semibold m-0">Are you sure you want to {statusDesc}?</h5>
        <p className="mt-4 mb-16">
          This action will {status === 'Respond' ? 'send your response' : statusDesc}
          {(status === 'Approved' || status === 'Rejected') && ' this rental request '} and cannot be undone.
        </p>
      </Modal>
      <div className="flex flex-column gap-16">
        <Form form={approvalForm} className="w-full form-style-one" layout="vertical">
          <Form.Item name="statusComments" label={'Comments'} rules={[{ required: true, message: 'Please add a comment' }]}>
            <Input.TextArea autoSize={{ minRows: 4 }} placeholder={'Add a comment'} disabled={false} />
          </Form.Item>
        </Form>

        <div className="flex content-end">
          {userData?.profileType === 'Dealer' ? (
            <div
              className={`dropdown-btn cust-dropdown flex gap-12 middle rounded-8 btn-blue`}
              onClick={() => handleConfirm('Respond')}
            >
              <MyIcon icon="IconNotePad" />
              Respond
            </div>
          ) : (
            <CustomDropdown options={OptionDropdown} />
          )}
        </div>
      </div>
    </>
  );
}

export default ApprovalStatusUpdate;
