import { IBooking, MyIcon } from '@scanny-app/loopy-loop';
import BookingCardComponent from '../Card/CardBooking';
import { OverviewData } from '../../pages/SinglePages/ViewRecord';
interface QueryBooking {
  overviewData: OverviewData;
}
function ViewBookings({ overviewData: { rentalRequestData, bookingData, filterParam } }: QueryBooking) {
  const navigateToRentalAgreement =
    filterParam !== '' && filterParam !== null
      ? `/rentals/rental-agreement/${rentalRequestData.id}/${rentalRequestData.hire}?filter=${filterParam}`
      : `/rentals/rental-agreement/${rentalRequestData.id}/${rentalRequestData.hire}`;

  return (
    <>
      {!bookingData || bookingData.length === 0 ? (
        <div className="flex md-flex-column flex-row overflow-hidden p-24 gap-24 md-gap-16">
          <MyIcon icon="IconCalendarInCloud"/>
          <div className="flex flex-column content-center start md-middle gap-4 md-gap-8">
            <h6 className="text-sm m-0 text-center">No bookings</h6>
            <p className="text-xs m-0 text-center">When you have bookings, they will appear here.</p>
          </div>
        </div>
      ) : (
        bookingData.map((booking: IBooking) => {
          return (
            <BookingCardComponent key={booking.id} bookingData={booking} navigateToRentalAgreement={navigateToRentalAgreement} />
          );
        })
      )}
    </>
  );
}
export default ViewBookings;
