import { MyIcon, IconName } from '@scanny-app/loopy-loop';
import { Form, FormInstance, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

interface CollapsibleFormProps {
  expanded: boolean;
  allFieldsCompleted: boolean;
  iconName: IconName;
  title: string;
  form: FormInstance;
  initialValues?: Store | undefined;
  subForm: React.ReactNode;
  loading?: boolean;
  onBack?: () => void;
  onNext: () => void;
}
function CollapsibleForm({
  expanded,
  allFieldsCompleted,
  iconName,
  title,
  form,
  initialValues,
  subForm,
  loading,
  onBack,
  onNext,
}: CollapsibleFormProps) {
  return (
    <>
      <Form className="w-full form-style-one flex gap-16 flex-column" layout="vertical" form={form} initialValues={initialValues}>
        <div className={`${allFieldsCompleted ? 'box-1-green' : expanded ? 'box-1-global' : 'box-1-dashed'} p-24`}>
          <div className="flex flex-row content-space-between">
            <div className="flex flex-row gap-16">
              <MyIcon icon={iconName} />
              <h5 className="m-0 text-sm">{title}</h5>
            </div>
            {allFieldsCompleted ? <MyIcon icon="IconCircleCompleted" /> : <MyIcon icon="IconCircle" />}
          </div>

          <div className={`${expanded ? 'mt-24 pt-24 border-t-dashed' : 'hidden'}`}>
            {subForm}

            <div className={`border-t-dashed pt-24 ${onBack ? 'content-space-between' : 'content-end'} flex`}>
              {onBack && (
                <button type="button" onClick={onBack} className="btn-outline-global align-middle gap-16 flex flex-row">
                  <MyIcon icon="IconBack" />
                  Back
                </button>
              )}
              <div className={'flex gap-12 middle'}>
                {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <button
                  type="button"
                  onClick={onNext}
                  className="btn-submit align-middle gap-16 flex flex-row"
                  disabled={loading}
                >
                  {iconName === 'IconRaPreauth' ? 'Submit' : 'Next'}
                  {iconName !== 'IconRaPreauth' && <MyIcon icon="IconNext" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default CollapsibleForm;
