import { Form, Input, DatePicker, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import type { RangePickerProps } from 'antd/es/date-picker';
import { compressImage, capitalizeWords, convertToBase64, MyIcon, Address } from '@scanny-app/loopy-loop';

const states = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];
interface DriverFormProps {
  /** This is the name of the key where this form's attributes will be stored **/
  name: string;
  /** Index of 0 is the primary driver. Any other index is the additional driver number**/
  index: number;
  /** If not an index of 0, this attribute clears the driver's licence **/
  handleRemove: () => void;
  /** If this attribute is set to true, only the email field is mandatory **/
  fieldsOptional?: boolean;
}

function DriverForm({ name, index, handleRemove, fieldsOptional }: DriverFormProps) {
  const { Option } = Select;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [driverLicenceFrontBase64, setDriverLicenceFrontBase64] = useState<string>('');
  const [driverLicenceBackBase64, setDriverLicenceBackBase64] = useState<string>('');
  if (!fieldsOptional) fieldsOptional = false;
  function getFormItemName(fieldName: string) {
    return name ? [`${name}`, fieldName] : fieldName;
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(capitalizeWords(e.target.value));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(capitalizeWords(e.target.value));
  };

  const disabledDateLicence: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  };

  const disabledDateDOB: RangePickerProps['disabledDate'] = (current) => {
    // User can select DOB minimum 17 years ago
    return current && current > dayjs().subtract(17, 'years').endOf('day');
  };
  const handleUploadRemove = () => {
    setDriverLicenceFrontBase64('');
  };
  const defaultValueDOB = dayjs().subtract(17, 'years');

  const handleBeforeUploadFront = async (file: File) => {
    compressImage(file, 0.6, 1920, 1080, async (compressedFile: File) => {
      const base64String = await convertToBase64(compressedFile);
      setDriverLicenceFrontBase64(base64String);
    });
    return false;
  };

  const handleBeforeUploadBack = async (file: File) => {
    compressImage(file, 0.6, 1920, 1080, async (compressedFile: File) => {
      const base64String = await convertToBase64(compressedFile);
      setDriverLicenceBackBase64(base64String);
    });

    return false; // This will prevent automatic upload, since we're handling it
  };

  return (
    <div>
      {index !== 0 && (
        <div className="flex content-space-between middle border-t-dashed mt-24">
          <h4 className="mt-24 mb-16 text-sm">
            Additional Driver {index} {(firstName || lastName) && `- ${firstName} ${lastName}`}
          </h4>
          <button type="button" onClick={handleRemove} className="custom-btn-outline flex content-end">
            <MyIcon icon={'IconRemove'} />
          </button>
        </div>
      )}
      <div className="gap-16 grid grid-cols-2 md-grid-cols-1">
        <Form.Item
          name={getFormItemName('firstName')}
          label="First Name"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the first name',
            },
          ]}
        >
          <Input onChange={handleFirstNameChange} className="capitalize" />
        </Form.Item>

        <Form.Item
          name={getFormItemName('lastName')}
          label="Last Name"
          rules={[{ required: !fieldsOptional, message: 'Please enter the last name' }]}
        >
          <Input onChange={handleLastNameChange} className="capitalize" />
        </Form.Item>

        <Form.Item
          name={getFormItemName('mobilePhone')}
          label="Mobile Phone"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the mobile number',
            },
            {
              pattern: /^(?:\(?(?:\+?61|0)4\)?(?:[ -]?[0-9]){7})[0-9]$/,
              message: 'Please enter a valid mobile number',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name={getFormItemName('email')} label="Email" rules={[{ required: true, message: 'Please enter the email' }]}>
          <Input type="email" />
        </Form.Item>
      </div>
      <h5 className="mt-24 mb-16 text-sm">Licence Details</h5>
      <Address
        fieldsOptional={fieldsOptional}
        streetName={getFormItemName('street')}
        street2Name={getFormItemName('streetLine2')}
        cityName={getFormItemName('city')}
        postCodeName={getFormItemName('postCode')}
        stateName={getFormItemName('state')}
      />
      <div className="mt-16 gap-16 grid grid-cols-2 md-grid-cols-1">
        <Form.Item
          name={getFormItemName('dob')}
          label="Date of Birth"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the date of birth',
            },
          ]}
        >
          <DatePicker
            className="box"
            format="DD/MM/YYYY"
            placeholder="Select the date"
            disabledDate={disabledDateDOB}
            defaultPickerValue={defaultValueDOB}
            renderExtraFooter={() => '*must be at least 17 years old'}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          name={getFormItemName('licenceNo')}
          label="Licence No."
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the licence number',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={getFormItemName('licenceStateIssue')}
          label="Licence State of Issue"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the licence state issue',
            },
          ]}
        >
          <Select placeholder="Select licence state">
            {states.map((state) => (
              <Option key={state} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={getFormItemName('licenceExpiryDate')}
          label="Licence Expiry Date"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please enter the licence expiry date',
            },
          ]}
        >
          <DatePicker className="box" format="DD/MM/YYYY" placeholder="Select the date" disabledDate={disabledDateLicence} />
        </Form.Item>

        <Form.Item
          name={getFormItemName('driverLicenceFront')}
          label="Licence Front Photo"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please upload the licence front photo',
            },
          ]}
        >
          <Upload
            className="driver-licence"
            beforeUpload={handleBeforeUploadFront}
            maxCount={1}
            onRemove={handleUploadRemove}
            accept=".jpeg,.jpg,.png,.gif,.bmp,.webp"
          >
            {driverLicenceFrontBase64 !== '' ? null : (
              <button type="button" className="btn-outline w-full content-space-between flex middle">
                Upload Photo
                <MyIcon icon={'IconUpload'} />
              </button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name={getFormItemName('driverLicenceBack')}
          label="Licence Back Photo"
          rules={[
            {
              required: !fieldsOptional,
              message: 'Please upload the licence back photo',
            },
          ]}
        >
          <Upload
            className="driver-licence"
            beforeUpload={handleBeforeUploadBack}
            maxCount={1}
            accept=".jpeg,.jpg,.png,.gif,.bmp,.webp"
          >
            {driverLicenceBackBase64 !== '' ? null : (
              <button type="button" className="btn-outline w-full content-space-between flex middle">
                Upload Photo
                <MyIcon icon={'IconUpload'} />
              </button>
            )}
          </Upload>
        </Form.Item>
        {driverLicenceFrontBase64 !== '' && (
          <Form.Item name={getFormItemName('driverLicenceFrontBase64')} initialValue={driverLicenceFrontBase64} hidden>
            <Input />
          </Form.Item>
        )}
        {driverLicenceBackBase64 !== '' && (
          <Form.Item name={getFormItemName('driverLicenceBackBase64')} initialValue={driverLicenceBackBase64} hidden>
            <Input />
          </Form.Item>
        )}
      </div>
      <div className="text-xs mt-16 italic" style={{ color: '#968f8f' }}>
        *If uploading a digital licence, be sure to capture the entire screen, including the top and bottom. <br />
        *All images are secure and not stored within device
      </div>
    </div>
  );
}

export default DriverForm;
