import { IRentalRequest, downloadInvoicePDF, IInvoice, MyIcon, CustomButton } from '@scanny-app/loopy-loop';

interface InvoiceCardComponentProps {
  invoiceData: IInvoice;
  rentalRequestData: IRentalRequest;
}

function InvoiceCardComponent({ invoiceData, rentalRequestData }: InvoiceCardComponentProps) {
  const handleDownload = async () => {
    downloadInvoicePDF(invoiceData.id, invoiceData.xeroInvoiceNumber);
  };

  return (
    <div className="flex flex-column box-1 overflow-hidden" key={invoiceData.id}>
      <div className="p-16 content-space-between flex middle border-b bg-grey-1">
        <div className="text-sm font-semibold">{invoiceData.name}</div>
        <div className="flex gap-8">
          <button type="button" className="flex middle gap-8 flex-row btn-status on-hire md-hidden" onClick={handleDownload}>
            <MyIcon icon="IconExport" />
            Download PDF
          </button>
        </div>
      </div>
      <div className="p-16 flex flex-row md-flex-column content-space-between text-xs gap-12">
        <div className="grid w-full grid-cols-3 md-grid-cols-2 gap-16">
          <div className="flex flex-column gap-8">
            <div>Invoice Number</div>
            <div className="font-semibold">{invoiceData.xeroInvoiceNumber}</div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Invoice Type</div>
            <div className="font-semibold">{invoiceData.type}</div>
          </div>
          <div className="flex flex-column  gap-8">
            <div>Invoice Amount</div>
            <div className="font-semibold">${invoiceData.orderAmountIncludingGST}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>VIN</div>
            <div className="font-semibold">{rentalRequestData.vehicleVIN}</div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Customer</div>
            <div className="font-semibold">{`${rentalRequestData.customerFirstName} ${rentalRequestData.customerLastName}`}</div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Dealership</div>
            <div className="font-semibold">{rentalRequestData.submittedByCompanyName}</div>
          </div>
        </div>
      </div>

      <div className="pb-16 px-16  md-flex hidden">
        <CustomButton
          className="on-hire w-full content-center"
          onClick={handleDownload}
          btnName={'Download PDF'}
          iconName={'IconExport'}
        />
      </div>
    </div>
  );
}

export default InvoiceCardComponent;
