import { message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export const downloadInvoicePDF = async (id: string, name: string) => {
  try {
    Auth.currentSession().then((response) => {
      const token = response.getAccessToken().getJwtToken();
      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/fetch/file`, JSON.stringify({ linkedEntityId: id }), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          message.error('Something went wrong. Please try again later.');
        });
    });
  } catch {
    message.error('Something went wrong. Please try again later.');
  }
};
