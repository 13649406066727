import { MyIcon } from '@scanny-app/loopy-loop';
function NotFount() {
  return (
    <section id="notfound-section">
      <div className="CenteredContent">
        <MyIcon icon={'IconUpsideDownCar'} />
        <h1
          className="my-16"
          style={{
            color: '#444341',
            fontSize: 46,
            fontWeight: 800,
          }}
        >
          404
        </h1>
        <p style={{ color: '#444341', fontSize: 23, fontWeight: 800, margin: 0 }}>Something went wrong</p>

        <div style={{ marginTop: 24, display: 'flex' }}>
          <a href="/" target="_self" className="btn-primary">
            Home
          </a>
        </div>
      </div>
    </section>
  );
}

export default NotFount;
