import { createContext } from 'react';
import { IBranch } from '@scanny-app/loopy-loop';
export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  profileType?: string;
  portalServices?: string[];
  currentCompany?: string;
  companyName?: string;
  masterUser?: boolean;
  branch?: string;
  userId?: string;
  Branch?: IBranch;
}

export const UserContext = createContext<IUser | null>(null);

export const EmptyUser: IUser = {
  firstName: '',
  lastName: '',
  email: '',
  profileType: '',
  portalServices: [],
  currentCompany: 'NOT_FOUND',
  companyName: 'NOT_FOUND',
  masterUser: false,
  branch: '',
  userId: '',
};
