import { NearDayLimit } from '../../helpers/NearDayLimit';
import { WarningMessage } from '@scanny-app/loopy-loop';
import { useContext } from 'react';
import { Tooltip, message } from 'antd';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  BookingStatusComponent,
  IBooking,
  IRentalRequest,
  UserContext,
  MyIcon,
  ToolTipOptions,
  LargeLoading,
  ExchangeCar,
  IExchangeForm,
  EstimatedFinishDate,
  IEstimatedFinishDateForm,
} from '@scanny-app/loopy-loop';
interface RentalCardComponentProps {
  mostRecentBooking: IBooking | null;
  rentalRequestData: IRentalRequest;
  onClickTab: (value: string) => void;
  setReloadDataRentalRequest: (value: boolean) => void;
}

function RentalCardComponent({
  mostRecentBooking,
  rentalRequestData,
  onClickTab,
  setReloadDataRentalRequest,
}: RentalCardComponentProps) {
  const userData = useContext(UserContext);
  const isDealer = userData?.profileType === 'Dealer';
  const isNearDayLimit = NearDayLimit(rentalRequestData, 'isNearDayLimit');
  const [openExchangeModal, setOpenExchangeModal] = useState(false);
  const [openEstFinDateModal, setOpenEstFinDateModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => {
    setOpenExchangeModal(false);
    setOpenEstFinDateModal(false);
  };
  const handleExchangeSubmit = (values: IExchangeForm) => {
    setIsLoading(() => true);
    Auth.currentSession().then((data) => {
      const token = data.getAccessToken().getJwtToken();
      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/Exchange`, JSON.stringify(values), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setOpenExchangeModal(false);
            setReloadDataRentalRequest(true);
            message.success('Exchange submitted successfully');
            setIsLoading(false);
          } else {
            message.error('Error submitting exchange');
            setIsLoading(false);
          }
        })
        .catch(() => {
          message.error('Error submitting exchange');
          setIsLoading(false);
        });
    });
  };

  const handleEstDateUpdate = (values: IEstimatedFinishDateForm) => {
    setIsLoading(() => true);
    Auth.currentSession().then((data) => {
      const token = data.getAccessToken().getJwtToken();
      axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/RentalRequest/${rentalRequestData.id}`, JSON.stringify(values), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setOpenEstFinDateModal(false);
            setReloadDataRentalRequest(true);
            message.success('Estimated finish date successfully updated');
            setIsLoading(false);
          } else {
            message.error('Error updating estimated finish date');
            setIsLoading(false);
          }
        })
        .catch(() => {
          message.error('Error updating estimated finish date');
          setIsLoading(false);
        });
    });
  };
  return (
    <div className="flex flex-column box-1 overflow-hidden" key={rentalRequestData.id}>
      <div className="p-16 content-space-between flex middle border-b bg-grey-1">
        <div className="text-sm font-semibold">{rentalRequestData.hireName}</div>
        <div className="flex flex-row middle gap-4">
          <BookingStatusComponent status={rentalRequestData.bookingStatus} />
          {isDealer && mostRecentBooking?.status !== 'Complete' && (
            <Tooltip
              color={'#FFF'}
              key={'#000'}
              placement="bottom"
              id={'custom-tooltip'}
              mouseLeaveDelay={0.7}
              title={
                <ToolTipOptions
                  toolTipOptions={[
                    {
                      title: 'Change vehicle',
                      iconName: 'IconSwapCar',
                      onClick: () => setOpenExchangeModal(true),
                      show: mostRecentBooking?.status === 'In Progress',
                    },
                    {
                      title: 'Edit estimated finish date',
                      iconName: 'IconCalendar',
                      onClick: () => setOpenEstFinDateModal(true),
                      show: mostRecentBooking?.status === 'In Progress' || mostRecentBooking?.status === 'Scheduled',
                    },
                  ]}
                />
              }
              trigger={['hover', 'focus']}
            >
              <div className={'p-4-8 flex middle hover-pointer'}>
                <MyIcon icon="IconKebabMenu" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {isLoading && <LargeLoading />}
      {isNearDayLimit && isDealer && (
        <WarningMessage
          messageText={'This booking requires an extension request'}
          actionText={'Request Extension'}
          onClickAction={() => onClickTab('2')}
        />
      )}
      {mostRecentBooking && (
        <ExchangeCar
          open={openExchangeModal}
          onCancel={handleCancel}
          bookingId={mostRecentBooking?.id}
          onExchangeSubmit={handleExchangeSubmit}
          isLoading={isLoading}
        />
      )}
      {mostRecentBooking && (
        <EstimatedFinishDate open={openEstFinDateModal} onCancel={handleCancel} onEstSubmit={handleEstDateUpdate} />
      )}

      <div className="p-16 flex flex-column gap-16">
        <div className=" grid grid-cols-3 md-grid-cols-2 text-xs gap-16">
          <div className="flex flex-column gap-8">
            <div>Hire Start Date</div>
            <div className="font-semibold">
              {rentalRequestData.hireStartDate ? new Date(rentalRequestData.hireStartDate).toLocaleDateString('en-AU') : '-'}
            </div>
          </div>

          {rentalRequestData.bookingStatus === 'Completed' ? (
            <div className="flex flex-column gap-8 ">
              <div>Hire Finish Date</div>
              <div className="font-semibold">
                {rentalRequestData.hireFinishDate ? new Date(rentalRequestData.hireFinishDate).toLocaleDateString('en-AU') : '-'}
              </div>
            </div>
          ) : (
            <div className="flex flex-column gap-8 ">
              <div>Estimated Finish Date</div>
              <div className="font-semibold">
                {rentalRequestData.hireEstimatedFinishDate
                  ? new Date(rentalRequestData.hireEstimatedFinishDate).toLocaleDateString('en-AU')
                  : '-'}
              </div>
            </div>
          )}
          <div className="flex flex-column gap-8 ">
            <div>Total Days </div>
            <div className="font-semibold">{rentalRequestData.hireNumberOfDays ? rentalRequestData.hireNumberOfDays : '0'}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Car Make</div>
            <div className="font-semibold">
              {mostRecentBooking ? (mostRecentBooking?.vehicleMake !== undefined ? mostRecentBooking?.vehicleMake : '-') : '-'}
            </div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Car Model</div>
            <div className="font-semibold">
              {mostRecentBooking ? (mostRecentBooking?.vehicleModel !== undefined ? mostRecentBooking?.vehicleModel : '-') : '-'}
            </div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Rego </div>
            <div className="font-semibold">
              {mostRecentBooking ? (mostRecentBooking?.vehicleRego !== undefined ? mostRecentBooking?.vehicleRego : '-') : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalCardComponent;
