import { IRentalRequest } from '@scanny-app/loopy-loop';
import { NearDayLimit } from './NearDayLimit';

function FilterData(
  combinationColumns: [string, string][],
  data: IRentalRequest[],
  columnsToSearch: string[],
  selectedBookingStatusFilter: string[],
  selectedSubmittedByFilter: string[],
  selectedApprovalFilter: string[],
  selectedInvoiceStatusFilter: string[],
  searchInputValue: string,
  dayLimitFilter: boolean,
  invoiceFilter: boolean,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.filter((item: any) => {
    if (selectedBookingStatusFilter.length > 0 && !selectedBookingStatusFilter.includes(item.bookingStatus)) {
      return false;
    }
    if (selectedSubmittedByFilter.length > 0 && !selectedSubmittedByFilter.includes(item.submittedByCompanyId)) {
      return false;
    }
    if (selectedApprovalFilter.length > 0 && !selectedApprovalFilter.includes(item.status)) {
      return false;
    }
    if (selectedInvoiceStatusFilter.length > 0 && !selectedInvoiceStatusFilter.includes(item.hireInvoiceStatus)) {
      return false;
    }
    if (invoiceFilter && !(item.hireInvoice !== null && item.bookingStatus === 'Completed')) {
      return false;
    }
    if (dayLimitFilter) {
      const filterNearDayLimit = NearDayLimit(item, 'filterNearDayLimit');
      if (!filterNearDayLimit) {
        return false;
      }
    }
    if (searchInputValue !== '') {
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columnsToSearch.some((column: any) => {
          return String(item[column]).toLowerCase().includes(searchInputValue.toLowerCase());
        }) ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (combinationColumns || []).some((pair: any) => {
          const combinedValue = String(item[pair[0]]) + ' ' + String(item[pair[1]]);
          return combinedValue.toLowerCase().includes(searchInputValue.toLowerCase());
        })
      );
    }
    return true; // Return true if none of the filters are active
  });
}

export default FilterData;
