import { RefObject, useEffect } from 'react';

interface OutsideClickProps<T extends HTMLElement> {
  ref: RefObject<T>;
  callback: () => void;
}

export const useOutsideClick = <T extends HTMLElement>({ ref, callback }: OutsideClickProps<T>): void => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
