import { MyIcon, IconName } from '@scanny-app/loopy-loop';

interface CustomButtonProps {
  className: string;
  onClick: () => void;
  btnName: string;
  iconName: IconName;
  id?: string;
}

function CustomButton({ className, onClick, btnName, iconName, id }: CustomButtonProps) {
  return (
    <>
      <button type="button" className={`flex middle gap-12 flex-row btn-status ${className}`} onClick={onClick} data-id={id}>
        <MyIcon icon={iconName} />
        {btnName}
      </button>
    </>
  );
}

export default CustomButton;
