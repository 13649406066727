import { MyIcon, IconName } from '@scanny-app/loopy-loop';

interface ToolTipOption {
  show: boolean;
  iconName: IconName;
  title: string;
  onClick: () => void;
}
interface ToolTipOptionsProps {
  toolTipOptions: ToolTipOption[];
}
function ToolTipOptions({ toolTipOptions }: ToolTipOptionsProps) {
  return (
    <div className="flex flex-column text-black hover-pointer tooltip-option">
      {toolTipOptions.map((element: ToolTipOption) => (
        <>
          {element.show && (
            <div className={'divider-dropdown py-4'}>
              <div
                key={element.title}
                className="p-8-4 flex middle icon-sm flex gap-4 hover-dark-grey hover-rounded-6"
                onClick={element.onClick}
              >
                <div className={'w-15 content-center flex'}>
                  <MyIcon icon={element.iconName} />
                </div>
                {element.title}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default ToolTipOptions;
