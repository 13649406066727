import React, { useState } from 'react';
import MyIcon from '../../icons/General/MyIcon';
import PopupFeedback from '../../components/Modals/PopupFeedback';

function FeedbackButton() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <PopupFeedback open={showModal} onCancel={() => setShowModal(false)} />
      <div id="feedback" className="flex middle content-center" onClick={() => setShowModal(true)}>
        <MyIcon icon={'IconFeedback'} />
        Feedback
      </div>
    </>
  );
}

export default FeedbackButton;
