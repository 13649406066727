import Lottie from 'react-lottie';
import animationData from './car-loader.json';
import { useEffect, useState } from 'react';

interface SmallLoadingProps {
  customText?: string;
}

function SmallLoading({ customText }: SmallLoadingProps) {
  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex w-full middle content-center h-full car-loader flex-column lottie-loading">
      <Lottie options={options} height={90} width={90} style={{ marginTop: -20 }} />
      {customText !== undefined && (
        <h6 className="text-xs font-semibold m-0 text-center pb-12" style={{ width: 200 }}>
          {customText}
        </h6>
      )}
    </div>
  );
}

interface LargeLoadingProps {
  customText?: string;
}

function LargeLoading({ customText }: LargeLoadingProps) {
  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Color state
  const [currentColor, setCurrentColor] = useState('#fff');

  const colors = ['var(--white-100)', 'var(--global-color)']; // example colors
  let colorIndex = 0;

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeColor = (color: any) => {
      const paths = document.querySelectorAll('.lottie-loading svg *');
      paths.forEach((path) => {
        if (path.getAttribute('stroke')) {
          path.setAttribute('stroke', color);
        }
        if (path.getAttribute('fill') && path.getAttribute('fill') !== 'none') {
          path.setAttribute('fill', color);
        }
      });
    };

    const svgCheckTimer = setInterval(() => {
      if (document.querySelector('svg')) {
        clearInterval(svgCheckTimer);
        changeColor(currentColor);
      }
    }, 100);

    const colorChangeTimer = setInterval(() => {
      colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
      setCurrentColor(colors[colorIndex]);
      changeColor(colors[colorIndex]);
    }, 500);

    return () => {
      clearInterval(svgCheckTimer);
      clearInterval(colorChangeTimer);
    };
  }, [currentColor]);

  return (
    <div className="loading-spinner flex middle content-center flex-column lottie-loading">
      <Lottie options={options} height={120} width={120} style={{ marginTop: -40 }} />

      {customText !== undefined && (
        <h6
          className="text-base font-semibold px-24 flex content-center text-center m-0"
          style={{ color: currentColor, width: 240 }}
        >
          {customText}
        </h6>
      )}
    </div>
  );
}

export { SmallLoading, LargeLoading };
