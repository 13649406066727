import { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import SearchColumns from './SearchColumns';
import { IRentalRequest } from '@scanny-app/loopy-loop';
interface DataTableProps<T> {
  columns: ColumnsType<T>;
  data: T[];
  isLoading?: boolean;
  columnsToSearch: string[];
  combinationColumns: [string, string][];
  className?: string;
  apiCompleted: boolean;
  searchPlaceholder: string;
  getRowKey: (record: T) => string | number;
}

const DataTable = <T extends object>({
  columns,
  data,
  columnsToSearch,
  combinationColumns,
  className,
  apiCompleted,
  searchPlaceholder,
  getRowKey,
}: DataTableProps<T>) => {
  const [filteredData, setFilteredData] = useState<T[]>([]); // This is the data that is filtered by the search bar
  const [isLoading, setIsLoading] = useState(true);
  const [hasFiltered, setHasFiltered] = useState(false);
  const dynamicY = `calc(100vh - 300px)`;
  const [activeSorter, setActiveSorter] = useState('sorted-active');

  useEffect(() => {
    if (apiCompleted && hasFiltered) {
      setIsLoading(false);
    }
  }, [data, hasFiltered]);

  const handleFilteredData = (newFilteredData: T[], checkFiltered: boolean) => {
    setFilteredData(() => newFilteredData);
    setHasFiltered(() => checkFiltered);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSort = (pagination: any, filters: any, sorter: any) => {
    setActiveSorter(!sorter.columnKey ? 'sorted-active' : '');
  };

  return (
    <>
      {columnsToSearch && (
        <SearchColumns<T>
          dataSource={data as IRentalRequest[]}
          combinationColumns={combinationColumns}
          columnsToSearch={columnsToSearch}
          onFilterChange={handleFilteredData}
          apiCompleted={apiCompleted}
          searchPlaceholder={searchPlaceholder}
        />
      )}
      {isLoading && <Skeleton active />}
      <Table
        id="tb-rental-request"
        columns={columns}
        dataSource={filteredData}
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: '100%', y: dynamicY }}
        className={`${className} ${isLoading && 'invisible'} ${activeSorter}`}
        rowKey={getRowKey}
        onChange={handleSort}
      />
    </>
  );
};

export default DataTable;
