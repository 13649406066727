import { MyIcon } from '@scanny-app/loopy-loop';
function SomethingWentWrong() {
  return (
    <section id="notfound-section">
      <div className="CenteredContent">
        <MyIcon icon={'IconUpsideDownCar'} />
        <h1
          style={{
            color: '#444341',
            fontSize: 46,
            fontWeight: 800,
            marginBottom: 16,
          }}
        >
          500
        </h1>
        <p style={{ color: '#444341', fontSize: 23, fontWeight: 800, margin: 0 }}>
          Something went wrong. Please reload the page.
        </p>
      </div>
    </section>
  );
}

export default SomethingWentWrong;
