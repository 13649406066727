import { CustomButton, DefaultCard, EmptyCard, IRentalRequest, MyIcon } from '@scanny-app/loopy-loop';

interface OverviewChecklistProps {
  rentalRequestData: IRentalRequest;
  setViewChecklistCar: (value: boolean) => void;
}

function OverviewChecklist({ rentalRequestData, setViewChecklistCar }: OverviewChecklistProps) {
  return (
    <div>
      {rentalRequestData.finishDate !== null &&
      rentalRequestData.hireChecklistIn === null &&
      rentalRequestData.bookingStatus === 'In Progress' ? (
        <CustomButton
          className={'on-hire-lg w-full content-center h-45'}
          onClick={() => setViewChecklistCar(true)}
          btnName={'Checklist Car'}
          iconName={'IconChecklistCar'}
        />
      ) : rentalRequestData.hireChecklistIn != null && rentalRequestData.status !== 'Rejected' ? (
        <DefaultCard
          className={'content-space-between'}
          content={
            <>
              Checklist is submitted
              <MyIcon icon={'IconChecklistCar'} />
            </>
          }
        />
      ) : (
        <EmptyCard />
      )}
    </div>
  );
}

export default OverviewChecklist;
