import { RENTAL_REQUEST_DATA_COLUMNS } from '../../config/Columns';
import { useNavigate } from 'react-router-dom';
import { PrimaryNavigation, MyIcon, UserContext, IRentalRequest } from '@scanny-app/loopy-loop';
import DataTable from '../../components/Table/DataTable';
import { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { message } from 'antd';
import { ColumnsType } from 'antd/es/table';
interface RentalRequestsProps {
  toggleSidebar?: (() => void) | undefined;
  sidebarVisibleInMobile?: boolean | undefined;
}

const RentalRequests = ({ toggleSidebar, sidebarVisibleInMobile }: RentalRequestsProps) => {
  const userData = useContext(UserContext);

  const navigate = useNavigate();
  const [data, setData] = useState<IRentalRequest[]>([]);
  const [apiCompleted, setApiCompleted] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const filterParam = queryParams.get('filter') || '';
  const [widthMobile, setWidthMobile] = useState(window.innerWidth <= 768);
  const [columns, setColumns] = useState<ColumnsType<IRentalRequest>>(() =>
    RENTAL_REQUEST_DATA_COLUMNS(filterParam, navigate, widthMobile, userData?.profileType || ''),
  );
  useEffect(() => {
    let run = true;
    Auth.currentSession()
      .then((responseData) => {
        const token = responseData.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/RentalRequest`, JSON.stringify({}), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (run) {
              setData(() => response.data);
              setApiCompleted(true);
            }
          })
          .catch(() => {
            message.error('Something went wrong. Please try again.');
          });
      })
      .catch(() => {
        message.error('Something went wrong. Please try again.');
      });
    return () => {
      run = false;
    };
  }, []);

  const rightNode = (
    <>
      {userData?.profileType === 'Dealer' && (
        <button
          className="btn-secondary flex middle flex-row md-text-hide gap-12 md-btn-transparent md-icon"
          onClick={() => navigate('/rentals/rental-requests/add')}
        >
          <MyIcon icon="IconRentalRequest" />
          <span>New rental request</span>
        </button>
      )}
    </>
  );
  useEffect(() => {
    setColumns(() => RENTAL_REQUEST_DATA_COLUMNS(filterParam, navigate, widthMobile, userData?.profileType || ''));
  }, [filterParam, widthMobile]);
  useEffect(() => {
    const handleResize = () => {
      setWidthMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columnsToSearchTempObj = {
    'invoice-received': {
      fields: ['customerFirstName', 'customerLastName', 'vehicleVIN'],
      placeholder: 'Search customer name or VIN',
    },
    'nearing-day-limit': {
      fields: ['customerFirstName', 'customerLastName', 'vehicleVIN', 'vehicleRego'],
      placeholder: 'Search customer name, VIN or customer rego',
    },
    default: {
      fields: [
        'customerFirstName',
        'customerLastName',
        'vehicleVIN',
        'customerContactNumber',
        'vehicleRego',
        'repairOrderNumber',
        'VORNumber',
      ],
      placeholder: 'Search name, VIN, mobile, customer rego, repair order number, VOR number',
    },
  };
  return (
    <>
      <PrimaryNavigation
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisibleInMobile}
        rightNode={rightNode}
        menuTitle={'Rental Request'}
      />

      <div className="container-1 p-24 md-w-fix table-wrapper">
        <DataTable<IRentalRequest>
          columns={columns}
          data={data}
          columnsToSearch={
            columnsToSearchTempObj[filterParam as keyof typeof columnsToSearchTempObj]?.fields ||
            columnsToSearchTempObj.default.fields
          }
          searchPlaceholder={
            columnsToSearchTempObj[filterParam as keyof typeof columnsToSearchTempObj]?.placeholder ||
            columnsToSearchTempObj.default.placeholder
          }
          combinationColumns={[['customerFirstName', 'customerLastName']]}
          className="md-rental-request"
          apiCompleted={apiCompleted}
          getRowKey={(record) => record.id.toString()}
        />
      </div>
    </>
  );
};

export default RentalRequests;
