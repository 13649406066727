import { SideMenu, AppConfigContext, FeedbackButton } from '@scanny-app/loopy-loop';
import React, { useContext, useState } from 'react';


interface AppContentProps {
  pageContent: React.ReactNode;
  pageName?: string;
  bottomNode?: React.ReactNode;
}

function AppContent({ pageContent, pageName, bottomNode }: AppContentProps) {
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const [sidebarVisibleInMobile, setSidebarVisibleInMobile] = useState(false);
  const { showSideBarText, feedbackButton } = useContext(AppConfigContext);

  const toggleSidebar = () => {
    setSidebarVisibleInMobile((prevState) => !prevState);
  };

  const enhancedPageContent = React.cloneElement(pageContent as React.ReactElement, {
    toggleSidebar: toggleSidebar,
    sidebarVisibleInMobile: sidebarVisibleInMobile,
  });

  return (
    <section id="app-container" className="app-inner-wrap relative">
      <SideMenu
        sidebarVisibleInMobile={sidebarVisibleInMobile}
        setSubMenuVisible={setSubMenuVisible}
        toggleSidebar={toggleSidebar}
        bottomNode={bottomNode}
      />

      <div
        id="page-content"
        className={`${subMenuVisible ? 'page-large' : 'page-small'} ${showSideBarText ? 'long' : 'short'} ${
          sidebarVisibleInMobile ? 'md-vh-full md-overflow-hidden' : ''
        }`}
        data-name={pageName}
      >
        {enhancedPageContent}
        {sidebarVisibleInMobile && <div className="md-overlay-container" onClick={toggleSidebar}></div>}
      </div>

      {feedbackButton && (
        <FeedbackButton />
      )}
    </section>
  );
}

export default AppContent;
