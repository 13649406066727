import { Form, Checkbox, Button } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { useRef, useState } from 'react';

interface RentalAgreementProps {
  isVisible: boolean;
  onSignatureChange: (dataUrl: string) => void;
  AgreementDescription: React.ReactNode;
  showExcessReduction?: boolean;
  showMarketingAndCommunication?: boolean;
}

function RentalAgreement({
  isVisible,
  onSignatureChange,
  AgreementDescription,
  showExcessReduction,
  showMarketingAndCommunication,
}: RentalAgreementProps) {
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [isSignatureExist, setIsSignatureExist] = useState(false);

  const handleEndDraw = () => {
    if (signatureRef && signatureRef.current) {
      const signatureDataURL = signatureRef.current?.toDataURL();
      onSignatureChange(signatureDataURL);
      setIsSignatureExist(true);
    }
  };

  const clearSignature = () => {
    signatureRef.current?.clear();
    onSignatureChange('');
  };

  return (
    <div className="gap-16 grid grid-cols-1 pb-16">
      <Form.Item className="box-1 p-12 agreement-desc">{AgreementDescription}</Form.Item>
      <Form.Item
        className="box-1 px-12 py-4"
        name="agreedToRental"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: '',
          },
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('You must agree to the rental agreement')),
          },
        ]}
      >
        <Checkbox className="font-normal">
          <span className="required-checkbox-label">
            I agree to the <strong>Rental Agreement</strong>
          </span>
        </Checkbox>
      </Form.Item>
      {showExcessReduction && (
        <Form.Item
          className="box-1 px-12 py-4"
          name="excessReduction"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: '',
            },
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('You must agree to the excess reduction')),
            },
          ]}
        >
          <Checkbox className="font-normal">
            <span className="required-checkbox-label">I agree to an excess reduction free shown in Rates & Fees</span>
          </Checkbox>
        </Form.Item>
      )}
      {showMarketingAndCommunication && (
        <Form.Item className="box-1 px-12 py-4" name="marketingAndCommunication" valuePropName="checked">
          <Checkbox className="font-normal">
            <span>I would like to receive marketing communications</span>
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item label="Signature" name="signature" rules={[{ required: true, message: 'Please provide your signature.' }]}>
        {(isVisible || isSignatureExist) && (
          <SignatureCanvas
            ref={signatureRef}
            onEnd={handleEndDraw}
            canvasProps={{
              width: 277,
              height: 140,
              className: 'box-1',
            }}
          />
        )}
      </Form.Item>

      <Button onClick={clearSignature} className="cust-btn" style={{ width: '150px' }}>
        Clear Signature
      </Button>
    </div>
  );
}

export default RentalAgreement;
