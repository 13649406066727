import { useState } from 'react';
import { DriverForm, CustomButton } from '@scanny-app/loopy-loop';

function MultipleDrivers() {
  const [generateId, setGenerateId] = useState(0);

  const [drivers, setDrivers] = useState([{ id: generateId }]);
  const addDriver = () => {
    setGenerateId((prevId) => {
      const newId = prevId + 1;
      setDrivers((prevDrivers) => [...prevDrivers, { id: newId }]);
      return newId;
    });
  };

  const removeDriver = (id: number) => {
    if (drivers.length > 1) {
      const newDrivers = drivers.filter((driver) => driver.id !== id);
      setDrivers(newDrivers);
    }
  };

  return (
    <>
      {drivers.map((driver, index) => (
        <div key={driver.id}>
          <DriverForm
            key={driver.id}
            index={index}
            name={driver.id === 0 ? '' : `additionalDriver${driver.id}`}
            handleRemove={() => removeDriver(driver.id)}
          />
        </div>
      ))}
      <div className="border-t-dashed pt-24 mt-24 flex">
        <CustomButton
          className={'on-hire-lg w-full content-center h-45'}
          btnName={'Add an additional driver'}
          iconName={'IconAdd'}
          onClick={addDriver}
        />
      </div>
      <div className="text-xs mt-16 mb-24 italic" style={{ color: '#968f8f' }}>
        *All drivers must be added in order to be covered by insurance.
      </div>
    </>
  );
}

export default MultipleDrivers;
