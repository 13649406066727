import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Input, Typography, Card, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RuleObject } from 'antd/es/form';
import { SmallLoading } from '@scanny-app/loopy-loop';
interface ResetPasswordProps {
  setShowResetPassword: (value: boolean) => void;
}

function ResetPassword({ setShowResetPassword }: ResetPasswordProps) {
  const [userEmail, setUserEmail] = useState('');
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId); // Cleanup on unmount or if timeLeft changes
    }
  }, [timeLeft]);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleSendCode = async () => {
    if (!emailRegex.test(userEmail)) {
      return;
    }

    try {
      await Auth.forgotPassword(userEmail);
      setResetCodeSent(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message.error(error.message);
    }

    setTimeLeft(60);
  };

  const handleResetPassword = async () => {
    if (!userEmail || !resetCode || !newPassword) {
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(userEmail, resetCode, newPassword);
      if (process.env.REACT_APP_SHOW_PASSWORD_RESET_MESSAGE) {
        localStorage.setItem('hasResetPassword', 'true');
      }
      setShowSuccessMessage(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const checkForLeadingOrTrailingSpaces = (rule: RuleObject, value: string) => {
    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
      return Promise.reject('Your password can’t start or end with a blank space');
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="mb-24 flex gap-8 flex-column">
        <h1 className="m-0">Reset password?</h1>
        <p className="m-0">Enter your email to receive reset code</p>
      </div>

      <Form name="basic" layout="vertical" form={form}>
        <Form.Item label="Email" name="Email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} disabled={resetCodeSent} />
        </Form.Item>

        {resetCodeSent ? (
          <>
            <Form.Item label="Reset Code" name="resetCode" rules={[{ required: true, message: 'Please input the reset code!' }]}>
              <Input value={resetCode} onChange={(e) => setResetCode(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <button disabled={timeLeft > 0} onClick={handleSendCode} className="btn-outline">
                Resend Code
              </button>
              {timeLeft > 0 && <span className="text-xs ml-8"> You can resend in {timeLeft} seconds</span>}
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: 'Please enter your new password' },
                { validator: checkForLeadingOrTrailingSpaces },
              ]}
            >
              <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <button onClick={handleResetPassword} className="btn-login-form">
                Reset Password
              </button>
            </Form.Item>
          </>
        ) : (
          <Form.Item>
            <button onClick={handleSendCode} className="btn-login-form">
              Send Reset Code
            </button>
          </Form.Item>
        )}
        {showSuccessMessage && (
          <div
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              justifyContent: 'center',
              backdropFilter: 'blur(10px)',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <Card>
              <div className="CenterItems">
                <SmallLoading />
                <Typography.Text>Password successfully changed. Navigating to sign in...</Typography.Text>
              </div>
            </Card>
          </div>
        )}
      </Form>
      <div className="text-xs w-full text-center font-bold hover-pointer pb-24" onClick={() => setShowResetPassword(false)}>
        Back to login
      </div>
    </>
  );
}

export default ResetPassword;
