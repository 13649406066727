import { IInvoice, IRentalRequest, DefaultCard, EmptyDashedCard } from '@scanny-app/loopy-loop';
import InvoiceCardComponent from '../Card/CardInvoice';

interface OverviewInvoiceProps {
  invoiceData: IInvoice[];
  rentalRequestData: IRentalRequest;
}

function OverviewInvoice({ invoiceData, rentalRequestData }: OverviewInvoiceProps) {
  return (
    <>
      {invoiceData.length === 0 || rentalRequestData.bookingStatus !== 'Completed' ? (
        rentalRequestData.status === 'Rejected' ? (
          <EmptyDashedCard />
        ) : (
          <DefaultCard content={'Available after Hire completion'} />
        )
      ) : (
        invoiceData.map((invoice: IInvoice) => (
          <InvoiceCardComponent key={invoice.id} invoiceData={invoice} rentalRequestData={rentalRequestData} />
        ))
      )}
    </>
  );
}

export default OverviewInvoice;
