import React from 'react';

function EmptyCard() {
  return <div className="h-45 box-1-dashed"></div>;
}

function EmptyDashedCard() {
  return <div className="status-rental rental-default">---</div>;
}

interface DefaultCardProps {
  className?: string;
  content: React.ReactNode;
}

function DefaultCard({ className, content }: DefaultCardProps) {
  return <div className={`box-1-grey flex gap-12 middle text-xs italic p-16 ${className}`}>{content}</div>;
}

export { EmptyCard, DefaultCard, EmptyDashedCard };
