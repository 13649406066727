// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterOptions = (input: string, option: any) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterOptionsVehicles = (input: string, option: any) => {
  const optionText = `${option.makeName} ${option.modelName} - ${option.name}`;

  return optionText.toLowerCase().includes(input.toLowerCase());
};

export { filterOptions, filterOptionsVehicles };
