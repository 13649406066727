import { IInvoice, MyIcon } from '@scanny-app/loopy-loop';
import InvoiceCardComponent from '../Card/CardInvoice';
import { OverviewData } from '../../pages/SinglePages/ViewRecord';
interface ViewInvoicesProps {
  overviewData: OverviewData;
}
function ViewInvoices({ overviewData: { rentalRequestData, invoiceData } }: ViewInvoicesProps) {
  return (
    <>
      {!invoiceData || invoiceData.length === 0 || rentalRequestData.bookingStatus !== 'Completed' ? (
        <div className="flex md-flex-column flex-row overflow-hidden p-24 gap-24 md-gap-16">
          <MyIcon icon="IconCardInCloud" />
          <div className="flex flex-column content-center start md-middle gap-4 md-gap-8">
            <h6 className="text-sm m-0 text-center">No invoices</h6>
            <p className="text-xs m-0 text-center">When you have invoices, they will appear here.</p>
          </div>
        </div>
      ) : (
        invoiceData.map((invoice: IInvoice) => {
          return <InvoiceCardComponent key={invoice.id} invoiceData={invoice} rentalRequestData={rentalRequestData} />;
        })
      )}
    </>
  );
}
export default ViewInvoices;
