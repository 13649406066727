import { Tabs, message } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import ViewBookings from '../../components/ViewData/ViewBookings';
import ViewInvoices from '../../components/ViewData/ViewInvoices';
import ViewApprovals from '../../components/ViewData/ViewApprovals';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Overview from '../../components/ViewData/Overview';
import ChecklistReturnCar from './ChecklistReturnCar';
import {
  LargeLoading,
  UserContext,
  IRequestExtension,
  IComment,
  IRentalRequest,
  IInvoice,
  IBooking,
  SecondaryNavigation,
  MyIcon
} from '@scanny-app/loopy-loop';

interface ViewRecordProps {
  setReloadDataDashboard: (value: boolean) => void;
}

export interface OverviewData {
  rentalRequestData: IRentalRequest;
  bookingData: IBooking[];
  invoiceData: IInvoice[];
  requestExtensionData: IRequestExtension[];
  filterParam: string | null;
  setReloadDataDashboard: (value: boolean) => void;
  setReloadDataRentalRequest: (value: boolean) => void;
  setViewChecklistCar: (value: boolean) => void;
  onClickTab: (tabKey: string) => void;
}
function ViewRecord({ setReloadDataDashboard }: ViewRecordProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [overviewData, setOverviewData] = useState<OverviewData>({} as OverviewData);
  const [getDataError, setGetDataError] = useState<string | null>(null);
  const { id } = useParams();
  const [reloadDataRentalRequest, setReloadDataRentalRequest] = useState(false);
  const [viewChecklistCar, setViewChecklistCar] = useState(false);
  const userData = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const approvalsParam = queryParams.get('approvals');
  const filterParam = queryParams.get('filter');
  const [activeTab, setActiveTab] = useState('1');
  const [reloadDataComment, setReloadDataComment] = useState(false);
  const [commentData, setCommentData] = useState<IComment[]>([]);
  const rentalId = overviewData.rentalRequestData?.id;

  const noBooking =
    overviewData.rentalRequestData !== null &&
    (overviewData.rentalRequestData?.bookingStatus === 'No booking' ||
      overviewData.rentalRequestData?.bookingStatus === 'Pending approval');

  const onClickTab = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Overview`,
      children: <Overview overviewData={overviewData} />,
    },

    {
      key: '2',
      label: `Approvals`,
      children: <ViewApprovals overviewData={overviewData} commentData={commentData} setReloadDataComment={setReloadDataComment} />,
    },
    {
      key: '3',
      label: `Bookings`,
      disabled: noBooking,
      children: <ViewBookings overviewData={overviewData} />,
    },
    {
      key: '4',
      label: `Invoices`,
      disabled: noBooking,
      children: <ViewInvoices overviewData={overviewData} />,
    },
  ];

  useEffect(() => {
    if (approvalsParam) setActiveTab('2');
  }, [approvalsParam]);

  useEffect(() => {
    const fetchDataRentalRequest = async () => {
      try {
        const sessionData = await Auth.currentSession();
        const token = sessionData.getAccessToken().getJwtToken();
        axios
          .get(`${process.env.REACT_APP_ENDPOINT_URL}/v1/overview/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setOverviewData({
              rentalRequestData: response.data[0][0],
              bookingData: response.data[2],
              invoiceData: response.data[3],
              requestExtensionData: response.data[1],
              filterParam: filterParam,
              setReloadDataDashboard: setReloadDataDashboard,
              setReloadDataRentalRequest: setReloadDataRentalRequest,
              setViewChecklistCar: setViewChecklistCar,
              onClickTab: onClickTab,
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setGetDataError('Failed to retrieve records. Please try again later.');
          });
      } catch {
        setIsLoading(false);
        setGetDataError('Something went wrong. Please try again.');
      }
    };

    fetchDataRentalRequest();
    if (reloadDataRentalRequest) {
      setReloadDataRentalRequest(false);
    }
  }, [id, reloadDataRentalRequest]);

  useEffect(() => {
    rentalId &&
      Auth.currentSession()
        .then((data) => {
          const token = data.getAccessToken().getJwtToken();
          axios
            .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Comment`, JSON.stringify({ rentalRequestId: rentalId }), {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              setCommentData(response.data);
            })
            .catch(() => {
              message.error('Error getting comment');
            });
        })
        .catch(() => {
          message.error('Error getting comment1');
        });

    if (reloadDataComment) {
      setReloadDataComment(false);
    }
  }, [reloadDataComment, rentalId]);

  if (isLoading) {
    return <LargeLoading />;
  }
  const leftNode = getDataError
    ? ''
    : `${overviewData.rentalRequestData.customerFirstName} ${overviewData.rentalRequestData.customerLastName}`;

  const onBack = () =>
    navigate(
      filterParam !== '' && filterParam !== null ? `/rentals/rental-requests?filter=${filterParam}` : `/rentals/rental-requests`,
    );

  return (
    <>
      {getDataError ? (
        <div className="container-2 p-24 flex flex-column content-center middle gap-16">
          <MyIcon icon="IconErrorInCloud"/>
          <h1 className="heading-page-content">{getDataError}</h1>
          <a className="no-underline" href="/rentals/rental-requests">
            <button className="btn-secondary flex middle"> Back to rental requests</button>
          </a>
        </div>
      ) : viewChecklistCar ? (
        <ChecklistReturnCar
          bookings={overviewData.bookingData}
          rentalRequestData={overviewData.rentalRequestData}
          setViewChecklistCar={setViewChecklistCar}
          setReloadDataRentalRequest={setReloadDataRentalRequest}
        />
      ) : (
        <>
          <SecondaryNavigation menuTitle={leftNode} rightNode={''} onBack={onBack} borderBottom={false} />

          <div className="container-2 px-24 pb-24 md-w-fix">
            {userData?.profileType === 'Manufacturer' && (
              <div className="text-sm flex gap-8 py-12 border-y-1">
                From:<span className="font-semibold">{overviewData.rentalRequestData.submittedByCompanyName}</span>
              </div>
            )}
            <Tabs
              className="w-full custom-tabs"
              defaultActiveKey="1"
              items={items}
              activeKey={activeTab}
              onChange={setActiveTab}
            />
          </div>
        </>
      )}
    </>
  );
}
export default ViewRecord;
