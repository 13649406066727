import React, { useRef, useState } from 'react';
import { useOutsideClick, MyIcon, IOptionDropdown } from '@scanny-app/loopy-loop';

interface CustomDropdownProps {
  options: IOptionDropdown[];
  defaultOptionIndex?: number;
}

const CustomDropdown = ({ options, defaultOptionIndex = 0 }: CustomDropdownProps) => {
  const defaultOption: IOptionDropdown = options[defaultOptionIndex] || options[0];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOptionDropdown>(defaultOption);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: IOptionDropdown) => () => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const dropdownRef = useRef(null);
  useOutsideClick({
    ref: dropdownRef,
    callback: () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  return (
    <div className="dropdown-container relative" ref={dropdownRef}>
      <div className="flex flex-row gap-2">
        <div
          className={`dropdown-btn cust-dropdown dropdown-name flex gap-12 middle rounded-l-8 ${selectedOption.className}`}
          onClick={selectedOption.onClick}
        >
          <MyIcon icon={selectedOption.icon} />
          {selectedOption.name}
        </div>
        <div onClick={toggling} className={`dropdown-btn  cust-dropdown rounded-r-8 ${selectedOption.className}`}>
          <MyIcon icon={isOpen ? 'IconCollapse' : 'IconExpand'} />
        </div>
      </div>

      {isOpen && (
        <div className="dropdown-list fade">
          {options.map((option, index) => (
            <div
              className={`gap-12 p-12-16 middle divider-dropdown hover-pointer hover-grey ${
                option.name === selectedOption.name ? 'hidden' : 'flex'
              }`}
              onClick={onOptionClicked(option)}
              key={index}
            >
              <div className="icon-wrap">
                <MyIcon icon={option.icon} />
              </div>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface RespondButtonProps {
  handleConfirm: () => void;
}

function RespondButton({ handleConfirm }: RespondButtonProps) {
  return (
    <div className={`dropdown-btn cust-dropdown flex gap-12 middle rounded-8 btn-blue`} onClick={handleConfirm}>
      <MyIcon icon="IconNotePad" />
      Respond
    </div>
  );
}

export { CustomDropdown, RespondButton };
