import { useNavigate } from 'react-router-dom';
import { DefaultCard, EmptyCard, IBooking, CustomButton, MyIcon } from '@scanny-app/loopy-loop';

interface OverviewRentalAgreementProps {
  customerFirstName: string;
  customerLastName: string;
  navigateURL: string;
  booking: IBooking;
  needsRentalAgreement: boolean;
}

function OverviewRentalAgreement({
  customerFirstName,
  customerLastName,
  navigateURL,
  booking,
  needsRentalAgreement,
}: OverviewRentalAgreementProps) {
  const navigate = useNavigate();

  return (
    <div>
      {needsRentalAgreement && booking.vehicleRego ? (
        <CustomButton
          className={'on-hire-lg w-full content-center h-45'}
          onClick={() => navigate(navigateURL)}
          btnName={'Rental Agreement'}
          iconName={'IconPreAuth'}
        />
      ) : booking && !needsRentalAgreement ? (
        <DefaultCard
          className={'content-space-between'}
          content={
            <>
              Completed by {customerFirstName} {customerLastName}
              <MyIcon icon={'IconPreAuth'} />
            </>
          }
        />
      ) : booking?.status === 'Scheduled' && !booking.vehicleRego ? (
        <DefaultCard
          className={'icon-sm'}
          content={
            <>
              <MyIcon icon={'IconPending'} />
              Waiting for Carbiz to assign a vehicle...
            </>
          }
        />
      ) : (
        <EmptyCard />
      )}
    </div>
  );
}

export default OverviewRentalAgreement;
