import { Form, DatePicker, Modal, Row, Col, Input } from 'antd';
import { useState } from 'react';
import { MyIcon, getFormattedTimeZone, disablePastDatesBasedOnTime } from '@scanny-app/loopy-loop';
interface ReturnSubmitInterface {
  returnNotes: string;
  finishDate: string;
}
interface NewReturnProps {
  open: boolean;
  onCancel: () => void;
  onReturnSubmit: (values: ReturnSubmitInterface) => void;
}

const NewReturn: React.FC<NewReturnProps> = ({ open, onCancel, onReturnSubmit }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 200;
  const handleOk = () => {
    form.validateFields().then((values) => {
      setDisableButton(true);
      const formattedValues: ReturnSubmitInterface = {
        returnNotes: values.returnNotes,
        finishDate: `${values.finishDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`,
      };

      onReturnSubmit(formattedValues);
      form.resetFields();
    });
  };
  const modalTitle = (
    <div className="w-full flex flex-row gap-8 middle">
      <MyIcon icon="IconReturn" />
      <h3 className="m-0 text-sm"> Return Car</h3>
    </div>
  );
  return (
    <Modal
      open={open}
      title={modalTitle}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
      className="custom-modal"
      width={400}
      centered
    >
      <Form form={form} disabled={disableButton} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <Form.Item
              name="finishDate"
              label="Finish Date"
              rules={[
                {
                  required: true,
                  message: 'Please select a date',
                },
              ]}
            >
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabledDate={disablePastDatesBasedOnTime}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="returnNotes" label="Return Notes">
              <TextArea
                style={{ minHeight: 100 }}
                maxLength={maxCharLimit}
                onChange={(e) => setCharCount(e.target.value.length)}
              />
            </Form.Item>
            <div className={'flex content-end text-grey text-xs mt-4'}>{`${charCount}/${maxCharLimit}`}</div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewReturn;
