import { useContext } from 'react';
import { IRentalRequest, UserContext, StatusComponent } from '@scanny-app/loopy-loop';
import FormUpdateVOR from './FormUpdateVOR';
import { CardApprovalRequest } from '../Card/CardRequestDetails';

interface ApprovalRequestProps {
  rentalRequestData: IRentalRequest;
  onClickTab: (tabKey: string) => void;
  VORPass: [
    setReloadDataDashboard: (value: boolean) => void,
    setReloadDataRentalRequest: (value: boolean) => void,
    setIsVORLoading: (value: boolean) => void,
  ];
}

function OverviewApproval({ rentalRequestData, onClickTab, VORPass }: ApprovalRequestProps) {
  const userData = useContext(UserContext);
  const isDealer = userData?.profileType === 'Dealer';
  const isManufacturer = userData?.profileType === 'Manufacturer';

  return (
    <>
      {isManufacturer && <CardApprovalRequest rentalRequestData={rentalRequestData} onClickTab={onClickTab} />}
      {isDealer && (
        <div className="flex flex-row w-full gap-12 content-space-between">
          <div className={`${rentalRequestData.status === 'Pending' ? 'w-50' : 'w-full'}`}>
            <StatusComponent status={rentalRequestData.status} />
          </div>

          {rentalRequestData.status === 'Pending' && (
            <div className="w-50 flex flex-row gap-12">
              <span className="font-semibold text-xs h-45 flex middle">Or</span>
              <FormUpdateVOR rentalRequestData={rentalRequestData} VORPass={VORPass} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default OverviewApproval;
