import React from 'react';
import { UserContext } from '@scanny-app/loopy-loop';
import { useContext } from 'react';
interface SequentialCardProps {
  onlyVisible?: 'Manufacturer' | 'Dealer';
  title: string;
  content: React.ReactNode;
}

function SequentialCard({ onlyVisible, title, content }: SequentialCardProps) {
  const userData = useContext(UserContext);
  let isVisible = true;
  if (onlyVisible === 'Manufacturer') {
    isVisible = onlyVisible === userData?.profileType;
  } else if (onlyVisible === 'Dealer') {
    isVisible = onlyVisible === userData?.profileType;
  }

  return (
    <>
      {isVisible && (
        <div className="flex gap-12 flex-column">
          <h5 className="m-0 text-sm">{title}</h5>
          <div>{content}</div>
        </div>
      )}
    </>
  );
}

export default SequentialCard;
