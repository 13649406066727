import { IRentalRequest } from '@scanny-app/loopy-loop';
const readableDate = (isoStr: string) => {
  if (!isoStr) return '';
  const date = new Date(isoStr);
  const dateOnlyPattern = /^\d{4}-\d{2}-\d{2}$/;
  if (dateOnlyPattern.test(isoStr)) {
    const dateParts = isoStr.split('-');
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Convert to DD/MM/YYYY format
  }
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  })
    .format(date)
    .replace(',', '');
  return formattedDate;
};
const convertRentalRequestToExcel = (data: IRentalRequest[]) => {
  return data.map((item) => {
    return {
      'Hire Name': item.hireName,
      'Customer Name': item.customerFirstName + ' ' + item.customerLastName,
      'Customer Contact Number': item.customerContactNumber,
      'Customer Email': item.customerEmail,
      'Vehicle Rego': item.vehicleRego,
      'Vehicle VIN': item.vehicleVIN,
      Make: item.makeName,
      Model: item.modelName,
      Comments: item.comments,
      Status: item.status,
      'Status Comments': item.statusComments,
      'Booking Status': item.bookingStatus,
      'Booking Start': readableDate(item.bookingStart),
      'Booking Comments': item.bookingComments,
      'Created Date': readableDate(item.createdDate),
      Dealership: item.submittedByCompanyName,
      'Dealer Code': item.submittedByCompanyDealerCode,
      'Hire Start Date': readableDate(item.hireStartDate),
      'Hire Estimated Finish Date': readableDate(item.hireEstimatedFinishDate),
      'Hire Finish Date': readableDate(item.hireFinishDate),
      'Hire Number Of Days': item.hireNumberOfDays,
      'Hire Invoice Amount': item.hireInvoiceAmount,
      'Hire Invoice Number': item.hireInvoiceNumber,
      'Hire Invoice Status': item.hireInvoiceStatus,
      'Hire Invoice Created Date': readableDate(item.hireInvoiceCreatedDate),
      'Hire Vehicle': item.hireVehicle,
      'Hire Vehicle Rego': item.hireVehicleRego,
      'Hire Vehicle Make': item.hireVehicleMake,
      'Hire Vehicle Model': item.hireVehicleModel,
      'Hire Vehicle Make Name': item.hireVehicleMakeName,
      'Hire Vehicle Model Name': item.hireVehicleModelName,
      'VOR Number': item.VORNumber,
      'Finish Date': readableDate(item.finishDate),
    };
  });
};
export { convertRentalRequestToExcel };
