import React, { useState, useEffect, useRef } from 'react';

interface AutoResizeTextProps {
  text: string | number;
  width: number;
  customClass: string;
  defaultFontSize: number;
}
function AutoResizeText({ text, width, customClass, defaultFontSize }: AutoResizeTextProps) {
  const [fontSize, setFontSize] = useState(defaultFontSize); // Initial font size
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const containerWidth = textRef.current.offsetWidth;
      const textWidth = textRef.current.scrollWidth;
      const scaleFactor = containerWidth / textWidth;

      if (scaleFactor < 1) {
        setFontSize(fontSize * scaleFactor);
      }
    }
  }, [text, width]);

  return (
    <div className="art-container" ref={textRef} style={{ width: `${width}px`, overflow: 'hidden', whiteSpace: 'nowrap' }}>
      <div className={`${customClass}`} style={{ fontSize: `${fontSize}px` }}>
        {text}
      </div>
    </div>
  );
}

export { AutoResizeText };
