import { MyIcon, IconName } from '@scanny-app/loopy-loop';

interface PrimaryNavigationProps {
  toggleSidebar: (() => void) | undefined;
  sidebarVisible: boolean | undefined;
  menuTitle: string;
  menuIcon?: IconName;
  rightNode?: React.ReactNode;
  classContainer?: string;
}

function PrimaryNavigation({
  toggleSidebar,
  sidebarVisible,
  menuTitle,
  menuIcon,
  rightNode,
  classContainer,
}: PrimaryNavigationProps) {
  return (
    <div className={`${classContainer ? classContainer : 'container-1'} px-24 md-w-fix`}>
      <div id="top-navigation" className="border-b">
        <div className="left-navigation">
          <button className="custom-btn-outline text-black" onClick={toggleSidebar}>
            <MyIcon icon={`${sidebarVisible ? 'IconBack' : 'IconMenu'}`} />
          </button>
        </div>
        <div className={`flex flex-row middle gap-8 ${menuIcon ? 'md-icon-show' : 'md-icon-none'}`}>
          <MyIcon icon={menuIcon || ''} /> <h1>{menuTitle}</h1>
        </div>
        <div className="flex flex-row middle gap-16">{rightNode}</div>
      </div>
    </div>
  );
}

export default PrimaryNavigation;
