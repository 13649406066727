import { Col, DatePicker, Form, Input, Modal, Row, message } from 'antd';
import { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { MyIcon, IRentalRequest, LargeLoading, UserContext, disablePastDatesBasedOnTime } from '@scanny-app/loopy-loop';

interface RequestExtensionProps {
  open: boolean;
  onCancel: () => void;
  rentalRequestData: IRentalRequest;
  setReloadDataRentalRequest: (value: boolean) => void;
  setReloadDataDashboard: (value: boolean) => void;
  setViewModalVisible: (value: boolean) => void;
}

function CreateRequestExtension({
  open,
  onCancel,
  setReloadDataRentalRequest,
  setReloadDataDashboard,
  setViewModalVisible,
  rentalRequestData,
}: RequestExtensionProps) {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useContext(UserContext);

  const modalTitle = (
    <div className="w-full flex flex-row gap-8 middle">
      <MyIcon icon="IconWarning" />
      <h3 className="m-0 text-sm">Request an Extension</h3>
    </div>
  );

  const createRequestExtension = async (values: { comments: string; partsETA: string }) => {
    setIsLoading(true);

    try {
      const data = {
        ...values,
        submittedByName: `${userData?.firstName} ${userData?.lastName}`,
        submittedByEmail: userData?.email,
        hire: rentalRequestData.hire,
      };

      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();
      await axios
        .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/HireExtension`, JSON.stringify(data), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          message.success('Rental request updated successfully');
          setReloadDataRentalRequest(true);
          setReloadDataDashboard(true);

          setViewModalVisible(false);
          setIsLoading(false);
        });
    } catch (error) {
      message.error(`Error updating rental request ${error}`);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        const formatValues = {
          comments: values.comments,
          partsETA: `${values.partsETA.format('YYYY-MM-DD')}`,
        };

        createRequestExtension(formatValues);
        form.resetFields();
      })
      .catch(() => {
        message.error('Please review field errors');
      });
  };

  return (
    <Modal
      open={open}
      title={modalTitle}
      okText="Request"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      className="custom-modal left-menu"
      width={400}
      centered
    >
      {isLoading && <LargeLoading customText="Just a moment, we're creating the request extension..." />}
      <Form form={form} disabled={false} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <Form.Item
              name="partsETA"
              label="ETA of parts"
              rules={[
                {
                  required: true,
                  message: 'Please select a date',
                },
              ]}
            >
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabledDate={disablePastDatesBasedOnTime}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="comments"
              label="Comments"
              rules={[
                {
                  required: true,
                  message: 'Please enter request details',
                },
              ]}
            >
              <TextArea style={{ minHeight: 100 }} />
            </Form.Item>
            <div className={'text-xs mb-8 italic text-grey pl-12 my-16 border-l-grey'}>
              Example: We require the rental extension due to the parts arrival ETA now being extended by a further 4 weeks by
              Mobis, this is out of the dealers control.
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateRequestExtension;
