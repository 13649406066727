import { ConfigProvider } from 'antd';
import { useLocation, Navigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useState, useEffect } from 'react';
import AppRoutes from './config/AppRoutes';
import axios from 'axios';
import {
  SomethingWentWrong,
  LargeLoading,
  IAllBooking,
  IAllStatus,
  IAllStatusResponse,
  IBookingResponse,
  IDashboard,
  EmptyDashboard,
  ISelectOption,
  GetOptionsFromDB,
  DealershipContext,
  IUser,
  UserContext,
  EmptyUser,
  AppConfigContext,
  IAppConfig,
  CompanyNotFound,
  HeadScript,
} from '@scanny-app/loopy-loop';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useIdleTimer } from 'react-idle-timer';
import SideMenuList from './config/SideMenuList';
import './App.css';
import { clarityScript } from './config/Scripts';

Amplify.configure(awsconfig);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function App() {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [isLoading, setIsLoading] = useState(true);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [dashboardData, setDashboardData] = useState<IDashboard>(EmptyDashboard);
  const [reloadDataDashboard, setReloadDataDashboard] = useState(false);
  const [dealershipOptions, setDealershipOptions] = useState<ISelectOption[]>([]);
  const [listOfDealership, setListOfDealership] = useState<string[]>();
  const storeDealership = localStorage.getItem('dealership');
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';
  const isAuthenticated = Object.keys(userData).length > 0 && userData.constructor === Object;

  const [appConfig, setAppConfig] = useState<IAppConfig>(() => ({
    logoImagePath: '/assets/logo-kia.svg',
    SideMenuList: SideMenuList({ collectDataDashboard: dashboardData, userData: userData }),
    showSideBarText: false,
    sideBarBackgroundColour: '#343434',
    onLogoutAction: () => localStorage.removeItem('dealership'),
  }));

  const setReloadDealership = (value: boolean) => {
    setReloadDataDashboard(value);
  };

  const onIdle = () => {
    if (isAuthenticated) {
      Auth.signOut().then(() => {
        if (appConfig.onLogoutAction) appConfig.onLogoutAction();
        window.location.reload();
      });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 7200000,
  });

  useEffect(() => {
    document.body.style.setProperty('--global-color', '#ffce2f');

    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession().then((currentSessionResponse) => {
          const token = currentSessionResponse.getAccessToken().getJwtToken();
          axios
            .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/getUserCompany`, JSON.stringify({}), {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response.data.length === 0) {
                setUserData(() => EmptyUser);
                setIsLoading(false);
              } else {
                setUserData(() => ({
                  USER_TYPE: 'External',
                  firstName: currentSessionResponse.getIdToken().payload.given_name,
                  lastName: currentSessionResponse.getIdToken().payload.family_name,
                  email: currentSessionResponse.getIdToken().payload.email,
                  profileType: response.data[0].portalAccountType,
                  portalServices: response.data[0].portalServices.split(';'),
                  currentCompany: response.data[0].id,
                  companyName: response.data[0].name,
                }));
                setIsLoading(false);
              }
            })
            .catch(() => {
              setUserData(() => EmptyUser);
              setSomethingWentWrong(true);
              setIsLoading(false);
            });
        });
      })
      .catch(() => {
        setIsLoading(false);
        setSomethingWentWrong(false);
      });
  }, []);

  useEffect(() => {
    const fetchDealership = async () => {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Dealerships`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      const listOfDealershipValues = optionsReturned.map((option) => option.value);
      setDealershipOptions(sortedOptions);
      setListOfDealership(listOfDealershipValues);
    };
    fetchDealership();
  }, []);

  useEffect(() => {
    setDashboardData(EmptyDashboard);
    const parseDealership = storeDealership && JSON.parse(storeDealership);
    const selectedDealerships = storeDealership ? parseDealership : listOfDealership;

    const fetchData = async () => {
      try {
        const data = await Auth.currentSession();
        const token = data.getAccessToken().getJwtToken();
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_URL}/v1/dashboard`,
          JSON.stringify({ dealerships: selectedDealerships }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response) {
          const allStatusCountUpdate: IAllStatus = { Rejected: 0, Pending: 0, Approved: 0 };
          const allBookingCountUpdate: IAllBooking = { 'In Progress': 0, 'No booking': 0, Scheduled: 0, Completed: 0 };

          response.data.compositeResponse[0].body.records.forEach((element: IAllStatusResponse) => {
            if (element.Status__c in allStatusCountUpdate) {
              allStatusCountUpdate[element.Status__c as keyof IAllStatus] = element.expr0;
            }
          });

          response.data.compositeResponse[3].body.records.forEach((element: IBookingResponse) => {
            if (element.Booking_Status__c in allBookingCountUpdate) {
              allBookingCountUpdate[element.Booking_Status__c as keyof IAllBooking] += 1;
            }
          });

          let hireDayTotal = 0;
          let hireDaysInvoiced = 0;
          let currentHireDays = 0;
          response.data.compositeResponse[3].body.records.forEach((element: IBookingResponse) => {
            if (element?.Hire__r?.Days_on_Hire__c) {
              hireDayTotal += element?.Hire__r?.Days_on_Hire__c;
              if (element.Booking_Status__c === 'In Progress') {
                currentHireDays += element?.Hire__r?.Days_on_Hire__c;
              }
              if (element.Booking_Status__c === 'Completed') {
                hireDaysInvoiced += element?.Hire__r?.Days_on_Hire__c;
              }
            }
          });

          setDashboardData({
            numberReachingDayLimit: response.data.compositeResponse[2].body.totalSize,
            totalRentalRequests: allStatusCountUpdate.Rejected + allStatusCountUpdate.Approved + allStatusCountUpdate.Pending,
            allStatusCount: allStatusCountUpdate,
            allBookingCount: allBookingCountUpdate,
            numberOutstandingTotal: response.data.compositeResponse[1].body.records[0].expr0,
            invoicesReceived: response.data.compositeResponse[1].body.records[0].expr1,
            totalDaysOnHire: hireDayTotal,
            hireDaysInvoiced: hireDaysInvoiced,
            currentHireDays: currentHireDays,
            collectionDataLoading: false,
          });
        }
      } catch (error) {
        setDashboardData((prev) => ({ ...prev, collectionDataLoading: false }));
      }
    };

    fetchData();
    setReloadDataDashboard(false);
  }, [reloadDataDashboard]);

  useEffect(() => {
    setAppConfig((prevConfig) => ({
      ...prevConfig,
      SideMenuList: SideMenuList({ collectDataDashboard: dashboardData, userData: userData }),
    }));
  }, [dashboardData, userData]);

  if (isLoading) return <LargeLoading />;
  if (!isLoading && somethingWentWrong) return <SomethingWentWrong />;
  if (!isLoading && !isAuthenticated && !isSignInPage) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && isSignInPage) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && !isSignInPage && userData.currentCompany === 'NOT_FOUND') {
    return <CompanyNotFound />;
  }

  const theme = {
    colorPrimary: '#3CCEB3',
    fontFamily: 'Inter, sans-serif',
  };

  return (
    <div className="App">
      <HeadScript scripts={[clarityScript]} />
      <ConfigProvider
        theme={{
          token: theme,
        }}
      >
        <Elements stripe={stripePromise}>
          <AppConfigContext.Provider value={appConfig}>
            <UserContext.Provider value={userData}>
              <DealershipContext.Provider value={{ dealershipOptions, setReloadDealership }}>
                <AppRoutes collectDataDashboard={dashboardData} setReloadDataDashboard={setReloadDataDashboard}></AppRoutes>
              </DealershipContext.Provider>
            </UserContext.Provider>
          </AppConfigContext.Provider>
        </Elements>
      </ConfigProvider>
    </div>
  );
}

export default App;
