import CreateRequestExtension from '../Modals/CreateRequestExtension';
import { useState, useContext } from 'react';
import CollapsibleCard from '../Card/ApprovalElement/CollapsibleCard';
import { CustomButton, IRequestExtension, LargeLoading, IComment, UserContext } from '@scanny-app/loopy-loop';
import { NearDayLimit } from '../../helpers/NearDayLimit';
import { OverviewData } from '../../pages/SinglePages/ViewRecord';
import ApprovalType from '../Card/ApprovalElement/ApprovalType';
import { RequestDetails } from '../Card/CardRequestDetails';
interface ViewDetailsProps {
  overviewData: OverviewData;
  commentData: IComment[];
  setReloadDataComment: (value: boolean) => void;
}

function ViewApprovals({
  overviewData: { rentalRequestData, requestExtensionData, setReloadDataRentalRequest, setReloadDataDashboard },
  commentData,
  setReloadDataComment,
}: ViewDetailsProps) {
  const userData = useContext(UserContext);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const isNearDayLimit = NearDayLimit(rentalRequestData, 'isNearDayLimit');
  const [isVORLoading, setIsVORLoading] = useState(false);
  const [isHotlineLoading, setIsHotlineLoading] = useState(false);

  const initialCardStates = requestExtensionData.reduce(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, item: IRequestExtension, index, array) => {
      acc[`extensionRequest-${item.id}`] = index === array.length - 1;
      return acc;
    },
    { initialApproval: requestExtensionData.length === 0 },
  );

  if (requestExtensionData.length > 0) {
    const lastItemId = requestExtensionData[requestExtensionData.length - 1].id;
    initialCardStates[`extensionRequest-${lastItemId}`] = true;
  }
  const [cardStates, setCardStates] = useState(initialCardStates);

  const handleModalVisible = (value: boolean) => {
    setViewModalVisible(value);
  };

  const handleExpanded = (key: string) => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCardStates((prevStates: any) => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  const isLastRequestApproved = () => {
    if (requestExtensionData.length === 0) return true;
    const lastRequest = requestExtensionData[requestExtensionData.length - 1];
    return lastRequest.status === 'Approved';
  };

  const reversedRequestExtensionData = [...requestExtensionData].reverse();

  return (
    <>
      {isVORLoading && <LargeLoading customText="Just a moment, we're updating the VOR Number...." />}
      {isHotlineLoading && <LargeLoading customText="Just a moment, we're updating the Hotline number...." />}

      <div className="flex flex-column gap-24">
        <CreateRequestExtension
          onCancel={() => handleModalVisible(false)}
          open={viewModalVisible}
          rentalRequestData={rentalRequestData}
          setReloadDataRentalRequest={setReloadDataRentalRequest}
          setReloadDataDashboard={setReloadDataDashboard}
          setViewModalVisible={setViewModalVisible}
        />
        {userData?.profileType === 'Dealer' && isLastRequestApproved() && isNearDayLimit && (
          <CustomButton
            className={'on-hire-lg w-full content-center h-45'}
            onClick={() => handleModalVisible(true)}
            btnName={'Request an Extension'}
            iconName={'IconAdd'}
          />
        )}

        {reversedRequestExtensionData.map((item: IRequestExtension) => {
          const extensionKey = `extensionRequest-${item.id}`;
          return (
            <CollapsibleCard
              key={item.id}
              title={'Extension Request'}
              expanded={cardStates[extensionKey]}
              onCollapsible={() => handleExpanded(extensionKey)}
              status={item.status}
              content={
                <ApprovalType
                  type={'Extension Request'}
                  requestExtensionData={item}
                  rentalRequestData={rentalRequestData}
                  commentData={commentData}
                  setReloadDataRentalRequest={setReloadDataRentalRequest}
                  setReloadDataDashboard={setReloadDataDashboard}
                  setReloadDataComment={setReloadDataComment}
                />
              }
            />
          );
        })}

        <CollapsibleCard
          title={'Initial Approval'}
          expanded={cardStates['initialApproval']}
          onCollapsible={() => handleExpanded('initialApproval')}
          status={rentalRequestData.status}
          content={
            <ApprovalType
              type={'Initial Approval'}
              rentalRequestData={rentalRequestData}
              commentData={commentData}
              setReloadDataRentalRequest={setReloadDataRentalRequest}
              setReloadDataDashboard={setReloadDataDashboard}
              setReloadDataComment={setReloadDataComment}
            />
          }
        />

        <RequestDetails
          rentalRequestData={rentalRequestData}
          VORPass={[setReloadDataDashboard, setReloadDataRentalRequest, setIsVORLoading]}
          HotlinePass={[setReloadDataRentalRequest, setIsHotlineLoading]}
        />
      </div>
    </>
  );
}
export default ViewApprovals;
