import { Form, DatePicker, Modal, Row, Col, Input, Select, message } from 'antd';
import { useState } from 'react';
import {
  MyIcon,
  disablePastDatesBasedOnTime,
  disablePastDatesBasedOnTimeAndWeekend,
  filterOptions,
  GetOptionsFromDB,
  ISelectOption,
  getFormattedTimeZone,
} from '@scanny-app/loopy-loop';
interface BookingSubmitInterface {
  bookingComments: string;
  bookingStart: string;
  estimatedFinishDate: string | null;
  embeddedVehicleId: string;
}
interface NewBookingProps {
  open: boolean;
  onCancel: () => void;
  onBookingSubmit: (values: BookingSubmitInterface) => void;
}

const NewBooking: React.FC<NewBookingProps> = ({ open, onCancel, onBookingSubmit }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(false);
  const { Option } = Select;
  const [bookingType, setBookingType] = useState<string>('');
  const [vehicleOptions, setVehicleOptions] = useState<ISelectOption[]>([]);
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 200;

  let estimatedFinishDateString: string | null = null;
  if (open) {
    if (form.getFieldsValue().estimatedFinishDate) {
      estimatedFinishDateString = form.getFieldsValue().estimatedFinishDate.format('YYYY-MM-DD');
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setDisableButton(true);
        const formattedValues = {
          bookingComments: values.comments,
          bookingStart: `${values.startDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`,
          estimatedFinishDate: estimatedFinishDateString,
          embeddedVehicleId: values.selectVehicle,
        };

        onBookingSubmit(formattedValues);
        form.resetFields();
      })
      .catch(() => {
        message.error('Please review field errors');
        setDisableButton(false);
      });
  };

  const getVehicle = async () => {
    if (vehicleOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/EmbeddedCars`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => `${item.makeName} ${item.modelName} - ${item.name}`,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setVehicleOptions(sortedOptions);
    }
  };

  const handleBookingTypeChange = (value: string) => {
    setBookingType(value);
    form.setFieldsValue({ startDate: '', selectVehicle: '' });
  };

  const modalTitle = (
    <div className="w-full flex flex-row gap-8 middle">
      <MyIcon icon="IconStars" />
      <h3 className="m-0 text-sm">Create Booking</h3>
    </div>
  );
  return (
    <Modal
      open={open}
      title={modalTitle}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
      className="custom-modal left-menu"
      width={400}
      centered
    >
      <Form form={form} disabled={disableButton} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <Form.Item
              name="bookingType"
              label="Booking Type"
              rules={[{ required: true, message: 'Please select the booking type' }]}
            >
              <Select onChange={handleBookingTypeChange}>
                <Option value="Vehicle On Site">Vehicle On Site</Option>
                <Option value="Vehicle Delivered">Vehicle Delivered</Option>
              </Select>
            </Form.Item>
          </Col>

          {bookingType === 'Vehicle On Site' && (
            <Col span={24}>
              <Form.Item
                name="selectVehicle"
                label="Select Vehicle"
                rules={[{ required: true, message: 'Please select the vehicle' }]}
              >
                <Select filterOption={filterOptions} showSearch onFocus={getVehicle} onClick={getVehicle}>
                  {vehicleOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    vehicleOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                {
                  required: true,
                  message: 'Please select a date',
                },
              ]}
            >
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabled={bookingType == ''}
                disabledDate={
                  bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="estimatedFinishDate" label="Estimated Finish Date">
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabledDate={
                  bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="comments" label="Comments">
              <TextArea
                style={{ minHeight: 100 }}
                maxLength={maxCharLimit}
                onChange={(e) => setCharCount(e.target.value.length)}
              />
            </Form.Item>
            <div className={'flex content-end text-grey text-xs mt-4'}>{`${charCount}/${maxCharLimit}`}</div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewBooking;
