import { Image, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '@scanny-app/loopy-loop';
import { AutoResizeText } from '@scanny-app/loopy-loop';

interface DashboardComponentProps {
  totalSize: number;
  title: string;
  viewAllRedirect: string;
  imageURL?: string;
  isLoading: boolean;
  isMoney?: boolean;
  smallVersion?: boolean;
  addViewAll?: boolean;
}

function Total({
  totalSize,
  title,
  viewAllRedirect,
  imageURL,
  isLoading,
  isMoney = false,
  smallVersion = false,
  addViewAll = false,
}: DashboardComponentProps) {
  const navigate = useNavigate();
  const outstandingTotal = (Math.round(totalSize * 100) / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <>
      <div
        className={`flex w-full md-w-full flex-column border-t-grey content-center ${smallVersion ? 'min-h-72' : 'min-h-104'}`}
      >
        {isLoading ? (
          <Skeleton paragraph={{ rows: 1 }} active className={'skeleton-dashboard p-12-0'} />
        ) : (
          <>
            <div className="flex flex-column p-12-0">
              <div className="flex content-space-between">
                <p className="info-desc m-0 w-full">{title} </p>

                {addViewAll && (
                  <div
                    className="hover-pointer"
                    onClick={() => {
                      navigate(viewAllRedirect);
                    }}
                  >
                    <MyIcon icon="IconForward" fill="none" />
                  </div>
                )}
              </div>

              {smallVersion ? (
                <p className={`info-detail flex`} style={{ fontSize: '18px' }}>
                  {isMoney && '$'}
                  {isMoney ? outstandingTotal : totalSize}
                </p>
              ) : (
                <AutoResizeText
                  customClass={`${isMoney ? 'total-size' : 'is-money'}  info-detail flex`}
                  text={isMoney ? `$${outstandingTotal}` : totalSize}
                  width={264}
                  defaultFontSize={46}
                />
              )}
            </div>
            {imageURL && (
              <div className="w-40 middle content-center flex">
                <Image className="" preview={false} src={imageURL} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default Total;
