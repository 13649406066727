import { useNavigate } from 'react-router-dom';
import { MyIcon, UserContext, IBooking, formatISO, BookingStatusComponent, PreAuthStatusComponent } from '@scanny-app/loopy-loop';
import { useContext } from 'react';
interface BookingCardComponentProps {
  navigateToRentalAgreement: string;
  bookingData: IBooking;
}

function BookingCardComponent({ navigateToRentalAgreement, bookingData }: BookingCardComponentProps) {
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const isoBookingStart = formatISO(bookingData.bookingStart).split(',');
  const newBookingStart = isoBookingStart[0].trim();
  const isProfileTypeManufacturer = userData?.profileType === 'Manufacturer';

  return (
    <div className="flex flex-column box-1 mb-16 overflow-hidden" key={bookingData.id}>
      <div className="p-16 content-space-between flex middle border-b bg-grey-1">
        <div className="text-sm font-semibold w-60">{bookingData.name}</div>
        <div className="flex flex-row middle gap-8 w-40 content-end">
          <BookingStatusComponent status={bookingData.status} />
        </div>
      </div>

      <div className="p-16 flex flex-column gap-16">
        <div className="grid grid-cols-3 md-grid-cols-2 text-xs gap-16">
          <div className="flex flex-column gap-8 ">
            <div>Booking Start </div>
            <div className="font-semibold">{newBookingStart ? newBookingStart : '-'}</div>
          </div>
          <div className="flex flex-column gap-8">
            <div>Booking Finish Date</div>
            <div className="font-semibold">
              {bookingData.bookingFinish ? new Date(bookingData.bookingFinish).toLocaleDateString('en-AU') : '-'}
            </div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Rental Agreement</div>
            {bookingData.rentalAgreement !== null ? (
              <PreAuthStatusComponent status="Completed" />
            ) : !isProfileTypeManufacturer && bookingData.rentalAgreement === null && bookingData.vehicleRego ? (
              <button
                onClick={() => navigate(navigateToRentalAgreement)}
                className="font-semibold btn-text-blue flex middle gap-8"
              >
                <MyIcon icon={'IconPreAuth'} />
                Rental Agreement
              </button>
            ) : bookingData.status === 'Scheduled' && !bookingData.vehicleRego ? (
              <div className="gap-8 middle font-semibold text-xs flex icon-sm">
                <MyIcon icon={'IconPending'} />
                Waiting...
              </div>
            ) : (
              '-'
            )}
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Car Make</div>
            <div className="font-semibold">{bookingData.vehicleMake !== undefined ? bookingData.vehicleMake : '-'}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Car Model</div>
            <div className="font-semibold">{bookingData.vehicleModel !== undefined ? bookingData.vehicleModel : '-'}</div>
          </div>
          <div className="flex flex-column gap-8 ">
            <div>Rego </div>
            <div className="font-semibold">{bookingData.vehicleRego !== undefined ? bookingData.vehicleRego : '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingCardComponent;
