import { Form, Input, Select, message, Row, Col, DatePicker } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useContext } from 'react';
import ApprovalVOR from '../../components/Modals/ApprovalVOR';
import {
  GetOptionsFromDB,
  ISelectOption,
  disablePastDatesBasedOnTime,
  disablePastDatesBasedOnTimeAndWeekend,
  filterOptions,
  getFormattedTimeZone,
  LargeLoading,
  SecondaryNavigation,
  capitalizeWords,
  UserContext,
} from '@scanny-app/loopy-loop';
interface CreateRentalRequestProps {
  setReloadDataDashboard: (value: boolean) => void;
}

function CreateRentalRequest({ setReloadDataDashboard }: CreateRentalRequestProps) {
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [submissionLoadingBooking, setSubmissionLoadingBooking] = useState(false);
  const [requestType, setRequestType] = useState<string>('');
  const [bookingType, setBookingType] = useState<string>('');
  const [makeOptions, setMakeOptions] = useState<ISelectOption[]>([]);
  const [modelOptions, setModelOptions] = useState<ISelectOption[]>([]);
  const [vehicleOptions, setVehicleOptions] = useState<ISelectOption[]>([]);
  const userData = useContext(UserContext);
  const MAKE_DEFAULT: ISelectOption = {
    label: process.env.REACT_APP_MANUFACTURER_MAKE_NAME || '',
    value: process.env.REACT_APP_MANUFACTURER_MAKE_ID || '',
  };
  const hasMakeDefault = MAKE_DEFAULT.value !== '' && MAKE_DEFAULT.label !== '';
  const [vehicleModel, setVehicleModel] = useState<ISelectOption>({} as ISelectOption);
  const [modelDisabled, setModelDisabled] = useState(!hasMakeDefault);
  const [vehicleMake, setVehicleMake] = useState<ISelectOption>(MAKE_DEFAULT);
  const [viewApprovalVORModalVisible, setViewApprovalVORModalVisible] = useState(false);
  const isNonVOR = requestType === 'Not VOR Related';

  const getModels = async () => {
    if (vehicleMake.value !== '') {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Model`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: { make: vehicleMake.value },
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setModelOptions(sortedOptions);
    }
  };
  const getMakes = async () => {
    if (makeOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Make`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setMakeOptions(sortedOptions);
    }
  };

  const getVehicle = async () => {
    if (vehicleOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/EmbeddedCars`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => `${item.makeName} ${item.modelName} - ${item.name}`,
      );

      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setVehicleOptions(sortedOptions);
    }
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMakeChange = (value: string, option: any) => {
    setModelDisabled(false);
    setModelOptions([]);
    setVehicleMake({
      ...vehicleMake,
      label: option.children,
      value: option.value,
    });
    setVehicleModel({} as ISelectOption);
    form.setFieldsValue({ modelId: undefined });
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModelChange = (value: string, option: any) => {
    setVehicleModel({
      ...vehicleModel,
      label: option.children,
      value: option.value,
    });
  };
  const handleTypeChange = (value: string) => {
    setRequestType(value);
    if (value !== 'I have a VOR Number') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        VORNumber: '',
        bookingStartDate: '',
        estimatedFinishDate: '',
        bookingType: '',
      });
    }
  };

  const handleBookingTypeChange = (value: string) => {
    setBookingType(value);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      bookingStartDate: '',
      selectVehicle: '',
    });
  };

  const handleSubmit = async () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      makeId: vehicleMake.value,
      modelId: vehicleModel.value,
    });
    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      if (hasMakeDefault) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          makeId: null,
        });
      }
      return;
    }

    setComponentDisabled(true);

    if (form.getFieldsValue().requestType === 'I have a VOR Number') {
      setSubmissionLoadingBooking(true);
    } else {
      setSubmissionLoading(true);
    }

    let bookingStartString = null;
    if (form.getFieldsValue().bookingStartDate) {
      bookingStartString = `${form
        .getFieldsValue()
        .bookingStartDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`;
    }

    let estimatedFinishDateString = null;
    if (form.getFieldsValue().estimatedFinishDate) {
      estimatedFinishDateString = form.getFieldsValue().estimatedFinishDate.format('YYYY-MM-DD');
    }

    const sessionData = await Auth.currentSession();
    const token = sessionData.getAccessToken().getJwtToken();

    const formValues = form.getFieldsValue();
    const formData = {
      ...formValues,
      customerFirstName: capitalizeWords(formValues.customerFirstName),
      customerLastName: capitalizeWords(formValues.customerLastName),
      typeId: process.env.REACT_APP_RECORD_TYPE_ID,
      submittedByCompany: userData?.currentCompany,
      submittedToCompany: process.env.REACT_APP_COMPANY_ID,
      bookingStart: bookingStartString,
      estimatedFinishDate: estimatedFinishDateString,
      status: formValues.requestType === 'I have a VOR Number' ? 'Approved' : 'Pending',
      embeddedVehicleId: formValues.selectVehicle,
      submittedByName: `${userData?.firstName} ${userData?.lastName}`,
      submittedByEmail: userData?.email,

      VORNumber: formValues.VORNumber && formValues.VORNumber.toUpperCase(),
      vehicleVIN: formValues.vehicleVIN && formValues.vehicleVIN.toUpperCase(),
      vehicleRego: formValues.vehicleRego && formValues.vehicleRego.toUpperCase(),
      repairOrderNumber: formValues.repairOrderNumber && formValues.repairOrderNumber.toUpperCase(),
      purchaseOrderNumber: formValues.purchaseOrderNumber && formValues.purchaseOrderNumber.toUpperCase(),
    };
    if (bookingStartString) {
      formData.bookingComments = form.getFieldsValue().comments;
    }
    delete formData.bookingType;
    delete formData.selectVehicle;
    delete formData.makeName;
    delete formData.modelName;
    delete formData.bookingStartTime;
    delete formData.bookingStartDate;

    axios
      .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/RentalRequest`, JSON.stringify(formData), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        message.success('Rental request created successfully');
        setSubmissionLoading(false);
        setSubmissionLoadingBooking(false);
        setReloadDataDashboard(true);
        setTimeout(() => {
          navigate('/rentals/rental-requests');
        }, 500);
      })
      .catch(() => {
        message.error('Error creating rental request');
        setSubmissionLoading(false);
        setSubmissionLoadingBooking(false);
        setComponentDisabled(false);
      });
  };

  const handleCheckVOR = async () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      makeId: vehicleMake.value,
      modelId: vehicleModel.value,
    });
    try {
      await form.validateFields();
    } catch (errorInfo) {
      if (hasMakeDefault) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          makeId: null,
        });
      }
      message.error('Please review field errors');
      return;
    }

    setViewApprovalVORModalVisible(true);
  };

  const rightNode = (
    <>
      <button onClick={isNonVOR ? handleCheckVOR : handleSubmit} disabled={componentDisabled} className="btn-submit align-middle">
        Submit
      </button>
    </>
  );

  const handleModalCancel = () => {
    setViewApprovalVORModalVisible(false);
  };

  const onBack = () => navigate('/rentals/rental-requests');

  return (
    <>
      {submissionLoadingBooking && <LargeLoading customText="One moment, we are making the booking..." />}
      {submissionLoading && <LargeLoading />}

      <SecondaryNavigation menuTitle={'New Rental Request'} rightNode={rightNode} onBack={onBack} />
      <div className="container-2 p-24 md-w-fix">
        <Form className="w-full form-style-one" layout="vertical" form={form} disabled={componentDisabled}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h5 className="m-0 text-sm">Customer Details</h5>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerFirstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer first name',
                  },
                ]}
              >
                <Input className="capitalize" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerLastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer last name',
                  },
                ]}
              >
                <Input className="capitalize" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerContactNumber"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer contact number',
                  },
                  {
                    pattern: /^\(?(?:\+?61|0)4\)?[ -]?[0-9]{7}[0-9]$/,
                    message: 'Please enter a valid phone number',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerEmail"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid customer email',
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="vehicleRego"
                label="Car Registration"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the car registration',
                  },
                ]}
              >
                <Input className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="vehicleVIN"
                label="VIN"
                rules={[
                  { required: true, message: 'Please enter the VIN' },
                  {
                    pattern: /^[\w\d]{17}$/,
                    message: 'Please verify VIN is 17 characters without spaces or special characters',
                  },
                ]}
              >
                <Input maxLength={17} placeholder="KNAJP1A59C1T98076" className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="makeId"
                label="Car Make"
                rules={[{ required: true, message: 'Please enter the car make' }]}
                style={{ position: 'relative' }}
              >
                <Select
                  disabled={hasMakeDefault}
                  showSearch
                  filterOption={filterOptions}
                  onFocus={getMakes}
                  onClick={getMakes}
                  onChange={handleMakeChange}
                >
                  {makeOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    makeOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '20px',
                  bottom: '10px',
                  pointerEvents: 'none', // to make sure clicks go to the select
                }}
              >
                {MAKE_DEFAULT.label}
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="modelId" label="Car Model" rules={[{ required: true, message: 'Please enter the car model' }]}>
                <Select
                  showSearch
                  filterOption={filterOptions}
                  onClick={getModels}
                  onFocus={getModels}
                  onChange={handleModelChange}
                  disabled={modelDisabled}
                >
                  {modelOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    modelOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <h5 className="mt-8 mb-0 text-sm">Booking</h5>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="repairOrderNumber" label="Repair Order Number">
                <Input className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="purchaseOrderNumber"
                label="Purchase Order Number"
                rules={[{ required: true, message: 'Please enter the purchase order' }]}
              >
                <Input className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="requestType"
                label="Request Type"
                rules={[{ required: true, message: 'Please enter the request type' }]}
              >
                <Select onChange={handleTypeChange}>
                  <Option value="I have a VOR Number">I have a VOR Number</Option>
                  <Option value="Not VOR Related">Not VOR Related</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="VORNumber"
                label="VOR Number"
                rules={
                  requestType === 'I have a VOR Number'
                    ? [
                        {
                          required: true,
                          message: 'Please enter the VOR number',
                        },
                        {
                          pattern: /\d{6}[A-Za-z]\d{3}/,
                          message: 'Please verify VOR number is 10 characters (format: 6 digits, 1 letter, 3 digits)',
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <Input maxLength={10} disabled={requestType !== 'I have a VOR Number'} className="uppercase" />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                name="bookingType"
                label="Booking Type"
                rules={
                  requestType === 'I have a VOR Number'
                    ? [
                        {
                          required: true,
                          message: 'Please enter the booking start date',
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <Select disabled={requestType !== 'I have a VOR Number'} onChange={handleBookingTypeChange}>
                  <Option value="Vehicle On Site">Vehicle On Site</Option>
                  <Option value="Vehicle Delivered">Vehicle Delivered</Option>
                </Select>
              </Form.Item>
            </Col>

            {bookingType === 'Vehicle On Site' && requestType === 'I have a VOR Number' && (
              <Col lg={12} xs={24}>
                <Form.Item
                  name="selectVehicle"
                  label="Select Vehicle"
                  rules={[{ required: true, message: 'Please select the vehicle' }]}
                >
                  <Select filterOption={filterOptions} showSearch onFocus={getVehicle} onClick={getVehicle}>
                    {vehicleOptions.length === 0 ? (
                      <Option key={'Loading...'} value={'Loading...'} disabled>
                        Loading...
                      </Option>
                    ) : (
                      vehicleOptions.map((option: ISelectOption) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col lg={12} xs={24}>
              <Form.Item
                name="bookingStartDate"
                label="Booking Start Date"
                rules={
                  requestType === 'I have a VOR Number'
                    ? [
                        {
                          required: true,
                          message: 'Please enter the booking start date',
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <DatePicker
                  disabled={requestType !== 'I have a VOR Number' || bookingType === ''}
                  className="box"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                  disabledDate={
                    bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="estimatedFinishDate" label="Estimated Finish Date">
                <DatePicker
                  disabled={requestType !== 'I have a VOR Number'}
                  className="box"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                  disabledDate={
                    bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="hotlineNumber" label="Hotline Number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="comments"
                label={'Comments'}
                rules={
                  isNonVOR
                    ? [
                        {
                          required: true,
                          message: 'Please enter comments',
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              {isNonVOR && (
                <div className={'flex flex-column'}>
                  <div className={'text-xs mt-12'}>
                    To assist in approving your request please supply a detailed reason why your dealership can not supply a VOR
                    number at this time.
                  </div>
                  <div className={'text-xs mb-8 italic text-grey pl-12 my-12 border-l-grey'}>
                    Example: We cannot supply a VOR number at this time as the clients vehicle has a major oil leak coming from
                    the transmission that is visible and currently towed in undrivable, diagnosis is to be completed first and
                    hotline raised, we need to get client mobile asap the vehicle is 12 months old.
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <ApprovalVOR open={viewApprovalVORModalVisible} onCancel={handleModalCancel} onOk={handleSubmit} />
    </>
  );
}

export default CreateRentalRequest;
