import { Form, Input, Modal, message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { IRentalRequest, MyIcon } from '@scanny-app/loopy-loop';
import { useState } from 'react';

export interface FormUpdateHotlineProps {
  rentalRequestData: IRentalRequest;
  HotlinePass: [setReloadDataRentalRequest: (value: boolean) => void, setIsHotlineLoading: (value: boolean) => void];
}

interface VORSubmit {
  hotlineNumber: string;
}

function FormUpdateHotline({ rentalRequestData, HotlinePass }: FormUpdateHotlineProps) {
  const [formVOR] = Form.useForm();
  const [setReloadDataRentalRequest, setIsHotlineLoading] = HotlinePass;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmitVOR = () => {
    setIsHotlineLoading(true);
    const formattedValues = {
      hotlineNumber: formVOR.getFieldsValue().hotlineNumber,
    };

    updateHotline(rentalRequestData.id, formattedValues);
    setShowConfirmation(false);
  };

  const updateHotline = (id: string, dataToSubmit: VORSubmit) => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/RentalRequest/${id}`, JSON.stringify(dataToSubmit), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setIsHotlineLoading(false);
            setReloadDataRentalRequest(true);
            message.success('Hotline Number updated successfully');
          })
          .catch(() => {
            message.error('Something went wrong updating the Hotline number. Please try again later.');
            setIsHotlineLoading(false);
          });
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.');
        setIsHotlineLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onOk={handleSubmitVOR}
        className="custom-modal"
        width={400}
        centered
      >
        <h5 className="text-sm font-semibold m-0">Are you sure you want to update Hotline number?</h5>
        <p className="mt-4 mb-16">This action will save the Hotline number to this request and cannot be undone.</p>
      </Modal>
      <Form form={formVOR} className="w-full flex flex-row form-btn-inline" onFinish={() => setShowConfirmation(true)}>
        <Form.Item name="hotlineNumber" className="m-0 w-full">
          <Input />
        </Form.Item>

        <button className="approve-btn h-45" type="submit">
          <MyIcon icon={'IconApproved'} />
        </button>
      </Form>
    </>
  );
}

export default FormUpdateHotline;
