import { createContext } from 'react';
import { ISideMenu } from '@scanny-app/loopy-loop';

export interface IAppConfig {
  SideMenuList: ISideMenu[];
  logoImagePath: string;
  showSideBarText: boolean;
  sideBarBackgroundColour: string;
  feedbackButton?: boolean;
  onLogoutAction?: () => void;
}

export const AppConfigContext = createContext<IAppConfig>({} as IAppConfig);
