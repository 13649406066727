import { MyIcon, ApprovalStatusComponent } from '@scanny-app/loopy-loop';

interface CollapsibleCardProps {
  title: 'Extension Request' | 'Initial Approval';
  expanded: boolean;
  content: React.ReactNode;
  onCollapsible: () => void;
  status: string;
}

function CollapsibleCard({ title, expanded, content, onCollapsible, status }: CollapsibleCardProps) {
  //BoxColor
  const wrapStatusClass = status === 'Approved' ? 'box-1-green' : status === 'Rejected' ? 'box-1-red' : 'box-1-blue';

  return (
    <div className={`${wrapStatusClass} p-24 flex flex-column gap-16`}>
      <div className="flex content-space-between w-full">
        <h5 className="text-sm font-semibold m-0">{title}</h5>
        <div className="flex gap-16 middle">
          <ApprovalStatusComponent status={status} />
          <button className="btn-transp" onClick={onCollapsible}>
            <MyIcon icon={expanded ? 'IconCollapse' : 'IconExpand'} />
          </button>
        </div>
      </div>
      <div className={`flex-column gap-16 ${expanded ? `flex` : 'hidden'}`}>{content}</div>
    </div>
  );
}

export default CollapsibleCard;
