import { Form, Input, Button, Typography } from 'antd';
import { ISignIn, MyIcon, IBackgroundStyle, WarningMessage } from '@scanny-app/loopy-loop';
interface LoginProps {
  onFinish: (values: ISignIn) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFinishFailed: () => void;
  errorMessage: string;
  setShowResetPassword: (value: boolean) => void;
  button: IBackgroundStyle;
  includesTermsAndPrivacy?: boolean;
}
function Login({ onFinish, onFinishFailed, errorMessage, setShowResetPassword, button, includesTermsAndPrivacy }: LoginProps) {
  const lastLogin = localStorage.getItem('lastLogin');
  const message = lastLogin ? 'Welcome back!' : 'Welcome';
  const description = lastLogin ? 'Please enter your details' : 'To get started, please enter your details';

  return (
    <>
      <div className="mb-24 flex gap-8 flex-column">
        <h1 className="m-0">{message}</h1>
        <p className="m-0">{description}</p>
      </div>
      <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password />
        </Form.Item>
        {process.env.REACT_APP_SHOW_PASSWORD_RESET_MESSAGE && localStorage.getItem('hasResetPassword') !== 'true' ? (
          <WarningMessage
            messageText={'Password reset is required for all users'}
            actionText={'Reset here'}
            onClickAction={() => setShowResetPassword(true)}
          />
        ) : null}
        <Form.Item className="remember">
          <Button
            type="link"
            style={{
              background: 'white',
              color: '#232323',
              fontWeight: '700',
              fontSize: '12px',
              padding: '0',
            }}
            onClick={() => setShowResetPassword(true)}
          >
            Forgot password
          </Button>
        </Form.Item>
        {errorMessage && (
          <Typography.Text
            style={{
              color: 'red',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {errorMessage}
          </Typography.Text>
        )}
        <Form.Item>
          <button
            className="btn-login-form"
            style={{
              backgroundImage: `url(${button.backgroundURL})`,
              backgroundColor: button.backgroundColour,
            }}
          >
            Submit
          </button>

          <div className="reach-out flex gap-8 content-center middle">
            {"Don't have an account? "}
            <Button
              className="flex gap-4 middle"
              onClick={() => {
                window.open('https://www.carbiz.com.au/contact-us/', '_blank');
              }}
              type="link"
              style={{
                background: 'white',
                color: '#232323',
                fontWeight: '700',
                fontSize: '12px',
                padding: '0',
              }}
            >
              Reach out
              <MyIcon icon={'IconTab'} />
            </Button>
          </div>
        </Form.Item>
      </Form>
      {includesTermsAndPrivacy && (
        <div className="flex gap-12 content-center  text-xs text-black">
          <div
            className="hover-pointer"
            onClick={() => {
              window.open('https://carbiz.com.au/terms-conditions/', '_blank');
            }}
          >
            Terms and conditions
          </div>
          <div className="text-grey">|</div>
          <div
            className="hover-pointer"
            onClick={() => {
              window.open('https://carbiz.com.au/privacy-policy/', '_blank');
            }}
          >
            Privacy policy
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
