import { Hour } from '@scanny-app/loopy-loop';
import { ISelectOption } from '@scanny-app/loopy-loop';

const getHour = (is24Hour: boolean, hour: number) => {
  if (!is24Hour) {
    if (hour > 12) {
      return hour - 12;
    } else {
      return hour === 0 ? 12 : hour;
    }
  }
  return hour;
};

const getClientTZOffset = () => {
  return -new Date().getTimezoneOffset() / 60;
};

const getFormattedTimeZone = () => {
  const offset = getClientTZOffset();
  const hours = Math.floor(Math.abs(offset));
  const minutes = (Math.abs(offset) * 60) % 60;
  return `${offset >= 0 ? '+' : '-'}${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const getTimes = (is24HourTime: boolean, timeJumpInMins: 15 | 30 | 60, startHour: Hour = 0, endHour: Hour = 24) => {
  const timesToReturn: ISelectOption[] = [];
  for (let i = startHour; i <= endHour; i++) {
    for (let min = 0; min < 60; min += timeJumpInMins) {
      timesToReturn.push({
        label: `${getHour(is24HourTime, i).toString()}:${min === 0 ? '0' : ''}${min} ${i < 12 ? 'AM' : 'PM'}`,
        value: `${i < 10 ? '0' : ''}${i.toString()}:${min === 0 ? '0' : ''}${min}:00.000000${getFormattedTimeZone()}`,
      });
    }
  }
  return timesToReturn;
};

export { getTimes, getFormattedTimeZone };
