import { Image, Tooltip } from 'antd';
import { useState, useEffect, useContext, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { message } from 'antd';
import { MyIcon, ISubMenu, UserContext, AppConfigContext, ISideMenu } from '@scanny-app/loopy-loop';

interface SideMenuProps {
  sidebarVisibleInMobile: boolean;
  setSubMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSidebar: () => void;
  bottomNode?: ReactNode;
}

function SideMenu({ sidebarVisibleInMobile, setSubMenuVisible, toggleSidebar, bottomNode }: SideMenuProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const userData = useContext(UserContext);
  const { SideMenuList, logoImagePath, showSideBarText, sideBarBackgroundColour, onLogoutAction } = useContext(AppConfigContext);

  const handleSubmenuClick = (link: string, sidebarItem: ISideMenu, subMenuItem: ISubMenu) => {
    if (sidebarItem.onSubMenuClickAction) {
      sidebarItem.onSubMenuClickAction(subMenuItem);
    }

    navigate(link);
    toggleSidebar();
  };
  function handleMenuClickAndNavigate(item: string, link: string, menu: ISubMenu[], onSideMenuClickAction?: () => void) {
    navigate(link);
    menu.length === 0 && toggleSidebar();
    onSideMenuClickAction && onSideMenuClickAction();
  }

  useEffect(() => {
    const pathnameSegments = location.pathname.split('/');
    setActiveMenu(pathnameSegments[1]);

    const isDashboardCondition = SideMenuList.some(
      (sidebarItem) => pathnameSegments[1] === sidebarItem.slug && sidebarItem.menu.length > 0,
    );
    const updateSubMenuVisibility = () => {
      setSubMenuVisible(isDashboardCondition);
    };
    updateSubMenuVisibility();
  }, [location, setSubMenuVisible, SideMenuList]);

  function handleLogout() {
    Auth.signOut()
      .then(() => {
        if (onLogoutAction) onLogoutAction();
        navigate(0);
      })
      .catch(() => {
        message.error('Error signing out');
      });
  }

  let userInfo = '';
  let initials = userData?.email[0];
  if (userData?.firstName != null && userData?.lastName != null) {
    userInfo = `${userData?.firstName} ${userData?.lastName}`;
    initials = userData?.firstName[0] + userData?.lastName[0];
  }

  const tooltipSidebarText = (
    <div className="btn-signout" onClick={handleLogout}>
      Sign out
    </div>
  );

  return (
    <div className={`sidebar-container ${sidebarVisibleInMobile ? 'flex' : 'md-hidden'}`}>
      <div id={showSideBarText ? 'sidebar-long' : 'sidebar'} style={{ backgroundColor: sideBarBackgroundColour }}>
        <div className={'relative h-full'}>
          <div id="sidebar-logo">
            <Image preview={false} src={logoImagePath}></Image>
          </div>
          <div className="menu-list flex content-space-between flex-column ">
            <ul>
              {SideMenuList.map((sidebarItem, index) => {
                return (
                  <li
                    key={index}
                    className={`menu-list-items relative ${activeMenu === sidebarItem.slug ? 'active' : ''}  ${showSideBarText ? 'long-menu-item' : 'short-menu-item'}`}
                    onClick={() =>
                      handleMenuClickAndNavigate(
                        sidebarItem.label,
                        sidebarItem.link,
                        sidebarItem.menu,
                        sidebarItem.onSideMenuClickAction,
                      )
                    }
                  >
                    {sidebarItem.icon}
                    {showSideBarText && <p className={'submenu-list-items '}>{sidebarItem.label}</p>}
                    {activeMenu !== sidebarItem.slug && !showSideBarText && <span className="tooltip">{sidebarItem.label}</span>}
                  </li>
                );
              })}
            </ul>
            <div className="flex flex-column w-full content-center absolute bottom-0 left-0">
              {!showSideBarText && (
                <div className="flex flex-column content-center w-full gap-12 middle">
                  <div className="photo-profile">{initials && initials.toUpperCase()}</div>
                  {userInfo !== '' && <div className="userInfo">{userInfo}</div>}
                </div>
              )}
              <div className={`menu-list ${showSideBarText ? 'longLogout' : ''}`}>
                {bottomNode}
                <div
                  className={`flex gap-12 relative hover-tooltip  ${showSideBarText ? 'flex-column' : 'short-menu-item hover-pointer '}`}
                  onClick={handleLogout}
                >
                  {!showSideBarText ? (
                    <>
                      <div className="text-white menu-list-items">
                        <MyIcon icon={'IconLogout'} />
                      </div>
                      <div className="tooltip" style={{ left: '78px' }}>
                        Logout
                      </div>
                    </>
                  ) : (
                    <Tooltip
                      placement="right"
                      title={tooltipSidebarText}
                      overlayStyle={{ paddingLeft: 20 }}
                      arrow={false}
                      overlayClassName="signout-bubble"
                    >
                      <div id="sidebar-avatar" className="flex bg-dark-grey-30 rounded-6 p-12 gap-12 middle hover-pointer ">
                        <div className="photo-profile">{initials && initials.toUpperCase()}</div>
                        {userInfo !== '' && <div className="userInfoBig">{userInfo}</div>}
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {SideMenuList.map((sidebarItem, index) => {
        let currentGroup = '';
        if (activeMenu === sidebarItem.slug && sidebarItem.menu.length > 0) {
          return (
            <div id="middle-menu" key={index}>
              <div id="header-middle-menu">
                {sidebarItem.icon}
                <h1>{sidebarItem.label}</h1>
              </div>
              <div className="submenu-list">
                {sidebarItem.menu && (
                  <ul data-name={sidebarItem.label}>
                    {sidebarItem.menu.map((menuitem, subIndex) => {
                      const currentSlug = sidebarItem.activeSubMenuFormula && sidebarItem.activeSubMenuFormula();
                      let activeClass = '';
                      if (currentSlug === menuitem.slug) {
                        activeClass = 'active';
                      }
                      let subGroupTitle = <></>;
                      if (menuitem.group && currentGroup !== menuitem.group) {
                        subGroupTitle = <li className="px-16 pb-16 flex gap-4 middle">{menuitem.group}</li>;
                        currentGroup = menuitem.group;
                      }
                      return (
                        <>
                          {subGroupTitle}
                          <li
                            key={subIndex}
                            data-name={menuitem.slug}
                            onClick={() => {
                              handleSubmenuClick(menuitem.link, sidebarItem, menuitem);
                            }}
                            className={`cursor-pointer flex gap-4 middle ${activeClass} ${menuitem.group ? 'border-l-grey ml-24 mb-16 p-0-12' : 'p-16-24'}`}
                          >
                            {menuitem.label}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default SideMenu;
