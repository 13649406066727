import { useContext } from 'react';
import { CustomButton, DefaultCard, EmptyDashedCard, IRentalRequest, IBooking, UserContext } from '@scanny-app/loopy-loop';
import RentalCardComponent from '../Card/CardRental';

interface HireDetailsProps {
  rentalRequestData: IRentalRequest;
  mostRecentBooking: IBooking;
  onClickTab: (tabKey: string) => void;
  setViewMakeBookingModalVisible: (value: boolean) => void;
  setReloadDataRentalRequest: (value: boolean) => void;
}

function OverviewHire({
  rentalRequestData,
  mostRecentBooking,
  onClickTab,
  setViewMakeBookingModalVisible,
  setReloadDataRentalRequest,
}: HireDetailsProps) {
  const userData = useContext(UserContext);
  const isDealer = userData?.profileType === 'Dealer';
  const isManufacturer = userData?.profileType === 'Manufacturer';
  const defaultCardManufacturer = <DefaultCard content={'Available after booking has been created'} />;

  return (
    <>
      {rentalRequestData.status === 'Pending' && (
        <>
          {isManufacturer && defaultCardManufacturer}
          {isDealer && (
            <DefaultCard className={'h-90 content-center text-grey'} content={'Waiting for manufacturer approval...'} />
          )}
        </>
      )}
      {rentalRequestData.status === 'Rejected' && <EmptyDashedCard />}
      {rentalRequestData.status === 'Approved' &&
        (rentalRequestData.bookingStatus === 'No booking' ? (
          <>
            {isManufacturer && defaultCardManufacturer}
            {isDealer && (
              <CustomButton
                className="booking-btn content-center"
                onClick={() => setViewMakeBookingModalVisible(true)}
                btnName="Create a new booking"
                iconName="IconAdd"
              />
            )}
          </>
        ) : (
          <RentalCardComponent
            mostRecentBooking={mostRecentBooking}
            rentalRequestData={rentalRequestData}
            onClickTab={onClickTab}
            setReloadDataRentalRequest={setReloadDataRentalRequest}
          />
        ))}
    </>
  );
}

export default OverviewHire;
