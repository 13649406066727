import React, { useEffect, useRef, useState } from 'react';
import { Layout, Flex, message } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { SmallLoading, MyIcon, convertToBase64, IconCarScan, compressImage, ICapturedImage } from '@scanny-app/loopy-loop';

const SUBJECTS = [
  { step: '1', title: 'Dashboard Odometer', icon: 'IconDashboardOdometer' },
  { step: '2', title: 'Interior Front Driver Seat', icon: 'IconInteriorFrontDriverSeat' },
  { step: '3', title: 'Interior Rear Right Seat', icon: 'IconInteriorRearRightSeat' },
  { step: '4', title: 'Interior Rear Left Seat', icon: 'IconInteriorRearLeftSeat' },
  { step: '5', title: 'Interior Front Passenger Seat', icon: 'IconInteriorFrontPassengerSeat' },
  { step: '6', title: 'Car Body Front', icon: 'IconCarBodyFront' },
  { step: '7', title: 'Car Body Driver Side Front', icon: 'IconCarBodyDriverSideFront' },
  { step: '8', title: 'Car Body Driver Side Middle', icon: 'IconCarBodyDriverSideMiddle' },
  { step: '9', title: 'Car Body Driver Side Rear', icon: 'IconCarBodyDriverSideRear' },
  { step: '10', title: 'Car Body Rear', icon: 'IconCarBodyRear' },
  { step: '11', title: 'Car Body Passenger Side Rear', icon: 'IconCarBodyPassengerSideRear' },
  { step: '12', title: 'Car Body Passenger Side Middle', icon: 'IconCarBodyPassengerSideMiddle' },
  { step: '13', title: 'Car Body Passenger Side Front', icon: 'IconCarBodyPassengerSideFront' },
  { step: '14', title: 'Car Key', icon: 'IconCarKey' },
];

const WIDTH_IDEAL = 4032;
const HEIGHT_IDEAL = 3024;

interface CarImageCaptureProps {
  onFinish: (values: ICapturedImage[]) => void;
  setIsModal: (value: boolean) => void;
  runCameraEffect: number;
}

const CarImageCapture = ({ onFinish, setIsModal, runCameraEffect }: CarImageCaptureProps) => {
  const { Content } = Layout;

  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);

  const [inReview, setInReview] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultsRef = useRef<any[]>([]);
  const imageCapture = useRef<ImageCapture>();
  const [previewCaptureImages, setPreviewCaptureImages] = useState<ICapturedImage[]>();
  const [submitCaptureImages, setSubmitCaptureImages] = useState<ICapturedImage[]>();

  const supportsImageCapture = () => {
    return 'ImageCapture' in window;
  };

  const initialiseImageCapture = () => {
    const mediaStream = navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment',
        width: { min: 1280, ideal: WIDTH_IDEAL },
        height: { min: 720, ideal: HEIGHT_IDEAL },
        aspectRatio: { ideal: 0.75 },
      },
    });

    mediaStream
      .then((stream) => {
        setIsCameraLoading(false);

        if (videoRef.current) {
          const video = videoRef.current;
          video.srcObject = stream;
          const playPromise = video.play();

          const [tracks] = stream.getVideoTracks();
          imageCapture.current = new ImageCapture(tracks);

          // To avoid Chrome errors
          if (playPromise !== undefined) {
            playPromise
              .then(() => {})
              .catch((error) => {
                message.error(`Auto-play prevent ${error}`);
              });
          }

          if (!imageCapture.current) {
            message.error('ImageCapture is not initialized');
            setIsLoading(false);
            return;
          }
        }

        return imageCapture.current?.getPhotoCapabilities();
      })
      .catch((err) => {
        if (err instanceof OverconstrainedError) {
          setError('Your device does not meet the minimum camera resolution requirements for image capture.');
        } else if (err instanceof DOMException && err.message === 'Permission denied') {
          setError('Camera permissions are not granted. Please allow camera access in your device settings to use this feature.');
        } else {
          setError(err.name + ': ' + err.message);
        }
      });
  };

  // For browsers that do not support ImageCapture. Image quality may be worse.

  const initialiseOther = () => {
    // Check if getUserMedia is supported
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          facingMode: 'environment', // or 'user' for front camera
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          setIsCameraLoading(false);

          if (videoRef.current) {
            const video = videoRef.current;
            video.srcObject = stream;
            const playPromise = video.play();

            if (playPromise !== undefined) {
              playPromise
                .then(() => {})
                .catch((error) => {
                  message.error(`Auto-play prevent ${error}`);
                });
            }
          }
        })
        .catch((error) => {
          message.error('Error accessing media devices:', error);
        });
    }
  };

  useEffect(() => {
    if (runCameraEffect && currentSubject !== SUBJECTS.length) {
      setIsCameraLoading(true);

      // Assuming initialiseImageCapture handles setting up the camera
      if (supportsImageCapture()) {
        initialiseImageCapture();
      } else {
        initialiseOther();
      }
    }
  }, [runCameraEffect]);

  const paintToCanvas = () => {
    if (videoRef.current && photoRef.current) {
      const video = videoRef.current;
      const photo = photoRef.current;
      const ctx = photo.getContext('2d');
      photo.width = video.videoWidth;
      photo.height = video.videoHeight;
      if (ctx) {
        ctx.drawImage(video, 0, 0, photo.width, photo.height);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processBlob = (blob: any) => {
    const subjectName = SUBJECTS[currentSubject].title || 'default_filename';
    const fileName = `${subjectName}.jpg`;
    const file = new File([blob], fileName, { type: 'image/jpeg' });
    compressImage(file, 0.6, 1920, 1080, handleCompressedImage);
  };

  const handleCompressedImage = async (compressedFile: File) => {
    const base64String = await convertToBase64(compressedFile);
    resultsRef.current.push(base64String);
  };

  const takePhoto = async () => {
    setIsCapturing(true);
    if (videoRef.current) {
      const video = videoRef.current;
      video.pause();
    }

    if (supportsImageCapture()) {
      if (imageCapture.current) {
        const blob = await imageCapture.current.takePhoto();
        await processBlob(blob);
        setIsCapturing(false);
      }
    } else if (photoRef.current) {
      paintToCanvas();
      photoRef.current.toBlob(async (blob) => {
        if (blob) {
          await processBlob(blob);
        } else {
          message.error('Failed to convert canvas to Blob.');
        }
      }, 'image/jpeg');
      setIsCapturing(false);
    }
    setInReview(true);
  };

  const retakePhoto = async () => {
    const video = videoRef.current;
    try {
      if (video) await video.play();
    } catch (err) {
      message.error('Something went wrong. Please try again later');
    }

    resultsRef.current.pop();
    setInReview(false);
  };

  const confirm = async () => {
    setIsLoading(true);

    if (currentSubject === SUBJECTS.length - 1) {
      const res = await Promise.all(resultsRef.current);
      const dataPreview = res.map((data, index) => {
        const subjectName = SUBJECTS[index].title;
        return {
          name: `${subjectName}.jpg`,
          title: subjectName,
          data: data,
        };
      }) as ICapturedImage[];

      setPreviewCaptureImages(dataPreview);
      setSubmitCaptureImages(dataPreview);
    }

    setTimeout(() => {
      setIsLoading(false);
      setCurrentSubject(currentSubject + 1);
      setInReview(false);

      if (currentSubject === SUBJECTS.length - 1) {
        if (videoRef.current && videoRef.current.srcObject) {
          const mediaStream = videoRef.current.srcObject as MediaStream;
          const tracks = mediaStream.getTracks();
          tracks.forEach((track) => track.stop());
          mediaStream.getTracks().forEach((track) => mediaStream.removeTrack(track));
          videoRef.current.srcObject = null;
        }
      } else if (!isLoading) {
        const video = videoRef.current;
        if (video) {
          try {
            video.play(); // Can only use 'await' inside an async function.
          } catch (err) {
            message.error('Something went wrong. Please try again later');
          }
        }
      }
    }, 2000);
  };

  const handleSubmit = async () => {
    if (submitCaptureImages) {
      onFinish(submitCaptureImages);
    } else {
      message.error('Something went wrong while submiting. Please try again later');
    }

    handleCancel();
  };

  const handleCancel = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
      mediaStream.getTracks().forEach((track) => mediaStream.removeTrack(track));
      videoRef.current.srcObject = null;
      setIsModal(false);
    } else {
      setIsModal(false);
    }
  };

  return (
    <div className="flex flex-column w-full h-full">
      {error.length === 0 ? (
        <>
          <div id="header-capture" className="w-full flex content-space-between middle pb-16">
            <div className="flex gap-12 middle">
              {currentSubject === SUBJECTS.length ? (
                <>
                  <IconCarScan icon="ScanCompleted" />
                  <div className="flex flex-column">
                    <div className="text-xs">Preview</div>
                    <h3 className="m-0 text-sm">Scan Complete</h3>
                  </div>
                </>
              ) : (
                <>
                  <IconCarScan icon={SUBJECTS[currentSubject].icon} />
                  <div className="flex flex-column">
                    <div className="text-xs">
                      Step{' '}
                      <span className="font-semibold">
                        {SUBJECTS[currentSubject].step}/{SUBJECTS.length}
                      </span>
                    </div>
                    <h3 className="m-0 text-sm">{SUBJECTS[currentSubject].title}</h3>
                  </div>
                </>
              )}
            </div>
            <div onClick={handleCancel} className="flex middle hover-pointer">
              <MyIcon icon={'IconClose'} />
            </div>
          </div>

          <div id="main-capture" className="w-full">
            {isCameraLoading && (
              <div className="camera-loading">
                <SmallLoading />
              </div>
            )}
            {currentSubject === SUBJECTS.length && (
              <div className="grid grid-cols-2 w-full gap-8 overflow-scroll camera-scan-preview">
                {previewCaptureImages &&
                  previewCaptureImages.length > 0 &&
                  previewCaptureImages.map((image, index) => (
                    <div key={index} className="car-image-capture">
                      <img src={`data:image/jpeg;base64,${image.data}`} alt={image.title} />
                      <div className="step">{index + 1}</div>
                    </div>
                  ))}
              </div>
            )}
            <video
              id="camera-feed"
              autoPlay={true}
              playsInline={true}
              className={`w-full ${(isCameraLoading || currentSubject === SUBJECTS.length) && 'hidden'}`}
              muted={true}
              ref={videoRef}
            ></video>
            <canvas ref={photoRef} className="w-full hidden" />
          </div>

          <div id="footer-capture" className="mt-12">
            {isLoading ? (
              <div className="italic text-sm h-45 flex middle">Processing...</div>
            ) : inReview ? (
              <div className="h-45 w-full content-space-between gap-16 flex">
                <button className="reject-btn cust-btn h-45 flex gap-8 middle" onClick={retakePhoto}>
                  <MyIcon icon="IconRetake" />
                  Retake
                </button>
                <button className="approve-btn cust-btn h-45 flex gap-8 middle" onClick={confirm}>
                  Confirm & Next <MyIcon icon="IconNext" />
                </button>
              </div>
            ) : (
              <div className="flex content-end">
                {/* {currentSubject > 0 && currentSubject < SUBJECTS.length && (
                  <button className="btn-outline" onClick={handleBack}>
                    Back
                  </button>
                )} */}

                {currentSubject === SUBJECTS.length ? (
                  <button className="btn-secondary flex gap-8 middle" onClick={handleSubmit}>
                    Done <MyIcon icon="IconNext" />
                  </button>
                ) : (
                  <div className="flex gap-8">
                    {videoRef.current === null && (
                      <button className="btn-secondary" onClick={initialiseOther} type="button">
                        Reload
                      </button>
                    )}
                    <button
                      className="btn-secondary flex gap-8 middle"
                      onClick={takePhoto}
                      type="button"
                      disabled={isCameraLoading || isCapturing}
                    >
                      Capture <MyIcon icon="IconRetake" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <Content>
          <Flex align="center" justify="center" vertical style={{ height: '100%' }}>
            <Title level={2} style={{ textAlign: 'center', padding: '20px' }}>
              {error}
            </Title>
            <FrownOutlined className="large-icon" />
          </Flex>
        </Content>
      )}
    </div>
  );
};

export default CarImageCapture;
