import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { FormInstance, message, Form } from 'antd';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  CollapsibleForm,
  RentalVehicle,
  PreAuth,
  RentalAgreement,
  MultipleDrivers,
  IBooking,
  LargeLoading,
  UserContext,
  capitalizeWords,
  SecondaryNavigation,
  ICapturedImage,
  MyIcon,
  VehicleOwner,
  PoweredByCarbizBanner,
} from '@scanny-app/loopy-loop';
interface RentalAgreementProps {
  AgreementDescription: React.ReactNode;
  backLink: (id: string) => string;
  entity?: 'Carbiz';
  showBanner?: boolean;
}
function CreateRentalAgreement({ AgreementDescription, backLink, entity, showBanner }: RentalAgreementProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { id, hire, bookingId } = useParams();
  const [rentalVehicleForm] = Form.useForm();
  const [driverForm] = Form.useForm();
  const [preAuthForm] = Form.useForm();
  const [ownerForm] = Form.useForm();
  const [rentalAgreementForm] = Form.useForm();
  const [currentForm, setCurrentForm] = useState(1);
  const [stripeErrorMessage, setStripeErrorMessage] = useState(false);
  const [bookingRecord, setBookingRecord] = useState<IBooking>({} as IBooking);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isPreAuthLoading, setIsPreAuthLoading] = useState(false);
  const [isRentalVehicleLoading, setIsRentalVehicleLoading] = useState(false);
  const [isPreAuthData, setIsPreAuthData] = useState(false);
  const [isChecklistData, setIsChecklistData] = useState(false);
  const [getCaptureImages, setGetCaptureImages] = useState<ICapturedImage[]>();
  const [error, setError] = useState<string | null>(null);
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const isCarbiz = entity && true;
  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        axios
          .post(
            `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Booking`,
            JSON.stringify({ hire: hire, rentalAgreement: null, id: bookingId }),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((response) => {
            if (response.data.length === 0) {
              setError('Rental agreement has been completed for this booking');
            }
            setBookingRecord(response.data[0]);
            setIsInitialLoading(false);
          });
      })
      .catch((error) => {
        setIsInitialLoading(false);
        setError('Something went wrong. Please try again later');
      });
  }, [hire, bookingId]);
  const handleNext = (currentFormName: FormInstance) => {
    currentFormName
      .validateFields()
      .then(() => {
        setCurrentForm(currentForm + 1);
      })
      .catch(() => {
        message.error('Please complete all fields').then();
      });
  };
  const handleBack = () => {
    if (currentForm > 1) {
      setCurrentForm(currentForm - 1);
    }
  };
  const handleRentalVehicleNext = () => {
    rentalVehicleForm.setFieldsValue({
      captureCarImages: getCaptureImages,
    });
    if (!isChecklistData) {
      rentalVehicleForm
        .validateFields()
        .then(() => {
          setIsRentalVehicleLoading(true);

          Auth.currentSession()
            .then((data) => {
              const token = data.getAccessToken().getJwtToken();
              return axios.post(
                `${process.env.REACT_APP_ENDPOINT_URL}/create/Checklist`,
                JSON.stringify({
                  bookingId: bookingRecord.id,
                  checklistType: 'Out',
                  fuel: rentalVehicleForm.getFieldsValue().fuelOut,
                  files: getCaptureImages,
                }),
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
            })
            .then(() => {
              setCurrentForm(currentForm + 1);
              setIsRentalVehicleLoading(false);
              setIsChecklistData(true);
              message.success('Checklist Successful').then();
            })
            .catch(() => {
              message.error('Something went wrong with the checklist update').then();
              setIsRentalVehicleLoading(false);
            });
        })
        .catch(() => {
          message.error('Please complete all fields').then();
          setIsRentalVehicleLoading(false);
        });
    } else {
      setCurrentForm(currentForm + 1);
    }
  };

  const handleSubmit = (paymentIntentId: string) => {
    setIsSubmissionLoading(true);
    const driverFormData = driverForm.getFieldsValue();
    driverFormData.firstName = capitalizeWords(driverFormData.firstName);
    driverFormData.lastName = capitalizeWords(driverFormData.lastName);
    Object.keys(driverFormData).forEach((key) => {
      if (key.startsWith('additionalDriver')) {
        const driver = driverFormData[key];
        driverFormData[key] = {
          ...driver,
          firstName: capitalizeWords(driver.firstName),
          lastName: capitalizeWords(driver.lastName),
        };
      }
    });
    const rentalVehicleData = rentalVehicleForm.getFieldsValue();
    Auth.currentSession().then((data) => {
      const token = data.getAccessToken().getJwtToken();
      axios
        .post(
          `${process.env.REACT_APP_ENDPOINT_URL}/create/RentalAgreement`,
          JSON.stringify({
            bookingId: bookingRecord.id,
            ...driverFormData,
            ...rentalAgreementForm.getFieldsValue(),
            ...ownerForm.getFieldsValue(),
            fuelOut: rentalVehicleData.fuelOut,
            stripePaymentIntentId: paymentIntentId,
          }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          navigate(`/rentals/rental-agreement/form-submitted/${id}`);
        })
        .catch(() => {
          message.error('Something went wrong').then();
          setIsButtonDisabled(false);
          setIsSubmissionLoading(false);
          setIsPreAuthData(false);
        });
    });
  };

  const handlePreAuthNext = () => {
    setIsButtonDisabled(true);
    if (!isPreAuthData) {
      preAuthForm
        .validateFields()
        .then(async () => {
          if (!stripe || !elements) {
            message.error('Stripe is not loaded');
            setIsButtonDisabled(false);
            return;
          }
          const card = elements.getElement(CardElement);
          if (!card) {
            message.error('Card Element not found');
            setIsButtonDisabled(false);

            return;
          }
          const result = await stripe.createToken(card);
          if (result.error) {
            message.error('Something went wrong with the Stripe token creation');
            setIsButtonDisabled(false);
          } else {
            setIsPreAuthLoading(true);
            try {
              Auth.currentSession().then((response) => {
                const amplifyToken = response.getAccessToken().getJwtToken();
                axios
                  .post(
                    `${process.env.REACT_APP_ENDPOINT_URL}/v1/create/payment-intent`,
                    JSON.stringify({
                      name: capitalizeWords(driverForm.getFieldValue('firstName')) + ' ' + driverForm.getFieldValue('lastName'),
                      email: driverForm.getFieldValue('email'),
                      phone: driverForm.getFieldValue('mobilePhone'),
                      address: {
                        city: driverForm.getFieldValue('city'),
                        line1: driverForm.getFieldValue('street'),
                        line2: driverForm.getFieldValue('streetLine2'),
                        postal_code: driverForm.getFieldValue('postCode'),
                        state: driverForm.getFieldValue('state'),
                        country: 'AU',
                      },
                      metadata: {
                        nameOnCard: capitalizeWords(preAuthForm.getFieldValue('nameOnCard')),
                        bookingId: bookingRecord.id,
                      },
                      stripeToken: result.token.id,
                    }),
                    {
                      headers: {
                        Authorization: `Bearer ${amplifyToken}`,
                      },
                    },
                  )
                  .then((response) => {
                    setIsPreAuthLoading(false);
                    setIsPreAuthData(true);
                    const paymentIntentId = response.data.clientSecret.split('_secret_')[0];
                    setTimeout(() => {
                      handleSubmit(paymentIntentId);
                    }, 1500);
                  })
                  .catch(() => {
                    setIsPreAuthLoading(false);
                    setIsButtonDisabled(false);
                    setStripeErrorMessage(true);
                  });
              });
            } catch {
              setIsPreAuthLoading(false);
              message.error('Something went wrong');
            }
          }
        })
        .catch(() => {
          message.error('Please complete all fields in the Pre-Auth form');
          setIsButtonDisabled(false);
        });
    }
  };

  const handleErrorChange = (value: boolean) => {
    setStripeErrorMessage(value);
  };
  const handleSignatureChange = (signatureDataURL: string) => {
    rentalAgreementForm.setFieldsValue({ signature: signatureDataURL });
  };
  const onBack = () => navigate(backLink(id || ''));
  if (isInitialLoading) return <LargeLoading />;
  if (error)
    return (
      <div className="container-2 p-24 flex flex-column content-center text-center middle gap-16">
        <MyIcon icon="IconErrorInCloud"></MyIcon>
        <h1 className="heading-page-content">{error}</h1>
        <a className="no-underline" href={backLink(id || '')}>
          <button className="btn-secondary flex middle"> Go back</button>
        </a>
      </div>
    );

  return (
    <>
      {isSubmissionLoading && <LargeLoading customText="Just a moment, we’re saving the rental agreement..." />}
      <SecondaryNavigation
        menuTitle={'Rental Agreement'}
        onBack={onBack}
        bannerNode={
          showBanner && (
            <PoweredByCarbizBanner appName={`${process.env.REACT_APP_MANUFACTURER_MAKE_NAME?.toUpperCase()} loan cars`} />
          )
        }
      />
      <div className="container-2  pb-24 px-24 md-w-fix">
        <div className="flex gap-16 flex-column">
          <CollapsibleForm
            expanded={currentForm === 1}
            allFieldsCompleted={currentForm > 1}
            iconName="IconRaRentals"
            title="1. Rental Vehicle"
            form={rentalVehicleForm}
            loading={isRentalVehicleLoading}
            initialValues={{
              rego: bookingRecord.vehicleRego,
              make: bookingRecord.vehicleMake,
              model: bookingRecord.vehicleModel,
              dropOffLocation: userData?.companyName || bookingRecord?.dropOffAddress,
            }}
            subForm={
              <RentalVehicle
                setGetCaptureImages={setGetCaptureImages}
                isRentalVehicleLoading={isRentalVehicleLoading}
                hasCarSeats={isCarbiz}
              />
            }
            onNext={() => handleRentalVehicleNext()}
          />
          <CollapsibleForm
            expanded={currentForm === 2}
            allFieldsCompleted={currentForm > 2}
            iconName="IconRaDrivers"
            title="2. Drivers"
            form={driverForm}
            initialValues={{
              firstName: bookingRecord.customerFirstName,
              lastName: bookingRecord.customerLastName,
              mobilePhone: bookingRecord.customerMobile,
              email: bookingRecord.customerEmail,
            }}
            subForm={<MultipleDrivers />}
            onNext={() => handleNext(driverForm)}
            onBack={handleBack}
          />
          {isCarbiz && (
            <CollapsibleForm
              expanded={currentForm === 3}
              allFieldsCompleted={currentForm > 3}
              iconName="IconEmbeddedVehicles"
              title="3. Vehicle Owner"
              form={ownerForm}
              subForm={<VehicleOwner />}
              onNext={() => handleNext(ownerForm)}
              onBack={handleBack}
            />
          )}
          <CollapsibleForm
            expanded={currentForm === 3 + (isCarbiz ? 1 : 0)}
            allFieldsCompleted={currentForm > 3 + (isCarbiz ? 1 : 0)}
            iconName="IconRaAgreement"
            title={`${3 + (isCarbiz ? 1 : 0)}. Rental Agreement`}
            form={rentalAgreementForm}
            subForm={
              <RentalAgreement
                isVisible={currentForm === 3 + (isCarbiz ? 1 : 0)}
                onSignatureChange={handleSignatureChange}
                AgreementDescription={AgreementDescription}
                showExcessReduction={isCarbiz}
                showMarketingAndCommunication={isCarbiz}
              />
            }
            onNext={() => handleNext(rentalAgreementForm)}
            onBack={handleBack}
          />
          <CollapsibleForm
            expanded={currentForm === 4 + (isCarbiz ? 1 : 0)}
            allFieldsCompleted={currentForm > 4 + (isCarbiz ? 1 : 0)}
            iconName="IconRaPreauth"
            title={`${4 + (isCarbiz ? 1 : 0)}.  $1 Temporary Hold`}
            form={preAuthForm}
            subForm={
              <PreAuth
                isPreAuthData={isPreAuthData}
                isPreAuthLoading={isPreAuthLoading}
                stripeErrorMessage={stripeErrorMessage}
                handleError={handleErrorChange}
              />
            }
            onNext={handlePreAuthNext}
            onBack={handleBack}
            loading={isButtonDisabled}
          />
        </div>
      </div>
    </>
  );
}

export default CreateRentalAgreement;
