import { ISelectOption, IUser } from '@scanny-app/loopy-loop';

interface FilterDealershipProps {
  value: string;
  dealershipOptions: ISelectOption[];
  setSelectedDealership: (value: string[] | undefined) => void;
  setReloadDealership: (value: boolean) => void;
}

interface initialValueDealershipProps {
  isManufacturer: boolean;
  userData: IUser | null;
  storeDealership: string | null;
  selectedDealership: string[] | undefined;
}

export const filterDealership = ({
  value,
  dealershipOptions,
  setSelectedDealership,
  setReloadDealership,
}: FilterDealershipProps) => {
  const parseValue = JSON.parse(value) as string[];
  const dealershipMapping = parseValue.map((val) => dealershipOptions.find((option) => option.value === val));
  const idsDealership = dealershipMapping.map((obj) => obj?.value) as string[];

  if (parseValue.length === 0) {
    localStorage.removeItem('dealership');
    setSelectedDealership(undefined);
  } else {
    localStorage.setItem('dealership', JSON.stringify(idsDealership));
    setSelectedDealership(idsDealership);
  }

  if (parseValue !== idsDealership) {
    setReloadDealership(true);
  }
};

export const initialValueDealership = ({
  isManufacturer,
  userData,
  storeDealership,
  selectedDealership,
}: initialValueDealershipProps) => {
  let defaultValue;
  if (isManufacturer) {
    if (storeDealership) {
      if (selectedDealership) {
        defaultValue = selectedDealership;
      } else {
        defaultValue = JSON.parse(storeDealership);
      }
    } else {
      defaultValue = [];
    }
  } else {
    defaultValue = JSON.stringify(userData?.currentCompany);
  }

  return defaultValue;
};
