import { createContext } from 'react';
import { ISelectOption } from './SelectOption';

export interface IDealership {
  id: string;
  name: string;
}

interface DealershipContextProps {
  dealershipOptions: ISelectOption[];
  setReloadDealership: (value: boolean) => void;
}

export const DealershipContext = createContext<DealershipContextProps>({
  dealershipOptions: [],
  setReloadDealership: () => {},
});
