import { message } from 'antd';

interface CompressImageCallback {
  (compressedFile: File): void;
}

const compressImage = (
  file: File,
  quality: number,
  maxWidth: number,
  maxHeight: number,
  callback: CompressImageCallback,
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event: ProgressEvent<FileReader>) => {
    const img = new Image();
    img.src = event.target!.result as string;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;

      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = img.width * ratio;
      const height = img.height * ratio;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob: Blob | null) => {
          if (blob) {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            callback(compressedFile);
          } else {
            message.error('Compression failed.');
          }
        },
        file.type,
        quality,
      );
    };
    img.onerror = () => {
      message.error('There was an error processing the image');
    };
  };
  reader.onerror = () => {
    message.error('There was an error reading the file');
  };
};

export { compressImage };
