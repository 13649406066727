import { formatISO, MyIcon, DefaultCard, EmptyCard, IBooking, IRentalRequest, CustomButton } from '@scanny-app/loopy-loop';

interface OverviewReturnCarProps {
  rentalRequestData: IRentalRequest;
  mostRecentBooking: IBooking;
  needsRentalAgreement: boolean;
  setViewReturnModalVisible: (value: boolean) => void;
}

function OverviewReturnCar({
  rentalRequestData,
  mostRecentBooking,
  needsRentalAgreement,
  setViewReturnModalVisible,
}: OverviewReturnCarProps) {
  return (
    <div>
      {rentalRequestData.finishDate === null &&
      new Date(rentalRequestData.hireStartDate) < new Date() &&
      rentalRequestData.bookingStatus === 'In Progress' &&
      mostRecentBooking &&
      !needsRentalAgreement ? (
        <CustomButton
          className={'on-hire-lg w-full content-center h-45'}
          onClick={() => setViewReturnModalVisible(true)}
          btnName={'Return Car'}
          iconName={'IconReturn'}
        />
      ) : rentalRequestData.finishDate !== null ? (
        <DefaultCard
          className={'content-space-between'}
          content={
            <>
              Return created for {formatISO(rentalRequestData.finishDate).toLocaleUpperCase()}
              <MyIcon icon={'IconReturn'} />
            </>
          }
        />
      ) : (
        <EmptyCard />
      )}
    </div>
  );
}

export default OverviewReturnCar;
