import { Auth } from 'aws-amplify';
import axios from 'axios';

interface QueryProps {
  endpoint: string;
  filter: unknown;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericItem = Record<string, any>;

type LabelFormatter = (item: GenericItem) => string;

const GetOptionsFromDB = async ({ endpoint, filter }: QueryProps, formatLabel: LabelFormatter) => {
  try {
    const data = await Auth.currentSession();
    const token = data.getAccessToken().getJwtToken();
    const url = `${endpoint}`;

    const response = await axios.post(url, JSON.stringify(filter), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.data !== null) {
      const optionsReturned = response.data.map((item: GenericItem) => ({
        label: formatLabel(item),
        value: item.id,
      }));
      return optionsReturned;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export { GetOptionsFromDB };
