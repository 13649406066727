import { Form, message, Input, Button } from 'antd';
import { useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  RentalVehicle,
  ICapturedImage,
  IBooking,
  IRentalRequest,
  SecondaryNavigation,
  SmallLoading,
} from '@scanny-app/loopy-loop';
import SignatureCanvas from 'react-signature-canvas';

interface ChecklistReturnCarProps {
  bookings: IBooking[];
  rentalRequestData: IRentalRequest;
  setViewChecklistCar: (value: boolean) => void;
  setReloadDataRentalRequest: (value: boolean) => void;
}

function ChecklistReturnCar({
  bookings,
  rentalRequestData,
  setViewChecklistCar,
  setReloadDataRentalRequest,
}: ChecklistReturnCarProps) {
  const [checklistReturnCarForm] = Form.useForm();
  const [getCaptureImages, setGetCaptureImages] = useState<ICapturedImage[]>();
  const [isRentalVehicleLoading, setIsRentalVehicleLoading] = useState(false);
  const [isChecklistData, setIsChecklistData] = useState(false);
  const bookingId = bookings[0].id;
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const handleEndDraw = () => {
    if (signatureRef && signatureRef.current) {
      const signatureDataURL = signatureRef.current?.toDataURL();
      checklistReturnCarForm.setFieldsValue({ customerSignature: signatureDataURL });
    }
  };
  const clearSignature = () => {
    signatureRef.current?.clear();
    checklistReturnCarForm.setFieldsValue({ customerSignature: '' });
  };
  const handleSubmit = () => {
    if (!isChecklistData) {
      checklistReturnCarForm.setFieldsValue({
        captureCarImages: getCaptureImages,
      });
      const checklistData = checklistReturnCarForm.getFieldsValue();
      checklistReturnCarForm
        .validateFields()
        .then(() => {
          setIsRentalVehicleLoading(true);
          Auth.currentSession()
            .then((data) => {
              const token = data.getAccessToken().getJwtToken();
              return axios.post(
                `${process.env.REACT_APP_ENDPOINT_URL}/create/Checklist`,
                JSON.stringify({
                  bookingId: bookingId,
                  checklistType: 'In',
                  fuel: checklistData.fuelOut,
                  files: getCaptureImages,
                  customerFirstName: checklistData.customerFirstName,
                  customerLastName: checklistData.lastName,
                  customerSignature: checklistData.customerSignature,
                }),
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
            })
            .then(() => {
              message.success('Checklist Successful');
              setIsChecklistData(true);
              setIsRentalVehicleLoading(false);
              setViewChecklistCar(false);
              setReloadDataRentalRequest(true);
            })
            .catch(() => {
              message.error('Something went wrong with the checklist update');
              setIsRentalVehicleLoading(false);
            });
        })
        .catch(() => {
          message.error('Please complete all fields');
          setIsRentalVehicleLoading(false);
        });
    }
  };

  const rightNode = (
    <button className="btn-submit align-middle" onClick={handleSubmit} disabled={isRentalVehicleLoading}>
      Submit
    </button>
  );

  return (
    <>
      <SecondaryNavigation menuTitle={'Checklist Car'} rightNode={rightNode} onBack={() => setViewChecklistCar(false)} />
      <div className="container-2 p-24 md-w-fix">
        {isRentalVehicleLoading ? (
          <div className="py-24">
            <SmallLoading customText="Checklist in progress. Do not refresh or navigate away." />
          </div>
        ) : (
          <Form
            form={checklistReturnCarForm}
            layout="vertical"
            className="w-full form-style-one flex gap-16 flex-column"
            initialValues={{
              rego: rentalRequestData.hireVehicleRego,
              make: rentalRequestData.hireVehicleMakeName,
              model: rentalRequestData.hireVehicleModelName,
              dropOffLocation: rentalRequestData.submittedByCompanyName,
            }}
          >
            <RentalVehicle setGetCaptureImages={setGetCaptureImages} isRentalVehicleLoading={isRentalVehicleLoading} />
            <div className="border-t-dashed">
              <h4 className="text-sm">Customer Signature</h4>
              <div className="mb-24 w-full gap-16 grid grid-cols-2 md-grid-cols-1">
                <Form.Item
                  name="customerFirstName"
                  label="Customer First Name"
                  rules={[{ required: true, message: 'Please enter the customer first name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Customer Last Name"
                  rules={[{ required: true, message: 'Please enter the customer last name' }]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Form.Item
                label="Signature"
                name="customerSignature"
                rules={[{ required: true, message: 'Please provide your signature.' }]}
              >
                <SignatureCanvas
                  ref={signatureRef}
                  onEnd={handleEndDraw}
                  canvasProps={{
                    width: 277,
                    height: 140,
                    className: 'box-1',
                  }}
                />
              </Form.Item>

              <Button onClick={clearSignature} className="cust-btn" style={{ width: '150px' }}>
                Clear Signature
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default ChecklistReturnCar;
