export const formatISO = (dateISOString: string, timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  if (!dateISOString) return '';
  return new Date(dateISOString).toLocaleString('en-AU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZone,
  });
};
export const formatTime = (dateISOString: string, timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  if (!dateISOString) return '';
  return new Date(dateISOString).toLocaleString('en-AU', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZone,
  });
};
