export interface IAllStatus {
  Rejected: number;
  Pending: number;
  Approved: number;
}

export interface IAllBooking {
  'In Progress': number;
  'No booking': number;
  Scheduled: number;
  Completed: number;
}
export interface IStringKeyNum {
  [key: string]: number;
}
export interface ICollectDataDashboard {
  name: string;
  value: number | IAllBooking | IAllStatus | boolean;
}

export interface IBookingResponse {
  Booking_Status__c: string;
  Hire__r: IDaysOnHire;
}

interface IDaysOnHire {
  Days_on_Hire__c: number;
}
export interface IAllStatusResponse {
  Status__c: string;
  expr0: number;
}

export interface IReferralResponse {
  Accident_Stage__c: string;
  expr0: number;
}

export interface IDashboard {
  numberReachingDayLimit: number;
  totalRentalRequests: number;
  allStatusCount: IAllStatus;
  allBookingCount: IAllBooking;
  numberOutstandingTotal: number;
  invoicesReceived: number;
  totalDaysOnHire?: number;
  currentHireDays?: number;
  hireDaysInvoiced?: number;
  collectionDataLoading: boolean;
}
export const EmptyDashboard: IDashboard = {
  numberReachingDayLimit: 0,
  totalRentalRequests: 0,
  allStatusCount: { Rejected: 0, Pending: 0, Approved: 0 },
  allBookingCount: { 'In Progress': 0, 'No booking': 0, Scheduled: 0, Completed: 0 },
  numberOutstandingTotal: 0,
  invoicesReceived: 0,
  collectionDataLoading: true,
};
