import { MyIcon } from '@scanny-app/loopy-loop';

interface WarningMessageProps {
  onClickAction: () => void;
  messageText: string;
  actionText: string;
}

function WarningMessage({ onClickAction, messageText, actionText }: WarningMessageProps) {
  return (
    <>
      <div className="flex flex-row md-flex-column gap-12 box-warning content-space-between">
        <div>{messageText}</div>
        <button className="warning-btn btn-transp gap-8 flex middle icon-xs" onClick={onClickAction}>
          {actionText}
          <MyIcon icon={'IconNext'} />
        </button>
      </div>
    </>
  );
}

export default WarningMessage;
