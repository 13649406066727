import { IRentalRequest, IComment, formatISO } from '@scanny-app/loopy-loop';

interface CardCommentProps {
  rentalRequestData: IRentalRequest;
  commentData: IComment[];
}

function CardComment({ rentalRequestData, commentData }: CardCommentProps) {
  return (
    <>
      {commentData &&
        commentData.map((item, index) => {
          const isDealer = item.submittedByCompany !== rentalRequestData.submittedToCompany;

          return (
            <div key={index} className={`box-1-grey p-16 flex flex-column gap-8 text-xs`}>
              <div className="flex content-space-between md-flex-column">
                <div className="flex gap-8">
                  <span className="font-semibold">{isDealer ? 'Response from:' : 'Further details requested:'}</span>
                  {item.submittedByName} / {item.submittedByCompanyName}
                </div>
                <div>{formatISO(item.createdDate).toUpperCase()}</div>
              </div>
              <div className="italic mt-4">{item.body}</div>
            </div>
          );
        })}
    </>
  );
}

export default CardComment;
