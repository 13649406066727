import { Form, DatePicker, Modal, message } from 'antd';
import { useState } from 'react';
import { disablePastDatesBasedOnTime, MyIcon } from '@scanny-app/loopy-loop';

export interface IEstimatedFinishDateForm {
  estimatedFinishDate: string;
}

interface EstimatedFinishDateProps {
  open: boolean;
  onCancel: () => void;
  onEstSubmit: (values: IEstimatedFinishDateForm) => void;
}

const EstimatedFinishDate: React.FC<EstimatedFinishDateProps> = ({ open, onCancel, onEstSubmit }) => {
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(false);

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        setDisableButton(true);
        const formattedValues = {
          estimatedFinishDate: form.getFieldsValue().estimatedFinishDate.format('YYYY-MM-DD'),
        };

        onEstSubmit(formattedValues);
        form.resetFields();
      })
      .catch(() => {
        message.error('Please review field errors');
        setDisableButton(false);
      });
  };

  const modalTitle = (
    <div className="w-full flex flex-row gap-8 middle">
      <MyIcon icon="IconStars" />
      <h3 className="m-0 text-sm">Edit estimated finish date</h3>
    </div>
  );
  return (
    <Modal
      open={open}
      title={modalTitle}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
      className="custom-modal left-menu"
      width={400}
      centered
    >
      <Form form={form} disabled={disableButton} className="w-full form-style-one" layout="vertical">
        <Form.Item
          name="estimatedFinishDate"
          label="Select a new date"
          rules={[{ required: true, message: 'Please select the estimated finish date' }]}
        >
          <DatePicker className="box" format="DD/MM/YYYY" placeholder="Select date" disabledDate={disablePastDatesBasedOnTime} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EstimatedFinishDate;
