export const capitalizeWords = (text: string, justFirstLetter: boolean = false) => {
  if (!text) return '';
  if (justFirstLetter) {
    return text
      .split(' ') // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' ');
  }
  return text
    .split(' ') // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Rejoin the words back into a single string
};
