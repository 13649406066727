import { ReactComponent as IconDashboard } from './assets/IconDashboard.svg';
import { ReactComponent as IconDamageDetection } from './assets/IconDamageDetection.svg';
import { ReactComponent as IconCarRentals } from './assets/IconCarRentals.svg';
import { ReactComponent as IconValuation } from './assets/IconValuation.svg';
import { ReactComponent as IconModuleMarketplace } from './assets/IconModuleMarketplace.svg';
import { ReactComponent as IconSystemSettings } from './assets/IconSystemSettings.svg';
import { ReactComponent as IconRentalRequest } from './assets/IconRentalRequest.svg';
import { ReactComponent as IconBack } from './assets/IconBack.svg';
import { ReactComponent as IconForward } from './assets/IconForward.svg';
import { ReactComponent as IconApproved } from './assets/IconApproved.svg';
import { ReactComponent as IconDecline } from './assets/IconDecline.svg';
import { ReactComponent as IconStars } from './assets/IconStars.svg';
import { ReactComponent as IconMenu } from './assets/IconMenu.svg';
import { ReactComponent as IconExport } from './assets/IconDownload.svg';
import { ReactComponent as IconCalendar } from './assets/IconCalendar.svg';
import { ReactComponent as IconPreAuth } from './assets/IconPreauth.svg';
import { ReactComponent as IconReturn } from './assets/IconReturn.svg';
import { ReactComponent as IconNext } from './assets/IconNext.svg';
import { ReactComponent as IconPending } from './assets/IconPending.svg';
import { ReactComponent as IconAdd } from './assets/IconAdd.svg';
import { ReactComponent as IconRaDrivers } from './assets/IconRaDrivers.svg';
import { ReactComponent as IconRaAgreement } from './assets/IconRaAgreement.svg';
import { ReactComponent as IconRaPreauth } from './assets/IconRaPreauth.svg';
import { ReactComponent as IconRaRentals } from './assets/IconRaRentals.svg';
import { ReactComponent as IconCircle } from './assets/IconCircle.svg';
import { ReactComponent as IconCircleCompleted } from './assets/IconCircleCompleted.svg';
import { ReactComponent as IconUpload } from './assets/IconUpload.svg';
import { ReactComponent as IconUploadCloud } from './assets/IconUploadCloud.svg';
import { ReactComponent as IconRemove } from './assets/IconRemove.svg';
import { ReactComponent as IconWarning } from './assets/IconWarning.svg';
import { ReactComponent as IconPDF } from './assets/IconPDF.svg';
import { ReactComponent as IconLogout } from './assets/IconLogout.svg';
import { ReactComponent as IconTab } from './assets/IconNewTab.svg';
import { ReactComponent as IconClose } from './assets/IconClose.svg';
import { ReactComponent as IconRetake } from './assets/IconRetake.svg';
import { ReactComponent as IconChecklistCar } from './assets/IconChecklistCar.svg';
import { ReactComponent as IconExpand } from './assets/IconExpand.svg';
import { ReactComponent as IconCollapse } from './assets/IconCollapse.svg';
import { ReactComponent as IconKebabMenu } from './assets/IconKebabMenu.svg';
import { ReactComponent as IconSwapCar } from './assets/IconSwapCar.svg';
import { ReactComponent as IconNotePad } from './assets/IconNotePad.svg';
import { ReactComponent as IconLock } from './assets/IconLock.svg';
import { ReactComponent as IconUpsideDownCar } from './assets/IconUpsideDownCar.svg';
import { ReactComponent as IconConfusedCar } from './assets/IconConfusedCar.svg';
import { ReactComponent as IconShadow } from './assets/IconShadow.svg';
import { ReactComponent as IconProoftecLogo } from './assets/IconProoftecLogo.svg';
import { ReactComponent as IconStripe } from './assets/IconStripe.svg';
import { ReactComponent as IconProoftec } from './assets/IconProoftec.svg';
import { ReactComponent as IconErrorInCloud } from './assets/IconErrorInCloud.svg';
import { ReactComponent as IconClipboardInCloud } from './assets/IconClipboardInCloud.svg';
import { ReactComponent as IconCardInCloud } from './assets/IconCardInCloud.svg';
import { ReactComponent as IconCalendarInCloud } from './assets/IconCalendarInCloud.svg';
import { ReactComponent as IconBarCode } from './assets/IconBarCode.svg';
import { ReactComponent as IconAgreementComplete } from './assets/IconAgreementComplete.svg';
import { ReactComponent as IconAddress } from './assets/IconAddress.svg';
import { ReactComponent as IconCustomer } from './assets/IconCustomer.svg';
import { ReactComponent as IconLocation } from './assets/IconLocation.svg';
import { ReactComponent as IconReferrals } from './assets/IconReferrals.svg';
import { ReactComponent as IconBell } from './assets/IconBell.svg';
import { ReactComponent as IconPayment } from './assets/IconPayment.svg';
import { ReactComponent as IconEmbeddedVehicles } from './assets/IconEmbeddedVehicles.svg';
import { ReactComponent as IconDocumentExport } from './assets/IconExport.svg';
import { ReactComponent as IconDriver } from './assets/IconDriver.svg';
import { ReactComponent as IconChaserVehicle } from './assets/IconChaserVehicle.svg';
import { ReactComponent as IconArrowUp } from './assets/IconArrowUp.svg';
import { ReactComponent as IconCelebration } from './assets/IconCelebration.svg';
import { ReactComponent as IconInfo } from './assets/IconInfo.svg';
import { ReactComponent as IconEdit } from './assets/IconEdit.svg';
import { ReactComponent as IconDot } from './assets/IconDot.svg';
import { ReactComponent as IconOutlineDot } from './assets/IconOutlineDot.svg';
import { ReactComponent as IconCarbiz } from './assets/IconCarbiz.svg';
import { ReactComponent as IconFeedback } from './assets/IconFeedback.svg';

import { IconName } from '@scanny-app/loopy-loop';
interface CustomIconProps {
  /**
   * The icon name of type IconName. Only valid icons denoted in IconName are valid.
   */
  icon: IconName;
  /**
   * Optional fill colour.
   */
  fill?: string;
}
const CustomIcon = ({ icon, fill = 'currentColor' }: CustomIconProps) => {
  // Determine which icon to render based on the "icon" prop
  let IconComponent;

  switch (icon) {
    case 'IconDashboard':
      IconComponent = IconDashboard;
      break;
    case 'IconDamageDetection':
      IconComponent = IconDamageDetection;
      break;
    case 'IconValuation':
      IconComponent = IconValuation;
      break;
    case 'IconModuleMarketplace':
      IconComponent = IconModuleMarketplace;
      break;
    case 'IconCarRentals':
      IconComponent = IconCarRentals;
      break;
    case 'IconSystemSettings':
      IconComponent = IconSystemSettings;
      break;
    case 'IconRentalRequest':
      IconComponent = IconRentalRequest;
      break;
    case 'IconBack':
      IconComponent = IconBack;
      break;
    case 'IconForward':
      IconComponent = IconForward;
      break;
    case 'IconApproved':
      IconComponent = IconApproved;
      break;
    case 'IconDecline':
      IconComponent = IconDecline;
      break;
    case 'IconStars':
      IconComponent = IconStars;
      break;
    case 'IconMenu':
      IconComponent = IconMenu;
      break;
    case 'IconExport':
      IconComponent = IconExport;
      break;
    case 'IconCalendar':
      IconComponent = IconCalendar;
      break;
    case 'IconPreAuth':
      IconComponent = IconPreAuth;
      break;
    case 'IconReturn':
      IconComponent = IconReturn;
      break;
    case 'IconPending':
      IconComponent = IconPending;
      break;
    case 'IconNext':
      IconComponent = IconNext;
      break;
    case 'IconAdd':
      IconComponent = IconAdd;
      break;
    case 'IconRaRentals':
      IconComponent = IconRaRentals;
      break;
    case 'IconRaDrivers':
      IconComponent = IconRaDrivers;
      break;
    case 'IconRaPreauth':
      IconComponent = IconRaPreauth;
      break;
    case 'IconRaAgreement':
      IconComponent = IconRaAgreement;
      break;
    case 'IconCircle':
      IconComponent = IconCircle;
      break;
    case 'IconCircleCompleted':
      IconComponent = IconCircleCompleted;
      break;
    case 'IconUpload':
      IconComponent = IconUpload;
      break;
    case 'IconUploadCloud':
      IconComponent = IconUploadCloud;
      break;
    case 'IconRemove':
      IconComponent = IconRemove;
      break;
    case 'IconWarning':
      IconComponent = IconWarning;
      break;
    case 'IconKebabMenu':
      IconComponent = IconKebabMenu;
      break;
    case 'IconSwapCar':
      IconComponent = IconSwapCar;
      break;
    case 'IconPDF':
      IconComponent = IconPDF;
      break;
    case 'IconLogout':
      IconComponent = IconLogout;
      break;
    case 'IconTab':
      IconComponent = IconTab;
      break;
    case 'IconClose':
      IconComponent = IconClose;
      break;
    case 'IconRetake':
      IconComponent = IconRetake;
      break;
    case 'IconChecklistCar':
      IconComponent = IconChecklistCar;
      break;
    case 'IconCollapse':
      IconComponent = IconCollapse;
      break;
    case 'IconExpand':
      IconComponent = IconExpand;
      break;
    case 'IconNotePad':
      IconComponent = IconNotePad;
      break;
    case 'IconLock':
      IconComponent = IconLock;
      break;
    case 'IconUpsideDownCar':
      IconComponent = IconUpsideDownCar;
      break;
    case 'IconConfusedCar':
      IconComponent = IconConfusedCar;
      break;
    case 'IconShadow':
      IconComponent = IconShadow;
      break;
    case 'IconStripe':
      IconComponent = IconStripe;
      break;
    case 'IconProoftecLogo':
      IconComponent = IconProoftecLogo;
      break;
    case 'IconProoftec':
      IconComponent = IconProoftec;
      break;
    case 'IconErrorInCloud':
      IconComponent = IconErrorInCloud;
      break;
    case 'IconClipboardInCloud':
      IconComponent = IconClipboardInCloud;
      break;
    case 'IconCardInCloud':
      IconComponent = IconCardInCloud;
      break;
    case 'IconCalendarInCloud':
      IconComponent = IconCalendarInCloud;
      break;
    case 'IconBarCode':
      IconComponent = IconBarCode;
      break;
    case 'IconAgreementComplete':
      IconComponent = IconAgreementComplete;
      break;
    case 'IconAddress':
      IconComponent = IconAddress;
      break;
    case 'IconCustomer':
      IconComponent = IconCustomer;
      break;
    case 'IconLocation':
      IconComponent = IconLocation;
      break;
    case 'IconReferrals':
      IconComponent = IconReferrals;
      break;
    case 'IconBell':
      IconComponent = IconBell;
      break;
    case 'IconPayment':
      IconComponent = IconPayment;
      break;
    case 'IconEmbeddedVehicles':
      IconComponent = IconEmbeddedVehicles;
      break;
    case 'IconDocumentExport':
      IconComponent = IconDocumentExport;
      break;
    case 'IconChaserVehicle':
      IconComponent = IconChaserVehicle;
      break;
    case 'IconDriver':
      IconComponent = IconDriver;
      break;
    case 'IconArrowUp':
      IconComponent = IconArrowUp;
      break;
    case 'IconCelebration':
      IconComponent = IconCelebration;
      break;
    case 'IconInfo':
      IconComponent = IconInfo;
      break;
    case 'IconEdit':
      IconComponent = IconEdit;
      break;
    case 'IconDot':
      IconComponent = IconDot;
      break;
    case 'IconOutlineDot':
      IconComponent = IconOutlineDot;
      break;
    case 'IconCarbiz':
      IconComponent = IconCarbiz;
      break;
    case 'IconFeedback':
      IconComponent = IconFeedback;
      break;
    // Handle other icons here
    default:
      IconComponent = null;
  }
  return IconComponent ? <IconComponent fill={fill} /> : null;
};

export default CustomIcon;
