import { Progress } from 'antd';
import { MyIcon } from '@scanny-app/loopy-loop';
import { useNavigate } from 'react-router-dom';

interface PercentagePieChartProps {
  total: number;
  count: number;
  title: string;
  link: string;
  isLoading: boolean;
  conicColors: Record<string, string>;
  session?: boolean;
}

const PercentagePieChart: React.FC<PercentagePieChartProps> = ({ total, count, title, link, isLoading, conicColors }) => {
  const navigate = useNavigate();

  const onCLick = () => {
    navigate(link);
    localStorage.setItem('filter', JSON.stringify([title]));
  };

  return (
    <div className="w-full flex flex-column gap-16 custom-progress">
      <Progress
        type="circle"
        size={144}
        percent={(count / total) * 100}
        format={() => (isLoading ? '' : count.toString())}
        strokeColor={conicColors}
        strokeWidth={10}
      />

      <div className="flex flex-row gap-12 middle content-center hover-pointer" onClick={onCLick}>
        <h6 className="text-center text-sm m-0 font-normal">{title}</h6>

        <MyIcon icon="IconForward" fill="none" />
      </div>
    </div>
  );
};

export default PercentagePieChart;
