import { Col, Row } from 'antd';
import FormUpdateVOR from '../OverviewElement/FormUpdateVOR';
import { useContext } from 'react';
import { UserContext, formatISO, ApprovalStatusComponent, IRentalRequest } from '@scanny-app/loopy-loop';
import FormUpdateHotline from '../OverviewElement/FormUpdateHotline';

interface RequestDetailsProps {
  rentalRequestData: IRentalRequest;
  VORPass: [
    setReloadDataDashboard: (value: boolean) => void,
    setReloadDataRentalRequest: (value: boolean) => void,
    setIsVORLoading: (value: boolean) => void,
  ];
  HotlinePass: [setReloadDataRentalRequest: (value: boolean) => void, setIsHotlineLoading: (value: boolean) => void];
}
function RequestDetails({ rentalRequestData, VORPass, HotlinePass }: RequestDetailsProps) {
  const userData = useContext(UserContext);
  const isDealer = userData?.profileType === 'Dealer';

  return (
    <div className="flex flex-column gap-16">
      <div className={'flex content-space-between'}>
        <h5 className="text-sm m-0">Request details</h5>
      </div>
      <Row className="form-style-one" gutter={[16, 16]}>
        <Col span={24}>
          <div className="label">Dealer Comments</div>
          <div className="box bg-box italic">{rentalRequestData.comments}</div>
        </Col>
        <Col span={12}>
          <div className="label">Repair Order Number</div>
          <div className="box bg-box">{rentalRequestData.repairOrderNumber}</div>
        </Col>
        <Col span={12}>
          <div className="label">Purchase Order Number</div>
          <div className="box bg-box">{rentalRequestData.purchaseOrderNumber}</div>
        </Col>
        <Col span={12}>
          <div className="label">Hotline Number</div>
          {rentalRequestData.hotlineNumber === null && isDealer ? (
            <FormUpdateHotline rentalRequestData={rentalRequestData} HotlinePass={HotlinePass} />
          ) : (
            <div className="box bg-box">{rentalRequestData.hotlineNumber}</div>
          )}
        </Col>
        <Col span={12}>
          <div className="label">VOR Number</div>
          {rentalRequestData.VORNumber === null && rentalRequestData.status !== 'Rejected' && isDealer ? (
            <FormUpdateVOR rentalRequestData={rentalRequestData} VORPass={VORPass} />
          ) : (
            <div className="box bg-box">{rentalRequestData.VORNumber}</div>
          )}
        </Col>
        <Col span={12}>
          <div className="label">First Name</div>
          <div className="box bg-box">{rentalRequestData.customerFirstName}</div>
        </Col>
        <Col span={12}>
          <div className="label">Last Name</div>
          <div className="box bg-box">{rentalRequestData.customerLastName}</div>
        </Col>
        <Col span={12}>
          <div className="label">Contact Number</div>
          <div className="box bg-box">{rentalRequestData.customerContactNumber}</div>
        </Col>
        <Col span={12}>
          <div className="label">Contact Email</div>
          <div className="box bg-box">{rentalRequestData.customerEmail}</div>
        </Col>
        <Col span={12}>
          <div className="label">Car Registration</div>
          <div className="box bg-box">{rentalRequestData.vehicleRego}</div>
        </Col>
        <Col span={12}>
          <div className="label">VIN</div>
          <div className="box bg-box">{rentalRequestData.vehicleVIN}</div>
        </Col>

        <Col span={12}>
          <div className="label">Car Make</div>
          <div className="box bg-box">{rentalRequestData.makeName}</div>
        </Col>
        <Col span={12}>
          <div className="label">Car Model</div>
          <div className="box bg-box">{rentalRequestData.modelName}</div>
        </Col>
      </Row>
    </div>
  );
}

interface CardApprovalRequestProps {
  rentalRequestData: IRentalRequest;
  onClickTab: (value: string) => void;
}

function CardApprovalRequest({ rentalRequestData, onClickTab }: CardApprovalRequestProps) {
  let boxColor = 'box-1';
  let bgColor = 'bg-grey-1';
  let borderBottom = 'border-b';
  if (rentalRequestData.status === 'Pending') {
    boxColor = 'box-1-blue';
    bgColor = 'bg-blue';
    borderBottom = 'border-b-blue';
  } else if (rentalRequestData.status === 'Approved') {
    boxColor = 'box-1-green';
    bgColor = 'bg-green';
    borderBottom = 'border-b-green';
  } else if (rentalRequestData.status === 'Rejected') {
    boxColor = 'box-1-red';
    bgColor = 'bg-red';
    borderBottom = 'border-b-red';
  }

  return (
    <div className={`flex flex-column overflow-hidden ${boxColor}`}>
      <div className={`p-16 content-space-between flex middle ${borderBottom} ${bgColor}`}>
        <div className="text-sm font-semibold">
          {rentalRequestData.customerFirstName} {rentalRequestData.customerLastName} -{' '}
          {formatISO(rentalRequestData.createdDate).toUpperCase()}
        </div>

        <div className="flex flex-row middle gap-16">
          <ApprovalStatusComponent status={rentalRequestData.status} />
          {rentalRequestData.status === 'Pending' && (
            <button className="view-btn cust-btn" onClick={() => onClickTab('2')}>
              View
            </button>
          )}
        </div>
      </div>

      <div className="p-16 flex flex-column gap-16">
        <div className=" grid grid-cols-3 md-grid-cols-2 text-xs gap-16">
          <div className="flex flex-column gap-8">
            <div>Car Registration</div>
            <div className="font-semibold">{rentalRequestData.vehicleRego}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>VIN Number</div>
            <div className="font-semibold">{rentalRequestData.vehicleVIN}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Dealership </div>
            <div className="font-semibold">{rentalRequestData.submittedByCompanyName}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Car Make</div>
            <div className="font-semibold">{rentalRequestData.makeName}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>Car Model</div>
            <div className="font-semibold">{rentalRequestData.modelName}</div>
          </div>

          <div className="flex flex-column gap-8 ">
            <div>VOR Number</div>
            <div className="font-semibold">{rentalRequestData.VORNumber ? rentalRequestData.VORNumber : '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { RequestDetails, CardApprovalRequest };
