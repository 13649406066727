import { Radio, RadioChangeEvent, Form, Checkbox, Input, Upload } from 'antd';
import { useState } from 'react';
import { compressImage, convertToBase64, DriverForm, MyIcon } from '@scanny-app/loopy-loop';

function VehicleOwner() {
  const [value, setValue] = useState<'Hirer' | 'Other Individual' | 'Company'>('Hirer');
  const [regoPapersBase64, setRegoPapersBase64] = useState<string>('');
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleBeforeUploadIma0ge = async (file: File) => {
    compressImage(file, 0.6, 1920, 1080, async (compressedFile: File) => {
      const base64String = await convertToBase64(compressedFile);
      setRegoPapersBase64(base64String);
    });
    return false;
  };
  return (
    <div className="gap-16 grid grid-cols-1 pb-16">
      <Form.Item name="ownershipAssertion" label="Please select ownership type">
        <Radio.Group className="flex-column font-normal flex" onChange={onChange} value={value}>
          <Radio value="Hirer">I am the owner</Radio>
          <Radio value="Other Individual">The vehicle is owned by another individual</Radio>
          <Radio value="Company">The vehicle is owned by a company or other organisation</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={'regoPapers'} label="Upload Rego Papers">
        <Upload
          className="driver-licence"
          beforeUpload={handleBeforeUploadIma0ge}
          maxCount={2}
          accept=".jpeg,.jpg,.png,.gif,.bmp,.webp"
          onRemove={() => setRegoPapersBase64('')}
        >
          {regoPapersBase64 !== '' ? null : (
            <button type="button" className="btn-outline w-full content-space-between flex middle">
              Upload Photo
              <MyIcon icon={'IconUpload'} />
            </button>
          )}
        </Upload>
      </Form.Item>
      {regoPapersBase64 !== '' && (
        <Form.Item name={'regoPapersBase64'} initialValue={regoPapersBase64} hidden>
          <Input />
        </Form.Item>
      )}
      {value === 'Other Individual' && (
        <DriverForm name={'vehicleOwner'} index={0} handleRemove={() => {}} fieldsOptional></DriverForm>
      )}
      {value === 'Company' && (
        <div className="gap-16 grid">
          <Form.Item
            label="Company or organisation name"
            name="companyName"
            rules={[
              {
                required: true,
                message: 'Please enter the company or organisation name',
              },
            ]}
          >
            <Input className="capitalize" />
          </Form.Item>
          <Form.Item
            className="box-1 px-12 py-8"
            name="agreeBehalfOfcompany"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: '',
              },
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('You must agree to act on behalf of the company or organisation')),
              },
            ]}
          >
            <Checkbox className="font-normal">
              <span className="required-checkbox-label">I am authorised to act on behalf of this company or organisation</span>
            </Checkbox>
          </Form.Item>
        </div>
      )}
    </div>
  );
}

export default VehicleOwner;
