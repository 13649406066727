const KAUAgreementDescription = () => {
  return (
    <>
      <div style={{ fontWeight: 700, fontSize: 14, marginTop: 12 }}>VEHICLE RENTAL AGREEMENT</div>
      <table className="rental-agreements" border={0}>
        <tbody>
          <tr>
            <td style={{ fontWeight: 700 }} colSpan={2}>
              IMPORTANT REQUIREMENTS
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must report any defect in, or damage to, the hire vehicle within 24 hours after its occurrence or
              detection and provide Carbiz with all information and documents reasonably required by Carbiz in relation to that
              defect or damage (including, if applicable, any police report).
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              Should the Hirer fail to comply with any term of this Agreement, all losses and damages suffered by Carbiz arising
              out of such failure will be borne by, and paid for, by the Hirer, except to the extent such loss or damage is caused
              by Carbiz.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              It is the Hirer’s responsibility to maintain the necessary tyre pressure, engine oils, brake fluids and engine
              coolant levels of the hire vehicle according to the manufacturer's specifications set out in the manual for the Hire
              Vehicle. The Hirer must reimburse Carbiz for any reasonable costs incurred by Carbiz arising out of the Hirer’s
              failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              Should any defect or malfunction of the hire vehicle occur, or if there is any sign of overheating or other fault,
              the Hirer must stop using the vehicle immediately. The Hirer must reimburse Carbiz for any reasonable costs incurred
              by Carbiz arising out of the Hirer’s failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must return the hire vehicle clean and tidy and free of smoking odours, and otherwise in the state of
              repair and condition that this Agreement requires the Hirer to maintain it. The Hirer must reimburse Carbiz for any
              reasonable costs incurred by Carbiz arising out of the Hirer’s failure to do so.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              The Hirer must pay for all fuel or electric charge (as applicable) required to operate the hire vehicle. The hire
              vehicle must be returned with the same amount of fuel or the electric charge level (as applicable) as at the time of
              handover of the vehicle to the Hirer. If the vehicle is returned with less fuel, Carbiz will charge the difference
              at $3.50 (plus GST) per litre. If the vehicle is returned with a lower electric charge level, Carbiz will charge the
              difference at $0.80 (plus GST) per kWh.
            </td>
          </tr>
          <tr>
            <td>•</td>
            <td>
              Unless clause 5.1 or clause 5.2 applies, the Hirer is not liable for loss or damage to the Hire Vehicle or to
              compensate Carbiz for any insurance excess or for loss and damage or injury to any third party caused by its use and
              operation of the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>1.</td>
            <td style={{ fontWeight: 700 }}>PREAMBLE</td>
          </tr>
          <tr>
            <td>1.1</td>
            <td>
              In consideration of Kia Australia Ltd (ACN 110 483 353) (Kia) agreeing to pay hire costs of the vehicle listed on
              page 1 of this Agreement (Hire Vehicle), Carbiz enters into this Agreement with the Hirer at Kia’s request.
            </td>
          </tr>
          <tr>
            <td>1.2</td>
            <td>
              In entering into this Agreement, the Hirer warrants that it is the owner or bailee of a vehicle that is subject to a
              warranty claim approved by Kia (Warranty Vehicle).
            </td>
          </tr>
          <tr>
            <td>1.3</td>
            <td>
              The Hirer has requested, and Carbiz has agreed to provide, the vehicle listed on page 1 of this Agreement (Hire
              Vehicle) for the benefit of the Hirer and any additional drivers listed in this Agreement or otherwise approved by
              Carbiz in writing (as updated with the prior written consent of Carbiz from time to time) (Additional Drivers).
            </td>
          </tr>
          <tr>
            <td>1.4</td>
            <td>
              The term of this Agreement begins on the start date listed on page 1 of this this Agreement and, subject to early
              termination in accordance with its terms, ends on the date which is no later than 1 business day after the
              authorised dealer of Kia Australia Ltd (ACN 110 483 353) responsible for arranging warranty repairs to the Warranty
              Vehicle (Kia Dealer) notifies the Hirer that the warranty repairs to the Warranty Vehicle have been completed (Hire
              Period).
            </td>
          </tr>
          <tr>
            <td>1.5</td>
            <td>
              If contact, licence or credit card details of the Hirer or any Additional Drivers change during the Hire Period,
              then the Hirer must promptly provide Carbiz with updated details.
            </td>
          </tr>
          <tr>
            <td>1.6</td>
            <td>
              The Hirer's rights in this Agreement are in addition to any rights the Hirer has under any laws that cannot be
              excluded, restricted or modified by agreement of the parties, including any applicable rights under Schedule 2 of
              the Competition and Consumer Act 2010 (Cth) (Non-Excludable Rights).
            </td>
          </tr>
          <tr>
            <td>1.7</td>
            <td>
              The parties acknowledge and agree that the National Credit Code contained in the National Consumer Credit Protection
              Act 2009 (Cth) does not apply to this Agreement.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>2.</td>
            <td style={{ fontWeight: 700 }}>CARBIZ AND HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>2.1</td>
            <td>Carbiz:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              enters into this Agreement in its own right, or as an agent of, or with the authority from, or otherwise on behalf
              of another person or entity who has the legal title to the Hire Vehicle (as applicable); and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>represents that it has the right to enter into this Agreement.</td>
          </tr>
          <tr>
            <td>2.2</td>
            <td>The Hirer hires the Hire Vehicle pursuant to the terms set out in this Agreement.</td>
          </tr>
          <tr>
            <td>2.3</td>
            <td>
              If Carbiz replaces the Hire Vehicle hired under this Agreement with a substitute vehicle, including without
              limitation under clause 4.10, then:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              the terms of this Agreement will apply to that substitute vehicle as if the details of that substitute vehicle were
              set out in this Agreement; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the parties will be bound by this Agreement as far as it relates to the hire of that substitute vehicle for the
              period of hire of that vehicle.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>3.</td>
            <td style={{ fontWeight: 700 }}>HIRER’S WARRANTY</td>
          </tr>
          <tr>
            <td>3.1</td>
            <td>The Hirer warrants that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>it is physically able to, and legally qualified to, operate the Hire Vehicle; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>it is the owner or bailee of the Damaged Vehicle.</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>4.</td>
            <td style={{ fontWeight: 700 }}>USE OF HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>4.1</td>
            <td>
              The Hirer must maintain the Hire Vehicle in the state of repair and condition in which it was given to the Hirer
              having regard to the damage check sheet on page 1 of this Agreement (Damage Check Sheet) and must ensure that the
              Hire Vehicle is kept reasonably clean and tidy and free of smoking odours.
            </td>
          </tr>
          <tr>
            <td>4.2</td>
            <td>
              The Hirer must keep the Hire Vehicle locked when not in use and must engage any security device fitted or supplied
              with the Hire Vehicle. It is the Hirer's responsibility to protect all personal property in the Hire Vehicle from
              loss or theft, and Carbiz will not be liable to the Hirer for any such loss or theft.
            </td>
          </tr>
          <tr>
            <td>4.3</td>
            <td>
              The Hirer must use all reasonable endeavours to protect the Hire Vehicle against bad weather that can cause damage
              to the Hire Vehicle, including hail, and keep the Hire Vehicle garaged whenever possible.
            </td>
          </tr>
          <tr>
            <td>4.4</td>
            <td>
              The Hirer must maintain the Hire Vehicle's tyre pressure, engine oils, brake fluid and engine coolant levels at the
              proper operating levels according to the manufacturer's specifications set out in the manual for the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td>4.5</td>
            <td>
              The Hirer must supply all fuel or electric charge for the Hire Vehicle during the Hire Period. Except where the Hire
              Vehicle is an electric vehicle, the Hirer must ensure that it refuels the Hire Vehicle with the fuel that is
              appropriate for that vehicle (as noted on the key tag for the Hire Vehicle supplied by Carbiz).
            </td>
          </tr>
          <tr>
            <td>4.6</td>
            <td>
              The Hirer must not, and must not permit anyone to, repair, or perform any work on, the Hire Vehicle, except with the
              prior written consent of Carbiz.
            </td>
          </tr>
          <tr>
            <td>4.7</td>
            <td>
              Only the Hirer and Additional Drivers are permitted to operate the Hire Vehicle, provided that such persons comply
              with the requirements of this Agreement and all applicable laws.
            </td>
          </tr>
          <tr>
            <td>4.8</td>
            <td>
              Despite any other term or provision of this Agreement, none of the following persons may operate the Hire Vehicle at
              any time:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              a person other than the Hirer or an Additional Driver set out in this Agreement, except with the prior written
              consent of Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>a person who holds a learner licence;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              a person who does not hold a current motor vehicle driver’s licence for the particular class of the Hire Vehicle;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              a person whose breath or blood alcohol concentration exceeds the maximum lawful concentration or who is intoxicated
              or who is under the influence of any drug, toxic, or illegal substance;
            </td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              a person who has given Carbiz, or for whom the Hirer has given Carbiz, a false name, age, address or driver’s
              licence details; or
            </td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>a person whose driver’s licence has been cancelled, endorsed or suspended within the last 3 months.</td>
          </tr>
          <tr>
            <td>4.9</td>
            <td>The Hirer must not, and must ensure that any Additional Driver does not:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              use or operate the Hire Vehicle outside of the State that it was hired in without the prior written consent of
              Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              drive the Hire vehicle on any unsealed road or in any off-road conditions, including without limitation fire trails,
              snow, beaches, sand, tracks, rails (other than as cargo), underground mines, mining shafts, tunnels which are not
              public roads, fields and paddocks;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              carry any inflammable, explosive or corrosive materials or carry any animal or pet in the Hire Vehicle, excluding
              guide dogs;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>carry passengers in the Hire Vehicle for hire or rideshare purposes;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>carry passengers in excess of the capacity of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>carry passengers other than in the interior or cab of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(g)</td>
            <td>
              push, propel or tow another vehicle, trailer or any other object using the Hire Vehicle without prior written
              consent of Carbiz;
            </td>
          </tr>
          <tr>
            <td>(h)</td>
            <td>
              use the Hire Vehicle for or in any race, competition, rally, speed or any other trial, contest, motor sport or any
              driver training course or in preparation for any of them;
            </td>
          </tr>
          <tr>
            <td>(i)</td>
            <td>
              use or operate the Hire Vehicle in a reckless or negligent manner or in a way that causes any loss or damage to any
              person or property;
            </td>
          </tr>
          <tr>
            <td>(j)</td>
            <td>
              use or operate the Hire Vehicle whilst the Hirer knows, or should reasonably know, that it is in an unsafe or
              unroadworthy condition;
            </td>
          </tr>
          <tr>
            <td>(k)</td>
            <td>act fraudulently or dishonestly;</td>
          </tr>
          <tr>
            <td>(l)</td>
            <td>smoke in the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(m)</td>
            <td>cause malicious damage to the Hire Vehicle; or</td>
          </tr>
          <tr>
            <td>(n)</td>
            <td>
              use or operate the Hire Vehicle in any way that does not comply with all applicable laws and regulations, including
              without limitation any seat belt and child restraint laws, and conditions of that person’s driver’s licence.
            </td>
          </tr>
          <tr>
            <td>4.10</td>
            <td>
              The Hirer must inform Carbiz of any damage to, or defect in, the Hire Vehicle within 24 hours of its occurrence or
              detection and must provide Carbiz with all information reasonably required by Carbiz in connection with the damage
              or defect (including, if applicable, any police report). After receiving notice of the damage or defect, Carbiz may
              require the Hirer to return the Hire Vehicle to Carbiz or the Kia Dealer. If Carbiz determines, acting reasonably,
              that the Hire Vehicle is damaged or defective, Carbiz must, subject to any other rights it may have under this
              Agreement, provide the Hirer with a replacement vehicle, which, if accepted by the Hirer, will be substituted as the
              Hire Vehicle for the purposes of this Agreement.
            </td>
          </tr>
          <tr>
            <td>4.11</td>
            <td>
              Carbiz will be responsible for any reasonable transportation or moving costs associated with any return of the Hire
              Vehicle under clause 4.10. However, subject to any Non-Excludable Rights, the Hirer must reimburse such costs if
              Carbiz determines, acting reasonably, on inspection of the Hire Vehicle, that the Hire Vehicle is not damaged or
              defective, or that the damage or defect has been caused by the Hirer.
            </td>
          </tr>
          <tr>
            <td>4.12</td>
            <td>
              The Hirer must not sell, transfer, lease or dispose of the Hire Vehicle or any of its parts or give anyone any legal
              or beneficial rights, title or interest in the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>5.</td>
            <td style={{ fontWeight: 700 }}>LIABILITY FOR LOSS OR DAMAGE</td>
          </tr>
          <tr>
            <td>5.1</td>
            <td>
              Subject to any Non-Excludable Rights, the Hirer is liable to compensate Carbiz for all loss or damage to the Hire
              Vehicle and will be liable for any and all loss, damage or injury to any third party arising out of the use or
              operation of the Hire Vehicle during the Hire Period, where the liability arises in connection with:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>tyre damage, including punctures and tread that is worn down beyond fair wear and tear;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>headlight, roof or underbody damage;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>water damage caused by the immersion of the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>interior damage to the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>loss of the key to the Hire Vehicle;</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>key being left in or near the Hire Vehicle whilst it was unattended;</td>
          </tr>
          <tr>
            <td>(g)</td>
            <td>
              the Hirer or any Authorised Driver leaving the scene of a collision or incident before exchanging details with all
              involved parties, except where the Hirer or any Authorised Driver is reasonably prevented from exchanging such
              details because the collision or incident has resulted in any passenger or driver of the Hire Vehicle having to
              attend hospital or engage with law enforcement or any other emergency services;
            </td>
          </tr>
          <tr>
            <td>(h)</td>
            <td>any deliberate, dangerous or reckless conduct by the Hirer and any Authorised Driver; or</td>
          </tr>
          <tr>
            <td>(i)</td>
            <td>the Hirer otherwise breaching this Agreement (including without limitation clause 4).</td>
          </tr>
          <tr>
            <td colSpan={2}>
              If this clause 5.1 applies, then within 7 days after Carbiz's written demand, the Hirer must pay Carbiz the amount
              of such loss or damage.
            </td>
          </tr>
          <tr>
            <td>5.2</td>
            <td>
              Subject to any Non-Excludable Rights, the Hirer is liable for payment of the damage excess in the amount of
              $5,000.00 (plus GST) where any loss or damage to the Hire Vehicle during the Hire Period arises in connection with
              any cause other than set out in clause 5.1 and where:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              the Hire Vehicle was involved in two or more incidents during the Hire Period resulting in loss or damage to the
              Hire Vehicle; or
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Hirer or any Additional Driver holds an overseas driver’s licence and does not hold an Australian driver’s
              licence.
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              If this clause 5.2 applies, then within 7 days after Carbiz’s written demand, the Hirer must pay Carbiz the amount
              of the applicable excess.
            </td>
          </tr>
          <tr>
            <td>5.3</td>
            <td>Other than as set out in clause 5.1 or clause 5.2, the Hirer is not liable for:</td>
          </tr>
          <tr>
            <td>5.4</td>
            <td>(a) any loss or damage to the Hire Vehicle during the Hire Period;</td>
          </tr>
          <tr>
            <td>5.5</td>
            <td>(b) payment of any damage excess to Carbiz; or</td>
          </tr>
          <tr>
            <td>5.6</td>
            <td>
              (c) for any loss, damage or injury to any third party caused by the Hirer’s use or operation of the Hire Vehicle.
            </td>
          </tr>
          <tr>
            <td>5.7</td>
            <td>
              Subject to the terms of this Agreement (including without limitation this clause 5), and to the full extent
              permitted by law, the Hirer releases Carbiz from, and indemnifies Carbiz against, any costs, expenses, fees,
              charges, losses, damages, liability, claims, demands, suits or proceedings incurred or suffered by, or made against,
              Carbiz in connection with the use or operation of the Hire Vehicle during the Hire Period or the Hirer’s breach of
              this Agreement.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>6.</td>
            <td style={{ fontWeight: 700 }}>PAYMENT OF COSTS</td>
          </tr>
          <tr>
            <td>6.1</td>
            <td>Within 7 days after the date of Carbiz’s invoice or demand, the Hirer must pay to Carbiz the following costs:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              daily hire fees in the amount of $92.40 (plus GST) if the Hire Vehicle is an internal engine combustion engine
              vehicle variant or $110.25 (plus GST) if the Hire Vehicle is an electric vehicle variant (as applicable), with such fees
              being payable for each day that the Hirer fails to return the Hire Vehicle to the Kia Dealer as required by clause
              9 of this agreement;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>any amounts that Carbiz determines, acting reasonably, are owed by the Hirer under clause 5;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              any other amount payable by the Hirer under this Agreement for the Hire Period, including without limitation any
              amount payable by the Hirer under clause 7; and
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>
              interest on any amount that remains unpaid after the 7th day after their respective due date for payment. Such
              interest is calculated at the rate of 2% per annum about the average of the most recent prime rate, indicator rate,
              or reference rate (however described) for business overdrafts published by the Commonwealth Bank of Australia, on
              daily rests.
            </td>
          </tr>
          <tr>
            <td>6.2</td>
            <td>
              The Hirer authorises any debit or credit card or any other card or account provided by the Hirer (Hirer’s Card) to
              be securely stored in stripe.com platform or a similar platform in accordance Carbiz’s Privacy Policy.
            </td>
          </tr>
          <tr>
            <td>6.3</td>
            <td>The Hirer authorises Carbiz to:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              charge the Hirer’s Card with any Hire Costs or other amounts due and payable by the Hirer under this Agreement; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              put an authorisation hold on the Hirer’s Card in the amount of $1.00 for a period of 7 days from the available
              balance of the Hirer’s Card as security for the Hirer’s performance of its obligations under this Agreement.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>7.</td>
            <td style={{ fontWeight: 700 }}>TOLLS AND INFRINGEMENTS</td>
          </tr>
          <tr>
            <td>7.1</td>
            <td>
              The Hirer is responsible for payment of all tolls, parking and traffic infringements in the Hire Vehicle for the
              Hire Period.
            </td>
          </tr>
          <tr>
            <td>7.2</td>
            <td>
              If Carbiz receives a toll, infringement or similar notice for the Hire Period, it will nominate the Hirer as the
              person responsible for payment of the relevant notice. If Carbiz is unable, for any reason, to make such nomination,
              then in addition to the amount of that toll or infringement, Carbiz may charge the Hirer an administration fee of:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>$15.00 (plus GST) for a toll notice; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>$50.00 (plus GST) for an infringement notice.</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>8.</td>
            <td style={{ fontWeight: 700 }}>GST</td>
          </tr>
          <tr>
            <td>8.1</td>
            <td>
              In this Agreement the terms defined in A New Tax System (Goods and Services Tax) Act 1999 (Cth) (GST Act) have the
              same meaning as in that legislation.
            </td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>The Hirer acknowledges that:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              the Hire Costs and any fees, charges and other payments referred to in this Agreement (Consideration) do not include
              any GST imposed under the GST Act; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              the Hirer must pay or reimburse Carbiz for any GST and indemnify Carbiz for any GST in addition to the Consideration
              at the same time as the Consideration is payable by the Hirer.
            </td>
          </tr>
          <tr>
            <td>8.3</td>
            <td>
              Carbiz will provide the Hirer with a tax invoice before the Hirer is required to pay or reimburses the GST to
              Carbiz.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>9.</td>
            <td style={{ fontWeight: 700 }}>RETURN OF HIRE VEHICLE</td>
          </tr>
          <tr>
            <td>9.1</td>
            <td>
              The Hirer agrees that the Kia Dealer has a possessory lien over the Warranty Vehicle until the Hire Vehicle is
              returned to the Kia Dealer in accordance with this Agreement.
            </td>
          </tr>
          <tr>
            <td>9.2</td>
            <td>
              The Hirer must return the Hire Vehicle to the Kia Dealer at the address nominated by the Kia Dealer on the earlier
              of the following dates:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>on expiry of the Hire Period;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>on the date of termination of this Agreement; or</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>on the date that this Agreement requires the Hirer to return the Hire Vehicle for any other reason.</td>
          </tr>
          <tr>
            <td>9.3</td>
            <td>
              The Hire Vehicle must be returned to Carbiz clean and tidy and free of smoking odours, and otherwise in the state of
              repair and condition in which it was given to the Hirer, having regard to the Damage Check Sheet, including with all
              of the Hire Vehicle’s original components and accessories. The Hirer must reimburse Carbiz for any reasonable costs
              incurred by Carbiz arising out of the Hirer’s failure to do so. Without limiting these obligation, the Hirer is
              liable for cleaning costs and replacement costs of any missing components, including without limitation any SD card,
              vehicle key, tools, jacking equipment, spare tyres, telematics, roof racks, baby seats and electric vehicle charging
              cables.
            </td>
          </tr>
          <tr>
            <td>9.4</td>
            <td>
              The Hirer must return Hire Vehicle with the same amount of fuel or electric charge level (as applicable) as at the
              time of handover of the Hire Vehicle to the Hirer. If the Hire Vehicle is returned with less:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>fuel, Carbiz will charge the Hirer the difference at $3.50 (plus GST) per litre; or</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>electric charge level, Carbiz will charge the difference at $0.80 (plus GST) per kWh.</td>
          </tr>
          <tr>
            <td>9.5</td>
            <td>
              If the Hirer fails to return the Hire Vehicle to the Kia Dealer by the return date specified under clause 9.1, then
              in addition to its other rights under this Agreement, the Hirer acknowledges and agrees that:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>Carbiz may report the Hire Vehicle as having been stolen to the police;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              Carbiz may, without notice, enter into any premises where it suspects the Hire Vehicle is located and remove it
              without committing a trespass. The Hirer irrevocably authorises Carbiz to enter into such premises and indemnifies
              Carbiz against all loss or damage suffered or incurred by Carbiz as a result, except to the extent caused by
              Carbiz's gross negligence; and
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              the Kia Dealer may retain possession of the Warranty Vehicle and not return it to the Hirer until the Hire Vehicle
              is returned to the Kia Dealer in accordance with this Agreement.
            </td>
          </tr>
          <tr>
            <td>9.6</td>
            <td>Carbiz may take possession of the Hire Vehicle without prior demand if:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>the Hire Vehicle is illegally parked;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              Carbiz or the Kia Dealer reasonably determines that the Hire Vehicle is being used in contravention of any law or in
              breach of this Agreement;
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              Carbiz or the Kia Dealer reasonably determines that the Hire Vehicle has been damaged or is defective in any way,
              and such damage or defect has not been reported by the Hirer as required under this Agreement;
            </td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>Carbiz or the Kia Dealer reasonably determines that the Hire Vehicle has been abandoned; or</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>
              Carbiz or the Kia Dealer becomes aware of speeding by any person driving the Hire Vehicle by more than 20 km/h above
              the speed limit.
            </td>
          </tr>
          <tr>
            <td>9.7</td>
            <td>
              The Hirer acknowledges and agrees that Carbiz has authorised the Kia Dealer to carry out a final inspection of the
              Hire Vehicle on its behalf. Once the Hire Vehicle is returned by the Hirer, Carbiz will use its reasonable
              endeavours to ensure that the final inspection of the Hire Vehicle is carried out as follows:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>if the Hire Vehicle is returned within 8am - 6pm on a business day, then at the time of the return; and</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              if the Hire Vehicle is returned outside of the above hours of operation, then within 8am - 6pm on the next business
              day after the return.
            </td>
          </tr>
          <tr>
            <td>9.8</td>
            <td>
              Carbiz will use its reasonable endeavours to ensure that the Kia Dealer to confirms the condition of the Hire
              Vehicle to the Hirer within 4 business hours of the final inspection if Carbiz or the Kia Dealer does not consider
              that the Hire Vehicle was returned in the state of repair and condition required by this Agreement.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>10.</td>
            <td style={{ fontWeight: 700 }}>BREACH AND TERMINATION</td>
          </tr>
          <tr>
            <td>10.1</td>
            <td>
              In addition to its rights under clause 9.5, Carbiz may terminate this Agreement by giving the Hirer at least 48
              hours' written notice if the Hirer breaches this Agreement and, in Carbiz's reasonable opinion, the breach:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>cannot be remedied; or</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              can be remedied, but is not remedied within 48 hours after Carbiz or the Kia Dealer gives written notice of the
              breach to the Hirer.
            </td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>
              Despite any other term or provision of this Agreement, either party may terminate this Agreement at any time for any
              reason by giving the other party at least 48 hours’ written notice.
            </td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>On the date of termination of this Agreement for any reason, the Hirer must:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              return the Hire Vehicle to the Kia Dealer at the address nominated by the Kia Dealer in accordance with all
              requirements under this Agreement; and
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>pay all money due and payable under this Agreement up to and including the date of termination.</td>
          </tr>
          <tr>
            <td>10.4</td>
            <td>
              If the Hirer is in default in payment of any amount owed to Carbiz under this Agreement, the Hirer authorises Carbiz
              to provide information of that default to a credit reporting body and to obtain an up-to-date consumer credit report
              on the Hirer. The Hirer acknowledges and agrees that Personal information may be used and disclosed by Carbiz to the
              credit reporting body and vice versa in accordance with the Privacy Act 1988 (Cth) to create or maintain a credit
              information file containing information about the Hirer, including defaults under this Agreement and the debt owed
              by the Hirer to Carbiz.
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>11.</td>
            <td style={{ fontWeight: 700 }}>ONBOARD DEVICES</td>
          </tr>
          <tr>
            <td>11.1</td>
            <td>By signing this Agreement, the Hirer:</td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>
              authorises Carbiz to install in the Hire Vehicle and use any Onboard Device for any purpose required by Carbiz;
            </td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>
              acknowledges and agrees that Carbiz owns all rights, title and interest (including any intellectual property rights)
              in the recordings, videos, materials and any other output produced by the Onboard Devices; and
            </td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>
              authorises Carbiz to use those recordings, videos, materials and output for any purposes required by Carbiz
              (including sales and marketing).
            </td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>
              For the purpose of clause 11.1, the term “Onboard Device” means a GPS, immobiliser, driving recorder, event data
              recorder, onboard camera, sensor, computer or another device that is installed in the Hire Vehicle that has one or
              more of the following functions:
            </td>
          </tr>
          <tr>
            <td>(a)</td>
            <td>electronic tracking;</td>
          </tr>
          <tr>
            <td>(b)</td>
            <td>immobilising;</td>
          </tr>
          <tr>
            <td>(c)</td>
            <td>recording;</td>
          </tr>
          <tr>
            <td>(d)</td>
            <td>monitoring;</td>
          </tr>
          <tr>
            <td>(e)</td>
            <td>viewing; or</td>
          </tr>
          <tr>
            <td>(f)</td>
            <td>any other function reasonably required by Carbiz.</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>12.</td>
            <td style={{ fontWeight: 700 }}>JURISDICTION</td>
          </tr>
          <tr>
            <td>12.1</td>
            <td>This Agreement is governed by the laws of the State in which the Hire Vehicle was hired.</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td style={{ fontWeight: 700 }}>13.</td>
            <td style={{ fontWeight: 700 }}>PRIVACY POLICY</td>
          </tr>
          <tr>
            <td>13.1</td>
            <td>
              The Hirer authorises Carbiz to collect and use any personal information provided by the Hirer in accordance with
              Carbiz’s Privacy Policy.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default KAUAgreementDescription;
