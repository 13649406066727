import { Form, Input, Select } from 'antd';
interface AddressProps {
  postCodeName: string | string[];
  streetName: string | string[];
  street2Name: string | string[];
  cityName: string | string[];
  stateName: string | string[];
  fieldsOptional: boolean;
  label?: string;
}
function Address({ postCodeName, streetName, street2Name, cityName, stateName, fieldsOptional, label }: AddressProps) {
  const { Option } = Select;
  const states = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];

  return (
    <div className="gap-16 grid grid-cols-2 md-grid-cols-1">
      <Form.Item
        name={streetName}
        label={`${label ? label : 'Address'}`}
        rules={[
          {
            required: !fieldsOptional,
            message: 'Please enter the street address',
          },
        ]}
      >
        <Input placeholder="Street Address" className="capitalize" />
      </Form.Item>
      <Form.Item name={street2Name} className={'grid end'}>
        <Input placeholder="Street Address Line 2" className="capitalize" />
      </Form.Item>
      <Form.Item
        name={cityName}
        rules={[
          {
            required: !fieldsOptional,
            message: 'Please enter the city',
          },
        ]}
      >
        <Input placeholder="City" className="capitalize" />
      </Form.Item>
      <Form.Item
        name={stateName}
        rules={[
          {
            required: !fieldsOptional,
            message: 'Please enter the state',
          },
        ]}
      >
        <Select placeholder="Select state">
          {states.map((state) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={postCodeName}
        rules={[
          {
            required: !fieldsOptional,
            message: 'Please enter the post code',
          },
        ]}
      >
        <Input placeholder="Post Code" />
      </Form.Item>
    </div>
  );
}
export default Address;
