import { Form, Input, Select, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { ICapturedImage, SmallLoading, IconCarScan, MyIcon, CustomButton, CarImageCapture } from '@scanny-app/loopy-loop';

interface RentalVehicleProps {
  setGetCaptureImages: (values: ICapturedImage[]) => void;
  isRentalVehicleLoading: boolean;
  hasCarSeats?: boolean;
}

function RentalVehicle({ setGetCaptureImages, isRentalVehicleLoading, hasCarSeats }: RentalVehicleProps) {
  const { Option } = Select;
  const [isModal, setIsModal] = useState(false);
  const [isModalPreview, setIsModalPreview] = useState(false);
  const [saveCaptureImages, setSaveCaptureImages] = useState<ICapturedImage[]>();
  const [runCameraEffect, setRunCameraEffect] = useState(0);

  const handleSaveCaptureImages = (values: ICapturedImage[]) => {
    setSaveCaptureImages(values);
    setGetCaptureImages(values);
  };

  const handleCancel = () => {
    setIsModalPreview(false);
  };

  const handleClick = () => {
    setIsModal(true);
    setRunCameraEffect((prev) => prev + 1);
  };

  useEffect(() => {
    if (isModal) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
    return () => document.body.classList.remove('body-no-scroll');
  }, [isModal]);

  return (
    <div className="flex flex-column gap-16 mb-24">
      <Modal closeIcon={false} open={isModal} footer={null} className="capture-image left-menu" centered>
        <CarImageCapture onFinish={handleSaveCaptureImages} setIsModal={setIsModal} runCameraEffect={runCameraEffect} />
      </Modal>

      <Modal closeIcon={false} open={isModalPreview} footer={null} className="capture-image left-menu" width={400} centered>
        <div id="header-capture" className="w-full flex content-space-between middle pb-16">
          <div className="flex gap-8 middle">
            <IconCarScan icon="ScanCompleted" />
            <div className="flex flex-column">
              <div className="text-xs">Preview</div>
              <h3 className="m-0 text-sm">Scan Complete</h3>
            </div>
          </div>
          <div onClick={handleCancel} className="flex middle hover-pointer">
            <MyIcon icon={'IconClose'} />
          </div>
        </div>
        <div className="grid grid-cols-2 w-full gap-8 overflow-scroll camera-scan-preview">
          {saveCaptureImages &&
            saveCaptureImages.length > 0 &&
            saveCaptureImages.map((image, index) => (
              <div key={index} className="car-image-capture">
                <img src={`data:image/jpeg;base64,${image.data}`} alt={image.title} />
                <div className="step">{index + 1}</div>
              </div>
            ))}
        </div>
      </Modal>
      <div className="w-full gap-16 grid grid-cols-2 md-grid-cols-1">
        <Form.Item name="make" label="Make">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="model" label="Model">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="rego" label="Rego">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="dropOffLocation" label="Drop Off Location">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          name="fuelOut"
          label="Fuel Out"
          rules={[
            {
              required: true,
              message: 'Please enter the fuel out',
            },
          ]}
        >
          <Select placeholder="Select fuel out">
            <Option value="Full">Full</Option>
            <Option value="3/4">3/4</Option>
            <Option value="Half">Half</Option>
            <Option value="1/4">1/4</Option>
            <Option value="Empty">Empty</Option>
          </Select>
        </Form.Item>
        {hasCarSeats && (
          <Form.Item name="carSeatsGiven" label="Car seats given?">
            <Select placeholder="Select car seats">
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
              <Option value="More than 1">More than 1</Option>
            </Select>
          </Form.Item>
        )}
      </div>
      <Form.Item
        name="captureCarImages"
        label="Capture Car images"
        rules={[
          {
            required: true,
            message: 'Please capture the car images',
          },
        ]}
      >
        <div className="flex flex-column gap-8">
          {isRentalVehicleLoading ? (
            <div className="py-24">
              <SmallLoading customText="Checklist in progress. Do not refresh or navigate away." />
            </div>
          ) : saveCaptureImages && saveCaptureImages.length > 0 ? (
            <div
              className="flex middle h-45 box-1-current px-16 content-space-between prooftec hover-pointer"
              onClick={() => {
                setIsModalPreview(true);
              }}
            >
              <div className="text-sm font-semibold  text-current flex gap-8">
                <MyIcon icon="IconProoftecLogo" />
                Scan Complete
              </div>
              <MyIcon icon="IconApproved" />
            </div>
          ) : (
            <CustomButton
              className={'on-hire-lg w-full content-center h-45'}
              btnName={'Add car scan'}
              iconName={'IconAdd'}
              onClick={handleClick}
            />
          )}
        </div>
      </Form.Item>

      <div className="flex gap-8 w-full content-end text-xs middle">
        Powered by
        <a href="https://www.prooftec.com/" target="_blank" rel="noopener noreferrer">
          <div style={{ display: 'flex' }}>
            <MyIcon icon="IconProoftec" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default RentalVehicle;
