import { Helmet } from 'react-helmet';

interface HeadScriptProps {
  scripts: string[];
}

function HeadScript({ scripts }: HeadScriptProps) {
  return (
    <>
      {process.env.REACT_APP_NODE_ENV !== 'development' && (
        <Helmet>
          {scripts.map((script, index) => (
            <script key={index} type="text/javascript">
              {script}
            </script>
          ))}
        </Helmet>
      )}
    </>
  );
}

export default HeadScript;
