import { Form, Input } from 'antd';
import { CardElement } from '@stripe/react-stripe-js';
import { SmallLoading, MyIcon } from '@scanny-app/loopy-loop';

interface PreAuthProps {
  isPreAuthData: boolean;
  isPreAuthLoading: boolean;
  stripeErrorMessage: boolean;
  handleError: (value: boolean) => void;
}

function PreAuth({ isPreAuthData, isPreAuthLoading, stripeErrorMessage, handleError }: PreAuthProps) {
  return (
    <>
      {isPreAuthLoading ? (
        <div className="py-24">
          <SmallLoading customText={'Payment in progress. Do not refresh or navigate away.'} />
        </div>
      ) : isPreAuthData ? (
        <div className="p-24 flex middle content-center flex-column gap-16  icon-xl">
          <MyIcon icon="IconCircleCompleted" />
          <div className="flex flex-column gap-4">
            <h6 className="text-sm font-semibold m-0 text-center">Success!</h6>
            <p className="m-0 text-xs">Your $1 Temporary hold was successful</p>
          </div>
        </div>
      ) : (
        <div className="mb-24 flex flex-column gap-16">
          <div className="text-xs italic" style={{ color: '#968f8f' }}>
            *$1 will be charged and released after a week in the case that you incur any tolls, fines or incidentals
          </div>
          {stripeErrorMessage && (
            <div className={'flex flex-column gap-4 p-12-16 border-red rounded-8 bg-red-1'}>
              <h6 className={'m-0 text-xs font-semibold text-red'}>Payment Declined</h6>
              <p className="text-xs m-0 text-red-90">The credit card information was declined. Please try again</p>
            </div>
          )}
          <div className="w-full form-style-one flex flex-column gap-16">
            <Form.Item
              label="Cardholder Name"
              name="nameOnCard"
              rules={[
                {
                  required: true,
                  message: 'Please enter the cardholder name',
                },
              ]}
            >
              <Input className="capitalize" />
            </Form.Item>
            <Form.Item
              name="creditCard"
              rules={[
                {
                  required: true,
                  message: 'Please enter card details',
                },
              ]}
            >
              <CardElement options={{ disableLink: true }} className="stripe-card box-1" onChange={() => handleError(false)} />
            </Form.Item>
          </div>
          <div className="flex gap-8 w-full content-space-between top text-xs middle">
            <div className="w-60 icon-sm flex gap-8">
              <MyIcon icon={'IconLock'} />
              <div>
                Guaranteed <span className={'font-semibold'}>safe & secure</span> checkout
              </div>
            </div>
            <div className="stripe-display gap-4 content-end middle">
              Powered by
              <div style={{ display: 'flex' }}>
                <MyIcon icon="IconStripe" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreAuth;
