import { ReactComponent as IconInteriorFrontPassengerSeat } from './assets/IconInteriorFrontPassengerSeat.svg';
import { ReactComponent as IconInteriorFrontDriverSeat } from './assets/IconInteriorFrontDriverSeat.svg';
import { ReactComponent as IconDashboardOdometer } from './assets/IconDashboardOdometer.svg';
import { ReactComponent as IconCarKey } from './assets/IconCarKey.svg';
import { ReactComponent as IconCarBodyRear } from './assets/IconCarBodyRear.svg';
import { ReactComponent as IconCarBodyPassengerSideRear } from './assets/IconCarBodyPassengerSideRear.svg';
import { ReactComponent as IconCarBodyPassengerSideMiddle } from './assets/IconCarBodyPassengerSideMiddle.svg';
import { ReactComponent as IconCarBodyPassengerSideFront } from './assets/IconCarBodyPassengerSideFront.svg';
import { ReactComponent as IconCarBodyFront } from './assets/IconCarBodyFront.svg';
import { ReactComponent as IconCarBodyDriverSideRear } from './assets/IconCarBodyDriverSideRear.svg';
import { ReactComponent as IconCarBodyDriverSideMiddle } from './assets/IconCarBodyDriverSideMiddle.svg';
import { ReactComponent as IconCarBodyDriverSideFront } from './assets/IconCarBodyDriverSideFront.svg';
import { ReactComponent as IconInteriorRearRightSeat } from './assets/IconInteriorRearRightSeat.svg';
import { ReactComponent as IconInteriorRearLeftSeat } from './assets/IconInteriorRearLeftSeat.svg';
import { ReactComponent as ScanCompleted } from './assets/ScanCompleted.svg';
interface IconCarScanProps {
  icon: string;
  fill?: string;
}
const CustomIcon = ({ icon, fill = 'currentColor' }: IconCarScanProps) => {
  // Determine which icon to render based on the "icon" prop
  let IconComponent;

  switch (icon) {
    case 'IconInteriorFrontPassengerSeat':
      IconComponent = IconInteriorFrontPassengerSeat;
      break;
    case 'IconInteriorFrontDriverSeat':
      IconComponent = IconInteriorFrontDriverSeat;
      break;
    case 'IconDashboardOdometer':
      IconComponent = IconDashboardOdometer;
      break;
    case 'IconCarKey':
      IconComponent = IconCarKey;
      break;
    case 'IconCarBodyRear':
      IconComponent = IconCarBodyRear;
      break;
    case 'IconCarBodyPassengerSideRear':
      IconComponent = IconCarBodyPassengerSideRear;
      break;
    case 'IconCarBodyPassengerSideMiddle':
      IconComponent = IconCarBodyPassengerSideMiddle;
      break;
    case 'IconCarBodyPassengerSideFront':
      IconComponent = IconCarBodyPassengerSideFront;
      break;
    case 'IconCarBodyFront':
      IconComponent = IconCarBodyFront;
      break;
    case 'IconCarBodyDriverSideRear':
      IconComponent = IconCarBodyDriverSideRear;
      break;
    case 'IconCarBodyDriverSideMiddle':
      IconComponent = IconCarBodyDriverSideMiddle;
      break;
    case 'IconCarBodyDriverSideFront':
      IconComponent = IconCarBodyDriverSideFront;
      break;
    case 'IconInteriorRearRightSeat':
      IconComponent = IconInteriorRearRightSeat;
      break;
    case 'IconInteriorRearLeftSeat':
      IconComponent = IconInteriorRearLeftSeat;
      break;
    case 'ScanCompleted':
      IconComponent = ScanCompleted;
      break;
    // Handle other icons here
    default:
      IconComponent = null;
  }

  return IconComponent ? <IconComponent fill={fill} /> : null;
};

export default CustomIcon;
