import { Form, Input, Modal, message } from 'antd';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { IRentalRequest, MyIcon } from '@scanny-app/loopy-loop';
import { useState } from 'react';

export interface FormUpdateVORProps {
  rentalRequestData: IRentalRequest;
  VORPass: [
    setReloadDataDashboard: (value: boolean) => void,
    setReloadDataRentalRequest: (value: boolean) => void,
    setIsVORLoading: (value: boolean) => void,
  ];
}

interface VORSubmit {
  VORNumber: string;
  status: string;
}

function FormUpdateVOR({ rentalRequestData, VORPass }: FormUpdateVORProps) {
  const [formVOR] = Form.useForm();
  const [setReloadDataDashboard, setReloadDataRentalRequest, setIsVORLoading] = VORPass;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmitVOR = () => {
    setIsVORLoading(true);
    const formattedValues = {
      status: 'Approved',
      VORNumber: formVOR.getFieldsValue().VORNumber.toLocaleUpperCase(),
    };

    updateVOR(rentalRequestData.id, formattedValues);
    setShowConfirmation(false);
  };

  const updateVOR = (id: string, dataToSubmit: VORSubmit) => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/RentalRequest/${id}`, JSON.stringify(dataToSubmit), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setIsVORLoading(false);
            setReloadDataDashboard(true);
            setReloadDataRentalRequest(true);
            message.success('VOR updated successfully');
          })
          .catch(() => {
            message.error('Something went wrong updating the VOR number. Please try again later.');
            setIsVORLoading(false);
          });
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.');
        setIsVORLoading(false);
      });
  };

  const optionModal = rentalRequestData.status === 'Pending' ? 'approve' : 'save';

  return (
    <>
      <Modal
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onOk={handleSubmitVOR}
        className="custom-modal"
        width={400}
        centered
      >
        <h5 className="text-sm font-semibold m-0">
          Are you sure you want to {optionModal} {optionModal === 'approve' && 'by'} VOR number?
        </h5>
        <p className="mt-4 mb-16">
          This action will {optionModal} {optionModal === 'save' ? 'the VOR number to this request' : 'this rental request'} and
          cannot be undone.
        </p>
      </Modal>
      <Form form={formVOR} className="w-full flex flex-row form-btn-inline" onFinish={() => setShowConfirmation(true)}>
        <Form.Item
          className="m-0 w-full"
          name="VORNumber"
          rules={[
            {
              required: true,
              message: 'Please enter the VOR number',
            },
            {
              pattern: /\d{6}[A-Za-z]\d{3}/,
              message: 'Please verify VOR number is 10 characters (format: 6 digits, 1 letter, 3 digits)',
            },
          ]}
        >
          <Input maxLength={10} placeholder="VOR Number" className="uppercase" />
        </Form.Item>

        <button className="approve-btn h-45" type="submit">
          <MyIcon icon={'IconApproved'} />
        </button>
      </Form>
    </>
  );
}

export default FormUpdateVOR;
