import MyIcon from './MyIcon';
interface IStatus {
  status: string;
}
const StatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Pending':
      return (
        <div className="status-pending flex middle content-space-between">
          Pending approval...
          <MyIcon icon="IconPending" />
        </div>
      );

    case 'Approved':
      return (
        <>
          <div className="flex flex-column status-approval">
            <div className="status-wrapper status-approval-id">
              <div>Approved</div>
              <MyIcon icon="IconApproved" />
            </div>
          </div>
        </>
      );

    case 'Rejected':
      return (
        <div className="status-rejected flex middle content-space-between">
          Rejected
          <MyIcon icon="IconDecline" />
        </div>
      );

    default:
      return <div>{status}</div>;
  }
};

const ApprovalStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Pending':
      return (
        <div className="flex gap-8 middle text-blue">
          <MyIcon icon="IconPending" />
          <div className="text-xs font-semibold">Pending Approval...</div>
        </div>
      );

    case 'Approved':
      return (
        <>
          <div className="flex gap-8 middle text-green">
            <MyIcon icon="IconApproved" />
            <div className="text-xs font-semibold">Approved </div>
          </div>
        </>
      );

    case 'Rejected':
      return (
        <div className="flex gap-8 middle text-red ">
          <MyIcon icon="IconDecline" />
          <div className="text-xs font-semibold">Rejected</div>
        </div>
      );

    case 'Request Details':
      return (
        <div className="flex gap-8 middle text-red ">
          <MyIcon icon="IconNotePad" />
          <div className="text-xs font-semibold">Further details requested</div>
        </div>
      );

    default:
      return <div>{status}</div>;
  }
};

const BookingStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Completed':
    case 'Complete':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#4DAA2C', backgroundColor: '#C4F4BD' }}>
          Completed
        </div>
      );
    case 'Awaiting Pre-Auth':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#FF4242', backgroundColor: '#FFDADA' }}>
          Awaiting Pre-Auth
        </div>
      );

    case 'Awaiting approval':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#A9A9A9' }}>
          Waiting for manufacturer approval...
        </div>
      );

    case 'In Progress':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#1D82CC', backgroundColor: '#D7ECFF' }}>
          On Hire
        </div>
      );
    case 'Scheduled':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#F97316', backgroundColor: '#FED7AA' }}>
          Scheduled
        </div>
      );

    case 'Rejected':
      return (
        <div className="booking-status" style={{ color: '#F97316', backgroundColor: '#FED7AA' }}>
          ---
        </div>
      );

    default:
      return (
        <div className="booking-status p-8-0" style={{ color: '#000', backgroundColor: 'transparent' }}>
          {status}
        </div>
      );
  }
};

const ExtensionStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Approved':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#4DAA2C', backgroundColor: '#C4F4BD' }}>
          Approved
        </div>
      );

    case 'Pending':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#1D82CC', backgroundColor: '#D7ECFF' }}>
          Pending approval
        </div>
      );

    case 'Rejected':
      return (
        <div className="booking-status p-8-16 md-p-4-12" style={{ color: '#ff4d4f', backgroundColor: '#FEDFDF' }}>
          Rejected
        </div>
      );

    default:
      return (
        <div className="booking-status p-8-0" style={{ color: '#000', backgroundColor: 'transparent' }}>
          {status}
        </div>
      );
  }
};

const PreAuthStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Completed':
      return <div className="font-semibold completed">Completed</div>;
    case 'Awaiting':
      return <div className="font-semibold awaiting-pre-auth">Awaiting</div>;
    case 'Scheduled':
      return <div className="font-semibold scheduled-pre-auth">Scheduled</div>;

    default:
      return <div>{status}</div>;
  }
};

const InvoiceStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Activated':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#F97316', backgroundColor: '#FED7AA' }}>
          Activated
        </div>
      );
    case 'Paid':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#4DAA2C', backgroundColor: '#C4F4BD' }}>
          Paid
        </div>
      );

    case 'Draft':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#1D82CC', backgroundColor: '#D7ECFF' }}>
          Draft
        </div>
      );

    default:
      return <div>{status}</div>;
  }
};
const JobStatusComponent = ({ status }: IStatus) => {
  switch (status) {
    case 'Completed':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#4DAA2C', backgroundColor: '#C4F4BD' }}>
          Completed
        </div>
      );

    case 'In Transit':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#1D82CC', backgroundColor: '#D7ECFF' }}>
          In Transit
        </div>
      );
    case 'Pending':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#F97316', backgroundColor: '#FED7AA' }}>
          Pending
        </div>
      );

    case 'Cancelled':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#D60606', backgroundColor: '#FFD7D7' }}>
          Cancelled
        </div>
      );

    case 'Client Late':
      return (
        <div className="invoice-status p-8-16 md-p-4-12" style={{ color: '#340064', backgroundColor: '#ECD7FF' }}>
          Client Late
        </div>
      );

    case 'Postponed':
      return (
        <div className="job-status p-8-16 md-p-4-12" style={{ color: '#2E3D00', backgroundColor: '#E9F7C1' }}>
          Postponed
        </div>
      );

    default:
      return (
        <div className="invoice-status p-8-0" style={{ color: '#000', backgroundColor: 'transparent' }}>
          {status}
        </div>
      );
  }
};
export {
  StatusComponent,
  BookingStatusComponent,
  InvoiceStatusComponent,
  PreAuthStatusComponent,
  ExtensionStatusComponent,
  ApprovalStatusComponent,
  JobStatusComponent,
};
