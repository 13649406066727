import { SubMenuList } from './SubMenuList';
import { MyIcon, ISideMenu, IUser, IDashboard } from '@scanny-app/loopy-loop';
interface SideMenuProps {
  collectDataDashboard: IDashboard | undefined;
  userData: IUser | null;
}
function SideMenuList({ collectDataDashboard, userData }: SideMenuProps) {
  const { CarRentals } = SubMenuList(collectDataDashboard);
  const data: ISideMenu[] = [
    {
      label: 'Dashboard',
      slug: '',
      icon: <MyIcon icon="IconDashboard" />,
      menu: [],
      link: '/',
    },
  ];

  if (userData && userData.portalServices && userData.portalServices.includes('Car Rentals')) {
    data.push({
      label: 'Rentals',
      slug: 'rentals',
      icon: <MyIcon icon="IconCarRentals" />,
      menu: CarRentals,
      link: '/rentals/rental-requests',
      activeSubMenuFormula: () => {
        const filterParam = new URLSearchParams(window.location.search).get('filter');
        if (filterParam) {
          return filterParam;
        } else {
          return 'rental-requests';
        }
      },
    });
  }
  return data;
}

export default SideMenuList;
