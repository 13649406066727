import { MyIcon } from '@scanny-app/loopy-loop';

interface SecondaryNavigationProps {
  menuTitle: string;
  rightNode?: React.ReactNode;
  onBack: () => void;
  borderBottom?: boolean;
  bannerNode?: React.ReactNode;
}

function SecondaryNavigation({ onBack, rightNode, menuTitle, borderBottom = true, bannerNode }: SecondaryNavigationProps) {
  return (
    <div className="container-2 px-24 md-w-fix sticky top-0 z-50">
      <div id="top-navigation" className={`${borderBottom ? 'border-b' : ''} ${!bannerNode && 'mb-24'}`}>
        <div className="flex flex-row middle">
          <button className="custom-btn-outline text-black" onClick={onBack}>
            <MyIcon icon="IconBack" />
          </button>
          <h1 className="heading-page-content">{menuTitle}</h1>
        </div>
        <div className="flex flex-row middle gap-4">{rightNode}</div>
      </div>
      {bannerNode && <div className="bg-white py-16">{bannerNode}</div>}
    </div>
  );
}

export default SecondaryNavigation;
