import * as XLSX from 'xlsx';
import { InitialFilters } from './GetFilterConfig';

const exportToExcel = <T,>(fileName: string, data: T[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName; // Use the file name passed as a parameter
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const formatTitle = (filterState: InitialFilters, filterParam: string) => {
  const today = new Date();
  const dateFormatted = today.toISOString().split('T')[0];
  let title = '';
  if (filterParam === 'nearing-day-limit') {
    title = `Day_Limit${filterState.dealershipFilter.value.length > 0 ? '_' + filterState.dealershipFilter.value : ''}`;
  }
  if (filterParam === 'invoice-received') {
    title = `Invoices${filterState.dealershipFilter.value.length > 0 ? '_' + filterState.dealershipFilter.value : ''}
      ${filterState.invoiceStatusFilter.value.length > 0 ? '_' + filterState.invoiceStatusFilter.value : ''}`;
  } else {
    title = `Rentals${filterState.dealershipFilter.value.length > 0 ? '_' + filterState.dealershipFilter.value : ''}${
      filterState.approvalFilter.value.length > 0 ? '_' + filterState.approvalFilter.value : ''
    }${filterState.bookingStatusFilter.value.length > 0 ? '_' + filterState.bookingStatusFilter.value : ''}`;
  }
  return `${dateFormatted}_` + title + `${filterState.searchTerm.value && '_Search_Term_' + filterState.searchTerm.value}.xlsx`;
};

export { exportToExcel, formatTitle };
