export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const result = event?.target?.result;

      if (typeof result === 'string') {
        resolve(result.split(',')[1]); // Split to get only the Base64 part
      } else {
        reject(new Error('Expected a string, but got an ArrayBuffer'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
