import React from 'react';
import MyIcon from '../../icons/General/MyIcon';
interface PoweredByCarbizBannerProps {
  appName: string;
}
const PoweredByCarbizBanner = ({ appName }: PoweredByCarbizBannerProps) => {
  return (
    <div className="box-1 p-20 text-sm md-text-xs flex content-space-between gap-16" style={{ borderLeft: '5px solid #3CCEB3' }}>
      <div className="flex gap-8 middle ">
        <div className="icon-base md-icon-sm">
          <MyIcon icon="IconInfo" fill="#3CCEB3" />
        </div>
        <div>
          <span className="font-bold">{appName} </span>are powered by
          <span className="font-bold"> Carbiz Replacement Cars</span>
        </div>
      </div>
      <div className="md-icon-sm text-end">
        <MyIcon icon="IconCarbiz" />
      </div>
    </div>
  );
};

export default PoweredByCarbizBanner;
