import dayjs from 'dayjs';
const disablePastDatesBasedOnTime = (currentDate: dayjs.Dayjs) => {
  const now = dayjs();
  const today = now.startOf('day');
  // const twoPM = today.add(14, 'hour');

  return currentDate.isBefore(today);

  // if (now.isBefore(twoPM)) {
  //   // If it's before 2pm, allow all dates including and after today
  //   return currentDate.isBefore(today);
  // } else {
  //   // If it's after 2pm, disable today and allow all dates from tomorrow onwards
  //   return currentDate.isBefore(today.add(1, 'day'));
  // }
};

const disablePastDatesBasedOnTimeAndWeekend = (currentDate: dayjs.Dayjs) => {
  const now = dayjs();
  const today = now.startOf('day');
  const twoPM = today.add(14, 'hour');
  const isWeekend = currentDate.day() === 0 || currentDate.day() === 6; // 0 = Sunday, 6 = Saturday

  // Check if the current time is before today's 2 PM
  if (now.isBefore(twoPM)) {
    // If it's before 2 PM, disable all past dates and weekends
    return currentDate.isBefore(today, 'day') || isWeekend;
  } else {
    // If it's after 2 PM, disable today and all past dates as well as weekends
    return currentDate.isBefore(today.add(1, 'day'), 'day') || isWeekend;
  }
};

export { disablePastDatesBasedOnTime, disablePastDatesBasedOnTimeAndWeekend };
